import { range } from "lib/utils/utils";

import AbstractFactorCoordinator from "./AbstractFactorCoordinator";
import {
  CircularCoordinate,
  RectangularCoordinate,
  Dot,
  Radian,
} from "./coordinate";
import Factors from "./factorization/Factors";

class SimpleFactorCoordinator extends AbstractFactorCoordinator {
  protected divideCircleByFactor = (
    factor: number,
    circle: Dot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    prevFactors: Factors
  ): Dot[] => {
    const theta: Radian = Radian.M_2PI.div(factor);
    const theta2: Radian = theta.div(2);
    const r: number =
      circle.radius * (Math.sin(theta2.value) / (1 + Math.sin(theta2.value)));

    // 内接円の中心座標(円座標系、原点は元円の中心)を計算してから
    // 直交座標系に変換し、元円の中心座標を加える
    const inscribedCircleCenter: RectangularCoordinate = new CircularCoordinate(
      circle.radius - r,
      circle.angle
    )
      .toRectangular()
      .add(circle.center);

    const inscribedCircleBase: Dot = new Dot(
      inscribedCircleCenter,
      r,
      circle.angle
    );
    const circles: Dot[] = range(factor).map((i) =>
      inscribedCircleBase.rotate(circle.center, theta.mul(i))
    );

    return circles;
  };
}

export default SimpleFactorCoordinator;
