import { GaugePartsSetting } from "./type";

export const defaultGaugePartsSetting: GaugePartsSetting = {
  type: "Gauge",
  common: {
    x: "0%",
    y: "0%",
    width: "80%",
    height: "10%",
    borderColor: "#fff",
    borderWidth: "2h",
    rotate: 0,
  },
  clock: {
    type: "Minute",
    divisor: 60,
    color: "#fff",
    duration: 300,
    delay: 0,
  },
};
