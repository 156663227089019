import { JSONSchema7, JSONSchema7Definition } from "json-schema";

import { generateTimelineSettingSchema } from "lib/schema";

const FactorDotClockPartsSetting: JSONSchema7Definition = {
  title: "ドット時計",
  description: "ドットの設定",
  type: "object",
  additionalProperties: false,
  properties: {
    type: {
      title: "パーツタイプ",
      enum: ["FactorDotClock"],
      default: "FactorDotClock",
    },
    common: { $ref: "#/definitions/ClockPartsCommonSetting" },
    clock: { $ref: "#/definitions/FactorDotClockPartsIndividualSetting" },
    timeline: { $ref: "#/definitions/FactorDotClockPartsTimelineSetting" },
  },
  required: ["type", "common", "clock"],
};

const FactorDotClockPartsIndividualSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: true,
  description: "ドット時計の個別設定",
  properties: {
    type: {
      $ref: "#/definitions/FactorDotClockPartsType",
      default: "Second",
    },
    color: {
      type: "string",
      description: "ドット色",
      default: "#fff",
    },
    duration: {
      type: "number",
      description: "ドット時計のアニメーション時間（ミリ秒）",
      minimum: 0,
      maximum: 1000,
      default: 300,
    },
    delay: {
      type: "number",
      description: "ドット時計のアニメーションの開始遅延時間（ミリ秒）",
      minimum: 0,
      maximum: 1000,
      default: 0,
    },
    dotScale: {
      type: "number",
      description: "ドットのスケール",
      minimum: 0,
      default: 0.8,
    },
    dotType: {
      enum: ["Circle", "Square"],
      description: "ドットのタイプ",
      default: "Circle",
    },
    "coordinator:type": {
      $ref: "#/definitions/CoordinatorType",
      default: "ClassicFactorCoordinator",
    },
    "coordinator:sort": {
      $ref: "#/definitions/SortOrderType",
      default: "ClassicSimpleFactorSorter",
    },
    "coordinator:merge": {
      type: "array",
      items: {
        type: "number",
        minimum: 1,
        maximum: 59,
      },
      default: [4],
    },
  },
  required: ["type"],
};

const FactorDotClockPartsTimelineSetting = generateTimelineSettingSchema(
  FactorDotClockPartsIndividualSetting
);

const FactorDotClockPartsType: JSONSchema7Definition = {
  enum: ["Year", "Month", "Day", "Hour", "Minute", "Second"],
  description: "ドット時計のタイプ",
};

const CoordinatorSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  properties: {
    type: {
      $ref: "#/definitions/CoordinatorType",
      default: "ClassicFactorCoordinator",
    },
    sort: {
      $ref: "#/definitions/SortOrderType",
      default: "ClassicSimpleFactorSorter",
    },
    merge: {
      type: "array",
      items: {
        type: "number",
        minimum: 1,
        maximum: 59,
      },
      default: [4],
    },
  },
};

const CoordinatorType: JSONSchema7Definition = {
  enum: ["ClassicFactorCoordinator", "SimpleFactorCoordinator"],
};

const SortOrderType: JSONSchema7Definition = {
  enum: [
    "ClassicSimpleFactorSorter",
    "AscendingSimpleFactorSorter",
    "DescendingSimpleFactorSorter",
  ],
};

export const FactorDotClockPartsDefinitions: JSONSchema7["definitions"] = {
  FactorDotClockPartsSetting,
  FactorDotClockPartsIndividualSetting,
  FactorDotClockPartsTimelineSetting,
  FactorDotClockPartsType,
  CoordinatorSetting,
  CoordinatorType,
  SortOrderType,
};
