import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as T from "components/atoms/Table";
import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";

import { defaultSimpleDotClockPartsSetting } from "./setting";

type SimpleDotClockPartsDocumentProps = {
  className?: string;
  id?: string;
};

const SimpleDotClockPartsDocument: React.FC<SimpleDotClockPartsDocumentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Simple Dot Clock
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>シンプルドット時計の設定です。</S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-type`}>
                    <S.ListItemLabel>type</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#SimpleDotClockPartsType">
                        SimpleDotClockPartsType
                      </Link>
                    </S.ListItemValidation>
                    <S.Required />
                  </AnchorContainer>
                  <S.ListItemDescription>
                    シンプルドット時計のタイプ
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-color`}>
                    <S.ListItemLabel>color</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>ドットの色</S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;#fff&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-duration`}>
                    <S.ListItemLabel>duration</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ドット時計のアニメーション時間。単位はミリ秒。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>300</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-delay`}>
                    <S.ListItemLabel>delay</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ドット時計のアニメーション開始遅延時間。単位はミリ秒。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>0</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-dotScale`}>
                    <S.ListItemLabel>dotScale</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ドットのサイズ。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    1の場合、ドット同志が
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>0.8</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-coordinator`}>
                    <S.ListItemLabel>coordinator</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to={`/docs#${id}-SimpleDotClockCoordinator`}>
                        SimpleDotClockCoordinator
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ドットの配置を決めるコーディネーター
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;CircularCoordinator&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <DocumentClock partsSetting={defaultSimpleDotClockPartsSetting} />
            </S.Contents>
            <S.Contents>
              <T.Container id="SimpleDotClockPartsType">
                <T.Title>SimpleDotClockPartsType</T.Title>
                <T.Table>
                  <T.Row>
                    <T.Header>Hour</T.Header>
                    <T.Data>時</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Minute</T.Header>
                    <T.Data>分</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Second</T.Header>
                    <T.Data>秒</T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
            <S.Contents>
              <T.Container id="SimpleDotClockCoordinator">
                <T.Title>SimpleDotClockCoordinator</T.Title>
                <T.Table>
                  <T.Row>
                    <T.Header>CircularCoordinator</T.Header>
                    <T.Data>円状にドットを配置するコーディネーター</T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(SimpleDotClockPartsDocument);
