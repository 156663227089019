import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

import SVGIcon from "components/atoms/SVGIcon";

type ClockDocumentMenuProps = {
  className?: string;
};

const ClockDocumentMenu: React.FC<ClockDocumentMenuProps> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <MenuList>
        <MenuItem>
          <NavLink smooth to="/">
            <Icon type="left-arrow" /> clock-f
          </NavLink>
        </MenuItem>
        <MenuHeader>
          <Link smooth to="#Top">
            OVERVIEW
          </Link>
        </MenuHeader>
        <MenuItem>
          <Link smooth to="#About">
            About clock-f
          </Link>
        </MenuItem>
        <MenuHeader>
          <Link smooth to="#GettingStarted">
            GETTING STARTED
          </Link>
        </MenuHeader>
        <MenuItem>
          <Link smooth to="#GettingStarted-Main">
            メイン画面
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#GettingStarted-Edit">
            時計編集画面
          </Link>
        </MenuItem>
        <MenuHeader>
          <Link smooth to="#Share">
            SHARE
          </Link>
        </MenuHeader>
        <MenuItem>
          <Link smooth to="#Share-Share">
            作った時計をシェアする
          </Link>
        </MenuItem>
        <MenuHeader>
          <Link smooth to="#Use">
            USE
          </Link>
        </MenuHeader>
        <MenuItem>
          <Link smooth to="#Use-Screensaver">
            スクリーンセーバーに設定する
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#Use-iPhone">
            iPhoneを置き時計として利用する
          </Link>
        </MenuItem>
        <MenuHeader>---</MenuHeader>
        <MenuItem>
          <Link smooth to="/docs/setting/">
            Clock Setting Document
          </Link>
        </MenuItem>
      </MenuList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;

  a {
    color: rgb(105, 115, 134);
  }
`;
const MenuHeader = styled.h3`
  font-size: 12px;
  font-weight: bold;
`;

const MenuList = styled.ul``;
const MenuItem = styled.li`
  line-height: 2;
  font-weight: 500;
`;

const Icon = styled(SVGIcon)``;
const NavLink = styled(Link)`
  display: flex;
  align-items: center;

  ${Icon} {
    margin-right: 8px;
  }
`;

export default React.memo(ClockDocumentMenu);
