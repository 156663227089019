import CircularCoordinate from "./CircularCoordinate";
import Radian from "./Radian";

/**
 * 直交座標系における座標
 */
class RectangularCoordinate {
  private _x: number;
  private _y: number;

  get x(): number {
    return this._x;
  }
  get y(): number {
    return this._y;
  }

  constructor(x: number, y: number) {
    this._x = x;
    this._y = y;
  }

  /**
   * 座標を指定した点を中心に回転する
   * @param center 回転の中心
   * @param angle 回転する角度
   */
  public rotate = (
    center: RectangularCoordinate,
    angle: Radian
  ): RectangularCoordinate => {
    return this.sub(center) // 回転中心を原点とする直行座標系に変換
      .toPolar() // 極座標系に変換
      .rotate(angle) // 指定角度だけ回転
      .toRectangular() // 直交座標系に変換
      .add(center); // 元の座標系に変換
  };

  public add = (other: RectangularCoordinate): RectangularCoordinate => {
    return new RectangularCoordinate(this.x + other.x, this.y + other.y);
  };

  public sub = (other: RectangularCoordinate): RectangularCoordinate => {
    return new RectangularCoordinate(this.x - other.x, this.y - other.y);
  };

  public mul = (num: number): RectangularCoordinate => {
    return new RectangularCoordinate(this.x * num, this.y * num);
  };

  public div = (num: number): RectangularCoordinate => {
    return new RectangularCoordinate(this.x / num, this.y / num);
  };

  /**
   * 極座標系の座標に変換する
   */
  public toPolar = (): CircularCoordinate => {
    const r: number = Math.sqrt(this.x ** 2 + this.y ** 2);
    // 原点 (x,y) = (0,0) において特異性があり、分母がゼロとなるため θ が定まらない
    // この場合は θ = 0 にする
    const angle: number = Math.atan2(this.y, this.x);
    return new CircularCoordinate(r, new Radian(angle));
  };

  public static get ZERO(): RectangularCoordinate {
    return new RectangularCoordinate(0, 0);
  }
}

export default RectangularCoordinate;
