import React from "react";
import styled from "styled-components";

import { Centered } from "components/layout";

import { ImagePartsSetting } from "./type";

interface ImagePartsProps {
  setting: ImagePartsSetting;
}

const ImageParts: React.FC<ImagePartsProps> = ({
  setting: {
    clock: { url, objectFit },
  },
}) => {
  return (
    <Wrapper>
      <Image src={url} style={{ objectFit }} />
    </Wrapper>
  );
};

const Wrapper = styled(Centered)``;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export default ImageParts;
