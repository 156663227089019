import dayjs, { Dayjs } from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import React from "react";

import SwitchingText from "components/clock/SwitchingText";
import { useTick } from "hooks/tick";

import { TextClockPartsSetting } from "./type";

dayjs.extend(dayOfYear);

interface TextClockPartsProps {
  setting: TextClockPartsSetting;
}

const formats: { [key in TextClockPartsSetting["clock"]["type"]]?: string } = {
  Year: "YYYY",
  Month: "MM",
  Day: "DD",
  MonthDay: "MM/DD",
  YearMonthDay: "YYYY/MM/DD",
  Hour: "HH",
  Minute: "mm",
  Second: "ss",
  HourMinute: "HH:mm",
  HourMinuteSecond: "HH:mm:ss",
};

const TextClockParts: React.FC<TextClockPartsProps> = ({
  setting: {
    clock: {
      type,
      format = " ",
      text: textFormat = "{CLOCK}",
      color = "#fff",
      fontSize = 0.2,
      fontWeight = "normal",
      fontFamily,
      fontSizeAdjust,
      fontStretch,
      fontVariant,
      whiteSpace = "pre-wrap",
      delay = 300,
    },
  },
}) => {
  const time = useTick();
  const timeText = formatTime(time, type, format);
  const prevTimeText = formatTime(time.add(-1, "s"), type, format);
  const text = textFormat.replace("{CLOCK}", timeText);
  const prevText = textFormat.replace("{CLOCK}", prevTimeText);
  const isEven = time.get("second") % 2 === 0;

  return (
    <SwitchingText
      text={text}
      prevText={prevText}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      fontSizeAdjust={fontSizeAdjust}
      fontStretch={fontStretch}
      fontVariant={fontVariant}
      whiteSpace={whiteSpace}
      delay={delay}
      isEven={isEven}
    />
  );
};

const formatTime = (
  time: Dayjs,
  type: TextClockPartsSetting["clock"]["type"],
  customFormat: string
) => {
  switch (type) {
    case "DayOfYear":
      return `${time.dayOfYear()}`;
    case "MinuteOfDay":
      return `${time.hour() * 60 + time.minute()}`;
    case "SecondOfDay":
      return `${time.hour() * 3600 + time.minute() * 60 + time.second()}`;
    case "SecondOfHour":
      return `${time.minute() * 60 + time.second()}`;
    case "Custom":
      return time.format(customFormat);
    default:
      return time.format(formats[type]);
  }
};

export default TextClockParts;
