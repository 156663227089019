import React, { useRef } from "react";
import styled from "styled-components";

import { Centered } from "components/layout";
import { ClockAreaContextProvider } from "hooks";
import { useElementSize } from "hooks/size";
import { backgroundStyle, invertedColor } from "lib/style";

type ClockAreaProps = {
  className?: string;
  aspectRatio?: number;
  background: string;
  displayBorder?: boolean;
};

const ClockArea: React.FC<ClockAreaProps> = ({
  className,
  aspectRatio,
  background,
  displayBorder,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerSize = useElementSize(ref);

  let borderColor = undefined;
  if (displayBorder) {
    borderColor = invertedColor(background);
  }

  return (
    <Wrapper
      className={className}
      ref={ref}
      style={backgroundStyle(background)}
    >
      <Centered>
        <ClockAreaContextProvider
          aspectRatio={aspectRatio}
          containerSize={containerSize}
          borderColor={borderColor}
        >
          {children}
        </ClockAreaContextProvider>
      </Centered>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  color: #fff;
  pointer-events: none;
  user-select: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow: hidden;
`;

export default ClockArea;
