import { range } from "lib/utils/utils";

import {
  CircularCoordinate,
  RectangularCoordinate,
  Dot,
  DotList,
  Radian,
} from "./coordinate";

import { Coordinator } from ".";

class CircularCoordinator implements Coordinator {
  public calc = (num: number): DotList => {
    const baseCircle = new Dot(
      RectangularCoordinate.ZERO,
      1,
      Radian.M_PI_2.mul(-1)
    );
    return new DotList(
      this.divideCircleByFactor(60, baseCircle).slice(0, num + 1)
    );
  };

  private divideCircleByFactor = (factor: number, circle: Dot): Dot[] => {
    const theta: Radian = Radian.M_2PI.div(factor);
    const theta2: Radian = theta.div(2);
    const r: number =
      circle.radius * (Math.sin(theta2.value) / (1 + Math.sin(theta2.value)));

    // 内接円の中心座標(円座標系、原点は元円の中心)を計算してから
    // 直交座標系に変換し、元円の中心座標を加える
    const inscribedCircleCenter: RectangularCoordinate = new CircularCoordinate(
      circle.radius - r,
      circle.angle
    )
      .toRectangular()
      .add(circle.center);

    const inscribedCircleBase = new Dot(inscribedCircleCenter, r, circle.angle);
    const circles: Dot[] = range(factor).map((i) =>
      inscribedCircleBase.rotate(circle.center, theta.mul(i))
    );

    return circles;
  };
}

export default CircularCoordinator;
