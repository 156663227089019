import Factors from "../Factors";

import { FactorSorter } from ".";

const ascendingComparator = (a: number, b: number): number => a - b;
const descendingComparator = (a: number, b: number): number => b - a;
const legacyClockFComparator = (a: number, b: number): number => {
  // a, b共に2, 4でない場合は降順
  if (a !== 2 && a !== 4 && b !== 2 && b !== 4) {
    return b - a;
  }
  // bのみが2か4
  if (a !== 2 && a !== 4) {
    return -1;
  }
  // aのみが2か4
  if (b !== 2 && b !== 4) {
    return 1;
  }
  // a, b共に2か4
  return a - b;
};

export enum SortOrder {
  Ascending = "Asc",
  Descending = "Desc",
  Classic = "Classic",
}

class SimpleFactorSorter implements FactorSorter {
  protected order: SortOrder = SortOrder.Classic;

  protected mergeFactors: Factors[] = [];

  constructor(mergeFactors: Factors[]) {
    this.mergeFactors = mergeFactors;
  }

  public sort = (factors: Factors): Factors => {
    let comparator: (a: number, b: number) => number;
    switch (this.order) {
      case SortOrder.Ascending:
        comparator = ascendingComparator;
        break;
      case SortOrder.Descending:
        comparator = descendingComparator;
        break;
      case SortOrder.Classic:
        comparator = legacyClockFComparator;
        break;
      default:
        comparator = ascendingComparator;
    }

    let mergedFactors = factors;
    this.mergeFactors.forEach((mf) => {
      mergedFactors = mergedFactors.mergeFactors(mf);
    });
    return mergedFactors.sort(comparator);
  };
}

export class AscendingSimpleFactorSorter extends SimpleFactorSorter {
  protected order: SortOrder = SortOrder.Ascending;
}

export class DescendingSimpleFactorSorter extends SimpleFactorSorter {
  protected order: SortOrder = SortOrder.Descending;
}

export class ClassicSimpleFactorSorter extends SimpleFactorSorter {
  protected order: SortOrder = SortOrder.Classic;
}

export default SimpleFactorSorter;
