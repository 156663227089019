import { JSONSchema7, JSONSchema7Definition } from "json-schema";

import { generateTimelineSettingSchema } from "lib/schema";

const SimpleDotClockPartsSetting: JSONSchema7Definition = {
  title: "シンプルドット時計",
  description: "シンプルドット時計の設定",
  type: "object",
  additionalProperties: false,
  properties: {
    type: {
      title: "パーツタイプ",
      enum: ["SimpleDotClock"],
    },
    common: { $ref: "#/definitions/ClockPartsCommonSetting" },
    clock: { $ref: "#/definitions/SimpleDotClockPartsIndividualSetting" },
    timeline: { $ref: "#/definitions/SimpleDotClockPartsTimelineSetting" },
  },
  required: ["type", "common", "clock"],
};

const SimpleDotClockPartsIndividualSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "シンプルドット時計の個別設定",
  properties: {
    type: {
      $ref: "#/definitions/SimpleDotClockPartsType",
      default: "Second",
    },
    color: {
      type: "string",
      description: "ドット色",
      default: "#fff",
    },
    duration: {
      type: "number",
      description: "ドット時計のアニメーション時間（ミリ秒）",
      minimum: 0,
      maximum: 1000,
      default: 300,
    },
    delay: {
      type: "number",
      description: "ドット時計のアニメーションの開始遅延時間（ミリ秒）",
      minimum: 0,
      maximum: 1000,
      default: 0,
    },
    dotScale: {
      type: "number",
      description: "ドットのスケール",
      minimum: 0,
      default: 0.8,
    },
    coordinator: {
      enum: ["CircularCoordinator"],
    },
  },
  required: ["type"],
};

const SimpleDotClockPartsTimelineSetting = generateTimelineSettingSchema(
  SimpleDotClockPartsIndividualSetting
);

const SimpleDotClockPartsType: JSONSchema7Definition = {
  enum: ["Hour", "Minute", "Second"],
  description: "ドット時計のタイプ",
};

export const SimpleDotClockPartsDefinitions: JSONSchema7["definitions"] = {
  SimpleDotClockPartsSetting,
  SimpleDotClockPartsIndividualSetting,
  SimpleDotClockPartsTimelineSetting,
  SimpleDotClockPartsType,
};
