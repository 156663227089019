import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import { clocks } from "clock/parts";
import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";

type ClockPartsDocumentContentProps = {
  className?: string;
  id?: string;
};

const ClockPartsSettingDocument: React.FC<ClockPartsDocumentContentProps> = ({
  className,
  id,
}) => {
  return (
    <Section className={className} id={id}>
      <Section.Header>
        <Link smooth to={`#${id}`}>
          Clock Parts Setting
        </Link>
      </Section.Header>
      <Section.Content>
        <S.P>時計パーツの設定です。</S.P>
      </Section.Content>
      <Section.PaneContent>
        <Section.ContentMain>
          <S.ItemList>
            <S.ItemListHeader>Properties</S.ItemListHeader>
            <S.List>
              <S.ListItem>
                <AnchorContainer id={`${id}-type`}>
                  <S.ListItemLabel>type</S.ListItemLabel>
                  <S.ListItemValidation>string</S.ListItemValidation>
                  <S.Required />
                </AnchorContainer>
                <S.ListItemDescription>時計のタイプ。</S.ListItemDescription>
                <S.ListItemDescription>
                  指定可能な値は、
                  {Object.keys(clocks).map((type, i) => (
                    <React.Fragment key={type}>
                      <Link smooth to={`/docs#${type}`}>
                        <S.Code>{type}</S.Code>
                      </Link>
                      {(i < Object.keys(clocks).length - 1 && ", ") || ""}
                    </React.Fragment>
                  ))}
                  です。
                </S.ListItemDescription>
                <S.ListItemDescription>
                  ここで設定したタイプに応じて、
                  <S.Code>clock</S.Code>
                  に時計ごとの個別設定をする必要があります。
                </S.ListItemDescription>
              </S.ListItem>

              <S.ListItem>
                <AnchorContainer id={`${id}-common`}>
                  <S.ListItemLabel>common</S.ListItemLabel>
                  <S.ListItemValidation>
                    <Link smooth to="#ClockPartsCommonSetting">
                      ClockPartsCommonSetting
                    </Link>
                  </S.ListItemValidation>
                  <S.Required />
                </AnchorContainer>
                <S.ListItemDescription>
                  時計タイプによらない共通の設定。
                </S.ListItemDescription>
              </S.ListItem>

              <S.ListItem>
                <AnchorContainer id={`${id}-clock`}>
                  <S.ListItemLabel>clock</S.ListItemLabel>
                  <S.ListItemValidation>
                    <Link smooth to="#ClockPartsIndivisualSetting">
                      ClockPartsIndivisualSetting
                    </Link>
                  </S.ListItemValidation>
                  <S.Required />
                </AnchorContainer>
                <S.ListItemDescription>
                  時計タイプごとの設定。
                </S.ListItemDescription>
              </S.ListItem>
            </S.List>
          </S.ItemList>
        </Section.ContentMain>
      </Section.PaneContent>
    </Section>
  );
};

export default React.memo(ClockPartsSettingDocument);
