import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as T from "components/atoms/Table";
import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";

import { defaultAnalogClockPartsSetting } from "./setting";

type AnalogClockPartsDocumentProps = {
  className?: string;
  id?: string;
};

const AnalogClockPartsDocument: React.FC<AnalogClockPartsDocumentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Analog Clock
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>アナログ時計の設定です。</S.P>
          <S.P>
            ゲージに外枠をつけたい場合は、
            <Link smooth to="#ClockPartsCommonSetting">
              Clock Parts Common Setting
            </Link>
            から枠線の設定を追加してください。
          </S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-type`}>
                    <S.ListItemLabel>type</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#AnalogClockPartsType">
                        AnalogClockPartsType
                      </Link>
                    </S.ListItemValidation>
                    <S.Required />
                  </AnchorContainer>
                  <S.ListItemDescription>
                    アナログ時計のタイプ
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-handWidth`}>
                    <S.ListItemLabel>handWidth</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#SizeType">
                        size
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>針の幅</S.ListItemDescription>
                  <S.ListItemDescription>
                    <Link smooth to="#SizeType">
                      size
                    </Link>
                    のうち、<S.Code>w</S.Code>,<S.Code>h</S.Code>
                    の単位を利用可能です。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;2h&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-color`}>
                    <S.ListItemLabel>color</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>針の色</S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;#fff&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-delay`}>
                    <S.ListItemLabel>delay</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    針が動くまでの遅延時間
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>300</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <DocumentClock partsSetting={defaultAnalogClockPartsSetting} />
            </S.Contents>
            <S.Contents>
              <T.Container id="AnalogClockPartsType">
                <T.Title>AnalogClockPartsType</T.Title>
                <T.Table>
                  <T.Row>
                    <T.Header>HourMinuteSecond</T.Header>
                    <T.Data>時針、分針、秒針を表示</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>HourMinute</T.Header>
                    <T.Data>時針、分針を表示</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Hour</T.Header>
                    <T.Data>時針を表示</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Minute</T.Header>
                    <T.Data>分針を表示</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Second</T.Header>
                    <T.Data>秒針を表示</T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(AnalogClockPartsDocument);
