/* eslint-disable @typescript-eslint/no-explicit-any */
import Ajv from "ajv";

import { ClockSetting, ClockFSettingSchema, defaultClockSetting } from "clock";
import { transform } from "lib/utils/object";
import { range } from "lib/utils/utils";

import * as LocalStorage from "./storage";

const DEFAULT_CLOCK_SETTING_KEY = "DEFAULT_CLOCK_SETTING";
const PREVIOUS_CLOCK_SETTING = "PREVIOUS_CLOCK_SETTING";
const WORKING_CLOCK_SETTING_KEY = "WORKING_CLOCK_SETTING";
const SAVE_DATA_KEY_PREFIX = "SAVE_DATA_";

export const MAX_SLOT_NUMBER = 20;

const ajv = new Ajv({ allErrors: true, verbose: true });

/**
 * 指定スロットの時計設定を取得する
 * @param slot 取得対象スロット
 */
export const loadSlotClockSetting = (
  slot: number,
  defaultSetting?: ClockSetting | null
): ClockSetting | null => {
  const key = generateSaveDataKey(slot);
  return loadClockSetting(key, defaultSetting || null);
};

/**
 * デフォルト表示の時計設定を取得する
 */
export const loadDefaultClockSetting = (): ClockSetting => {
  return loadClockSetting(DEFAULT_CLOCK_SETTING_KEY, defaultClockSetting);
};

/**
 * １つ前の時計設定を取得する
 */
export const loadPreviousClockSetting = (): ClockSetting | null => {
  return loadClockSetting(PREVIOUS_CLOCK_SETTING, null);
};

/**
 * 現在(ClockPageで表示する)の時計設定を取得する
 */
export const loadWorkingClockSetting = (): ClockSetting | null => {
  return loadClockSetting(WORKING_CLOCK_SETTING_KEY, null);
};

/**
 * 時計設定を取得する
 * @param key 取得対象キー
 * @param defaultSetting データが存在しなかった場合のデフォルト設定
 */
const loadClockSetting = <T>(
  key: string,
  defaultSetting: T extends ClockSetting | null ? T : never
): ClockSetting | T => {
  const setting: ClockSetting | null = LocalStorage.get(key);

  if (!setting) {
    return defaultSetting;
  }

  try {
    if (!ajv.validate(ClockFSettingSchema, setting)) {
      if (setting.version == null || setting.version == 1) {
        const migratedSetting = migrateV1toV2(setting);
        if (ajv.validate(ClockFSettingSchema, migratedSetting)) {
          console.info("setting is migrated to V2");
          return migratedSetting;
        }
      }
      console.error(`invalid clock setting!! key:${key}`);
      return defaultSetting;
    }
  } catch (e) {
    console.error(`failed validate setting!! key:${key}`);
    return defaultSetting;
  }

  return setting;
};

/**
 * 全スロットの設定を取得する
 */
export const loadAllSlotClockSettings = (
  defaultSetting: ClockSetting | null
): (ClockSetting | null)[] => {
  return range(1, MAX_SLOT_NUMBER + 1).map((slot) =>
    loadSlotClockSetting(slot, defaultSetting)
  );
};

/**
 * 全ての設定を取得する
 */
export const loadAllClockSettings = (): {
  default: ClockSetting;
  working: ClockSetting | null;
  previous: ClockSetting | null;
  slots: (ClockSetting | null)[];
} => {
  return {
    default: loadDefaultClockSetting(),
    working: loadWorkingClockSetting(),
    previous: loadPreviousClockSetting(),
    slots: loadAllSlotClockSettings(null),
  };
};

/**
 * 時計設定を保存する
 * @param slot 保存対象スロット
 * @param setting 時計設定
 */
export const saveSlotClockSetting = (
  slot: number,
  setting: ClockSetting
): void => {
  const key = generateSaveDataKey(slot);
  LocalStorage.set(key, setting);
};

/**
 * デフォルト表示の時計設定を保存する
 * @param setting 時計設定
 */
export const saveDefaultClockSetting = (setting: ClockSetting): void => {
  // 既存の設定を１つ前の設定として保存
  const currentClockSetting = loadDefaultClockSetting();
  if (currentClockSetting) {
    savePreviousClockSetting(currentClockSetting);
  }
  LocalStorage.set(DEFAULT_CLOCK_SETTING_KEY, setting);
};

/**
 * １つ前の時計設定を保存する
 * @param setting 時計設定
 */
export const savePreviousClockSetting = (setting: ClockSetting): void => {
  LocalStorage.set(PREVIOUS_CLOCK_SETTING, setting);
};

/**
 * 現在(ClockPageで表示する)の時計設定を取得する
 */
export const saveWorkingClockSetting = (setting: ClockSetting): void => {
  LocalStorage.set(WORKING_CLOCK_SETTING_KEY, setting);
};

const generateSaveDataKey = (slot: number): string =>
  `${SAVE_DATA_KEY_PREFIX}${`00${slot}`.slice(-2)}`;

export const deleteClockSetting = (slot: number): void => {
  const key = generateSaveDataKey(slot);
  LocalStorage.del(key);

  // 削除されたとこを除いで詰め直す
  const clockSettings = loadAllSlotClockSettings(null).filter(
    (setting) => !!setting
  );

  range(MAX_SLOT_NUMBER).forEach((i) => {
    const slot = i + 1;
    const setting = clockSettings[i];
    if (setting) {
      saveSlotClockSetting(slot, setting);
    } else {
      const key = generateSaveDataKey(slot);
      LocalStorage.del(key);
    }
  });
};

const migrateV1toV2 = (v1setting: any): ClockSetting => {
  const v1 = JSON.parse(JSON.stringify(v1setting));

  const coordinatorConverter = (coordinator: { [key: string]: any }) => {
    return transform(
      coordinator,
      {
        type: "coordinator:type",
        sort: "coordinator:sort",
        merge: "coordinator:merge",
      },
      {}
    );
  };
  const setting = transform(v1, {}, {}, [
    [
      ["coordinator", null],
      ["", coordinatorConverter],
    ],
  ]);

  setting.version = 2;
  return setting;
};
