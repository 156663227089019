import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { ClockSetting, emptyClockSetting } from "clock";
import { sampleClocks } from "clock/setting";
import { FullScreen } from "components/layout";
import { CloseButton } from "components/molecules/IconButton";
import SlotClock, { EmptySlotClock } from "components/molecules/SlotClock";
import { loadAllClockSettings } from "lib/savedata";
import { range } from "lib/utils/utils";

type OverlayClockListProps = {
  className?: string;
  currentSetting: ClockSetting;
  onChangeDefaultClock: (setting: ClockSetting) => void;
  onClickEditClock: (setting: ClockSetting, slot?: number) => void;
  onDeleteClock: (slot: number) => void;
  onSaveClock?: (setting: ClockSetting, slot: number) => void;
  onClose: () => void;
};
const OverlayClockList: React.FC<OverlayClockListProps> = ({
  className,
  currentSetting,
  onChangeDefaultClock,
  onClickEditClock,
  onDeleteClock,
  onSaveClock,
  onClose,
}) => {
  const [clockSettings, setClockSettings] = useState(() =>
    loadAllClockSettings()
  );

  const setDefaultClock = useCallback((setting: ClockSetting) => {
    onChangeDefaultClock(setting);
    onClose();
  }, []);

  const deleteClock = useCallback(
    (slot: number) => {
      if (
        !confirm("削除したら元に戻すことはできません。本当に削除しますか？")
      ) {
        return;
      }
      onDeleteClock(slot);
      setClockSettings(loadAllClockSettings());
    },
    [onDeleteClock]
  );

  const canSave = useMemo(() => !!onSaveClock, [onSaveClock]);
  const saveClock = useCallback(
    (setting: ClockSetting, slot: number) => {
      if (onSaveClock) {
        onSaveClock(setting, slot);
        setClockSettings(loadAllClockSettings());
      }
    },
    [onSaveClock]
  );

  const newSlot =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (clockSettings.slots as any).findLastIndex(
      (setting: ClockSetting | null) => !!setting
    ) + 2;

  return (
    <BluredFullScreen>
      <Wrapper className={className}>
        <ScrollContainer>
          <ClockListContainer>
            <StyledSlotClock
              setting={clockSettings.default}
              slotName="デフォルトで表示する時計"
              onClickEditClock={() => onClickEditClock(clockSettings.default)}
            />
            {clockSettings.previous && (
              <StyledSlotClock
                setting={clockSettings.previous}
                slotName="１つ前の時計（ヒストリー）"
                onClickSetDefaultClock={() =>
                  setDefaultClock(clockSettings.previous || emptyClockSetting)
                }
                onClickEditClock={() =>
                  onClickEditClock(clockSettings.previous || emptyClockSetting)
                }
              />
            )}
            {range(10).map((i) => (
              <StyledEmptySlotSpacer key={i} />
            ))}
          </ClockListContainer>
          <SectionTitleContainer>
            <SectionTitle>保存済みの時計設定</SectionTitle>
          </SectionTitleContainer>
          <ClockListContainer>
            {clockSettings.slots.map((setting, i) => {
              if (!setting) {
                return <React.Fragment key={i}></React.Fragment>;
              }
              const slot = i + 1;
              return (
                <StyledSlotClock
                  key={i}
                  setting={setting}
                  slotName={`スロット ${slot}`}
                  onClickSetDefaultClock={
                    setting ? () => setDefaultClock(setting) : undefined
                  }
                  onClickEditClock={() =>
                    onClickEditClock(setting || emptyClockSetting, slot)
                  }
                  onClickDelete={setting ? () => deleteClock(slot) : undefined}
                  onClickSave={
                    canSave ? () => saveClock(currentSetting, slot) : undefined
                  }
                />
              );
            })}
            <StyledSlotClock
              setting={null}
              slotName={`新規スロット`}
              onClickSetDefaultClock={undefined}
              onClickEditClock={() =>
                onClickEditClock(emptyClockSetting, newSlot)
              }
              onClickDelete={undefined}
              onClickSave={
                canSave ? () => saveClock(currentSetting, newSlot) : undefined
              }
            />
            {range(10).map((i) => (
              <StyledEmptySlotSpacer key={i} />
            ))}
          </ClockListContainer>
          <SectionTitleContainer>
            <SectionTitle>サンプル設定</SectionTitle>
          </SectionTitleContainer>
          <ClockListContainer>
            {sampleClocks.map((setting, i) => {
              if (!setting) {
                return <React.Fragment key={i}></React.Fragment>;
              }
              const sampleSlot = i + 1;
              return (
                <StyledSlotClock
                  key={i}
                  setting={setting}
                  slotName={`サンプル ${sampleSlot}`}
                  onClickSetDefaultClock={
                    setting ? () => setDefaultClock(setting) : undefined
                  }
                />
              );
            })}
            {range(10).map((i) => (
              <StyledEmptySlotSpacer key={i} />
            ))}
          </ClockListContainer>
        </ScrollContainer>
        <StyledCloseButton label="Close" onClick={onClose} />
      </Wrapper>
    </BluredFullScreen>
  );
};

const BluredFullScreen = styled(FullScreen)`
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(16px);

  z-index: 100;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  padding: 32px;
  overflow-y: scroll;
`;

const ScrollContainer = styled.div``;

const ClockListContainer = styled.div`
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledSlotClock = styled(SlotClock)`
  margin: 8px;
`;

const StyledEmptySlotSpacer = styled(EmptySlotClock)`
  margin-left: 8px;
  margin-right: 8px;
`;

const StyledCloseButton = styled(CloseButton).attrs(() => ({ color: "#fff" }))`
  position: absolute;
  top: 32px;
  right: 32px;
  transform: scale(1.5);
`;

const SectionTitleContainer = styled(ClockListContainer)`
  padding: 32px 16px 0;
`;
const SectionTitle = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: white;
`;

export default React.memo(OverlayClockList);
