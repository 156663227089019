import dayjs from "dayjs";
import React, { useState } from "react";
import styled from "styled-components";

import {
  ClockSetting,
  ClockFSettingSchema,
  ClockFSettingSchemaForForm,
} from "clock";
import { Button, PrimaryButton } from "components/atoms/Button";
import { DatetimeInput } from "components/atoms/DatetimeInput";
import FormEditor from "components/editor/FormEditor";
import JsonEditor from "components/editor/JsonEditor";

const INITIAL_FIXED_TIME = "2023-12-31 12:34:56";

type SettingPanelProps = {
  setting: ClockSetting;
  isEdited: boolean;
  onUpdateSetting: (setting: ClockSetting) => void;
  onClickReturnToClock: () => void;
  onClickSetDefault?: () => void;
  onClickSave: () => void;
  type: "form" | "json";
  showDetails?: boolean;
  setShowDetails?: (value: boolean) => void;
  onClickAddParts?: () => void;
  setFixedTime?: (time: dayjs.Dayjs) => void;
  fixedTimeEnabled: boolean;
  setFixedTimeEnabled: (fixedTimeEnabled: boolean, timeValue: string) => void;
};
const SettingPanel: React.FC<SettingPanelProps> = ({
  setting,
  isEdited,
  onUpdateSetting,
  onClickReturnToClock,
  onClickSetDefault,
  onClickSave,
  type,
  showDetails,
  setShowDetails,
  onClickAddParts,
  setFixedTime,
  fixedTimeEnabled,
  setFixedTimeEnabled,
}) => {
  const [isOpenOption, setIsOpenOption] = useState(false);

  return (
    <Wrapper>
      <EditorContainer>
        {type === "form" ? (
          <FormEditor<ClockSetting>
            setting={setting}
            schema={ClockFSettingSchemaForForm}
            onUpdateSetting={onUpdateSetting}
            showDetails={showDetails}
            onClickAddParts={onClickAddParts}
          />
        ) : (
          <JsonEditor<ClockSetting>
            setting={setting}
            schema={ClockFSettingSchema}
            onUpdateSetting={onUpdateSetting}
          />
        )}
      </EditorContainer>
      <ButtonContainer>
        <Button onClick={onClickReturnToClock}>閉じる</Button>
        {onClickSetDefault && (
          <PrimaryButton onClick={onClickSetDefault}>
            デフォルトに設定
          </PrimaryButton>
        )}
        <PrimaryButton
          onClick={onClickSave}
          style={{ marginLeft: "auto" }}
          disabled={!isEdited}
        >
          保存
        </PrimaryButton>
      </ButtonContainer>
      <FloatingContainer
        style={{
          top: type === "form" ? 8 : 44,
          right: isOpenOption ? 8 : -295,
        }}
      >
        <Arrow onClick={() => setIsOpenOption(!isOpenOption)}>
          {isOpenOption ? "▶︎" : "◀︎"}
        </Arrow>
        <OptionContainer>
          <FixDatetimeContainer>
            <ToggleFixDatetime
              id="toggle-fix-time"
              type="checkbox"
              checked={fixedTimeEnabled}
              onChange={(event) => {
                const inputElement = document.getElementById(
                  "fixed-time"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ) as any;
                setFixedTimeEnabled(
                  event.target.checked,
                  inputElement.value || INITIAL_FIXED_TIME
                );
              }}
            />
            <DatetimeLabel htmlFor="toggle-fix-time">時刻を固定</DatetimeLabel>
            <StyledDatetimeInput
              id="fixed-time"
              defaultValue="2023-12-31 12:34:56"
              placeholder="2023-12-31 12:34:56"
              onChange={(time: dayjs.Dayjs) => {
                if (fixedTimeEnabled && setFixedTime) {
                  setFixedTime(time);
                }
              }}
            />
          </FixDatetimeContainer>
          <ToggleShowDetailsContainer>
            <ToggleShowDetails
              id="toggle-show-details"
              type="checkbox"
              checked={showDetails}
              onChange={(event) => {
                if (setShowDetails) {
                  setShowDetails(event.target.checked);
                }
              }}
            />
            <ToggleShowDetailsLabel htmlFor="toggle-show-details">
              詳細を表示する
            </ToggleShowDetailsLabel>
          </ToggleShowDetailsContainer>
        </OptionContainer>
      </FloatingContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  overflow-x: hidden;
`;

const EditorContainer = styled.div`
  height: calc(100% - 52px);
  > * {
    height: 100%;
  }
`;

const ButtonContainer = styled.div`
  height: 52px;
  padding-left: 16px;
  padding-right: 16px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  > button {
    margin: 0 8px;
  }

  border-top: 1px solid gray;
`;

const FloatingContainer = styled.div`
  position: absolute;
  width: 320px;
  top: 44px;
  right: 8px;
  background: #fff;

  z-index: 100;
  border: 1px solid rgb(79, 86, 107);
  display: flex;
  align-items: center;
  transition: right 500ms ease-in-out;
`;
const Arrow = styled.div`
  height: 100px;
  background: rgba(79, 86, 107, 0.2);
  line-height: 100px;
  padding: 0 4px;
  user-select: none;
  cursor: pointer;
`;
const OptionContainer = styled.div`
  padding: 16px;
`;
const FixDatetimeContainer = styled.div`
  height: 30px !important;

  display: flex;
  align-items: center;
`;
const ToggleFixDatetime = styled.input``;

const DatetimeLabel = styled.label`
  display: inline-block;
  user-select: none;
  padding-left: 8px;
  padding-top: 8px;
  margin-right: 12px;
`;

const StyledDatetimeInput = styled(DatetimeInput)`
  border: 1px solid rgb(79, 86, 107);
  padding: 0 4px;
  width: 160px;
`;

const ToggleShowDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 5;
`;
const ToggleShowDetails = styled.input``;
const ToggleShowDetailsLabel = styled.label`
  display: inline-block;
  user-select: none;
  padding-left: 8px;
  padding-top: 8px;
`;

export default React.memo(SettingPanel);
