import { FontWeight } from "types";

import { AnalogClockPartsSetting } from "./AnalogClock";
import { CountPartsSetting } from "./Count";
import { FactorDotClockPartsSetting } from "./FactorDotClock";
import { GaugePartsSetting } from "./Gauge";
import { ImagePartsSetting } from "./Image";
import { LabelPartsSetting } from "./Label";
import { SimpleDotClockPartsSetting } from "./SimpleDotClock";
import { TextClockPartsSetting } from "./TextClock";

export type ClockSetting = {
  title?: string;
  background?: string;
  aspectRatio?: number | null; // 画面の横/縦
  parts: ClockPartsSetting[];
  version?: 1 | 2;
};

export type ClockPartsSetting =
  | SimpleDotClockPartsSetting
  | FactorDotClockPartsSetting
  | TextClockPartsSetting
  | AnalogClockPartsSetting
  | LabelPartsSetting
  | ImagePartsSetting
  | CountPartsSetting
  | GaugePartsSetting;

export type ClockPartsType =
  | "FactorDotClock"
  | "SimpleDotClock"
  | "TextClock"
  | "AnalogClock"
  | "Label"
  | "Image"
  | "Count"
  | "Gauge";

export type ClockPartsCommonSetting = {
  x?: string; // 中心のx座標 中心が0
  y?: string; // 中心のy座標 中心が0
  width?: string; // 幅
  height?: string; // 高さ
  rotate?: number; // 回転
  opacity?: number;
  background?: string; // 背景色
  borderWidth?: string; // ボーダーの太さ
  borderStyle?: string; // ボーダーのスタイル
  borderColor?: string; // ボーダーの色
  borderRadius?: string; // 角丸の半径
  filter?: string; // 背景フィルター
  blend?: React.CSSProperties["mixBlendMode"];
};

export type TimelineType =
  | "Year"
  | "Month"
  | "Day"
  | "Hour"
  | "Minute"
  | "Second"
  | "DayOfYear"
  | "MinuteOfYear"
  | "SecondOfYear"
  | "MinuteOfDay"
  | "SecondOfDay"
  | "SecondOfHour";
export type TimelineOptions = {
  duration?: number;
  delay?: number;
  timingFunction?: React.CSSProperties["transitionTimingFunction"];
  divisor?: number | null;
};
export type TimelineSetting<T extends ClockPartsSetting> = {
  type?: TimelineType;
  values: TimelineItem<T>[];
} & TimelineOptions;

export type TimelineApplyMethodType = "merge" | "spot";
export type TimelineItem<T extends ClockPartsSetting> = {
  t: number; // 時間
  apply?: TimelineApplyMethodType;
  common?: Partial<T["common"]>;
  clock?: Partial<T["clock"]>;
};

export type LabelPartsCommonSetting = {
  color?: string;
  fontSize?: number;
  fontWeight?: FontWeight;
  fontFamily?: React.CSSProperties["fontFamily"];
  fontSizeAdjust?: React.CSSProperties["fontSizeAdjust"];
  fontStretch?: React.CSSProperties["fontStretch"];
  fontVariant?: React.CSSProperties["fontVariant"];
  whiteSpace?: React.CSSProperties["whiteSpace"];
};

type TimeSetting<T extends ClockPartsSetting> = {
  base: ClockPartsSetting;
  timeline?: {
    type?: TimelineType;
    value: (n: number) => TimelineItem<T>;
    duration?: number;
    delay?: number;
    timingFunction?: React.CSSProperties["transitionTimingFunction"];
    divisor?: number | null;
  };
};

export const generateTimelineSetting = <T extends ClockPartsSetting>(
  setting: T
): TimeSetting<T> => {
  if (!setting.timeline) {
    return { base: setting };
  }

  const baseValue: TimelineItem<T> = {
    t: 0,
    apply: "merge",
    common: setting.common,
    clock: setting.clock,
  };

  const sortedValues: TimelineItem<T>[] = [
    baseValue,
    ...setting.timeline.values,
  ]
    .slice()
    .sort((a: TimelineItem<T>, b: TimelineItem<T>) => a.t - b.t);

  let recentValue: TimelineItem<T> = baseValue;
  const sortedMergedValues: TimelineItem<T>[] = sortedValues.map((_value) => {
    const _recentValue = JSON.parse(JSON.stringify(recentValue));
    const apply = _value.apply ?? "merge";
    const value = {
      t: _value.t,
      apply,
      common: _value.common
        ? { ..._recentValue.common, ..._value.common }
        : _recentValue.common,
      clock: (_value.clock
        ? { ..._recentValue.clock, ..._value.clock }
        : _recentValue.clock) as T["clock"],
    };
    if (apply) {
      recentValue = value;
    }
    return value;
  });

  const getTimelineItem = (n: number) => {
    let lastValue: TimelineItem<T> = sortedMergedValues[0];
    for (const value of sortedMergedValues) {
      if (value.t < n) {
        if (value.apply === "merge") {
          // retain = true の場合だけ、値を保持する
          lastValue = value;
        }
      } else if (value.t === n) {
        // 時刻どんぴしゃの設定がある場合は、その値を返す
        return value;
      } else {
        return lastValue;
      }
    }

    return lastValue;
  };

  return {
    base: setting,
    timeline: {
      type: setting.timeline.type,
      value: getTimelineItem,
      duration: setting.timeline.duration,
      delay: setting.timeline.delay,
      timingFunction: setting.timeline.timingFunction,
      divisor: setting.timeline.divisor,
    },
  };
};
