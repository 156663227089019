import QRCode from "qrcode.react";
import React from "react";
import styled from "styled-components";

import { FullScreen } from "components/layout";

type OverlayMenuProps = {
  className?: string;
  shareURL: string;
  onClose: () => void;
};
const OverlayQRCode: React.FC<OverlayMenuProps> = ({
  className,
  shareURL,
  onClose,
}) => {
  return (
    <BluredFullScreen>
      <Wrapper className={className} onClick={onClose}>
        <QRCode value={shareURL} size={240} includeMargin />
      </Wrapper>
    </BluredFullScreen>
  );
};

const BluredFullScreen = styled(FullScreen)`
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(16px);

  z-index: 100;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default React.memo(OverlayQRCode);
