import { invert } from "polished";

/**
 * iOSのアドレスバーなどに影響されないvhの値を '--vh' として定義し、
 * CSSから利用できるようにする。
 */
export const useCustomVH = (): void => {
  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  // 画面のサイズ変動があった時に高さを再計算する
  window.addEventListener("resize", setFillHeight);

  // 初期化
  setFillHeight();
};

/**
 * 反転色または白色（引数が色でなかった場合）
 */
export const invertedColor = (color: string, fallback?: string): string => {
  try {
    return invert(color);
  } catch (e) {
    return fallback || "#ffffff";
  }
};

export const backgroundStyle = (background?: string): React.CSSProperties => {
  if (!background) {
    return {};
  }

  if (background.substring(0, 3) === "url") {
    return {
      backgroundImage: background,
      backgroundSize: "cover",
    };
  }

  return { background };
};

export type TransitionSetting = [
  React.CSSProperties["transitionProperty"],
  React.CSSProperties["transitionDuration"] | number,
  React.CSSProperties["transitionTimingFunction"],
  React.CSSProperties["transitionDelay"] | number
];
export const generateTransitionStyle = (
  defaultTargetProperties: React.CSSProperties["transitionProperty"][],
  additionalTransitionSettings?: TransitionSetting[]
): string => {
  const transitions = defaultTargetProperties.map(
    (property) =>
      `${property} var(--transition-duration) var(--transition-timing-function) var(--transition-delay)`
  );
  const additionalTransitions = (additionalTransitionSettings || []).map(
    ([property, duration, timingFunction, delay]) => {
      const fragments: string[] = [];
      fragments.push(property || "");
      if (!isNaN(duration as number)) {
        fragments.push(`${duration}ms`);
      } else {
        fragments.push(`${duration}`);
      }
      fragments.push(timingFunction || "");
      if (!isNaN(delay as number)) {
        fragments.push(`${delay}ms`);
      } else {
        fragments.push(`${delay}`);
      }
      return fragments.join(" ");
    }
  );

  return transitions.concat(additionalTransitions).join(", ");
};
