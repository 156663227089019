import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";

/**
 * 与えられた配列の要素からなる順列の組み合わせを全て返す
 * 配列内に同一要素が複数存在する場合、重複が発生する。
 * 重複を排除の排除は`uniq()`で可能。
 *
 * @param array 対象の配列
 */
export const permutation = <T>(array: T[]): T[][] => {
  const result: T[][] = [];
  if (array.length === 1) {
    result.push(array);
    return result;
  }
  for (let i = 0; i < array.length; i++) {
    const firstElem = array.slice(i, i + 1);
    const restElem = [...array.slice(0, i), ...array.slice(i + 1)];
    const innerPermutations = permutation(restElem);
    for (const innerPermutation of innerPermutations) {
      result.push([...firstElem, ...innerPermutation]);
    }
  }

  return result;
};

/**
 * 配列から重複を排除
 * @param array 対象の配列
 */
export const uniq = <T>(array: T[]): T[] => {
  return uniqWith(array, isEqual);
};

/**
 * 対象の配列にsearchElementsに含まれる要素が全て含まれるかを判定
 * @param array 対象の配列
 * @param searchElements 検査する要素の配列
 */
export const contains = <T>(array: T[], searchElements: T[]): boolean => {
  const restArray: T[] = [...array];
  for (const searchElement of searchElements) {
    const index = restArray.indexOf(searchElement);
    if (index === -1) {
      return false;
    }
    restArray.splice(index, 1);
  }
  return true;
};

/**
 * 対象の配列からtargetElementsに含まれる要素を1回のみ除外した差分を返す
 * @param array 対象の配列
 * @param targetElements 除外する要素の配列
 */
export const differenceOne = <T>(array: T[], targetElements: T[]): T[] => {
  const restArray: T[] = [...array];
  for (const targetElement of targetElements) {
    const index = restArray.indexOf(targetElement);
    if (index === -1) {
      throw new Error(`Array don't have target element: ${targetElement}`);
    }
    restArray.splice(index, 1);
  }
  return restArray;
};

/**
 * 数配列の積を求める
 * @param array 対象の配列
 */
export const product = (array: number[]): number => {
  return array.reduce((p, v) => p * v, 1);
};
