import Radian from "./Radian";
import RectangularCoordinate from "./RectangularCoordinate";

/**
 * 極座標系における座標
 */
class CircularCoordinate {
  private _radial: number;
  private _angle: Radian;

  get radial(): number {
    return this._radial;
  }
  get angle(): Radian {
    return this._angle;
  }

  constructor(radial: number, angle: Radian) {
    if (radial < 0) {
      throw new Error("ValueError: radial value must be >= 0");
    }
    this._radial = radial;
    this._angle = angle;
  }

  /**
   * 座標を回転させる
   * @param angle 回転させる角度
   */
  public rotate = (angle: Radian): CircularCoordinate => {
    return this.mul(new CircularCoordinate(1, angle));
  };

  public mul = (other: CircularCoordinate | number): CircularCoordinate => {
    if (typeof other === "number") {
      return this.mul(new CircularCoordinate(other, new Radian(0)));
    } else {
      return new CircularCoordinate(
        this.radial * other.radial,
        this.angle.add(other.angle)
      );
    }
  };

  /**
   * 直交座標系の座標に変換する
   */
  public toRectangular = (): RectangularCoordinate => {
    return new RectangularCoordinate(
      this.radial * Math.cos(this.angle.value),
      this.radial * Math.sin(this.angle.value)
    );
  };
}

export default CircularCoordinate;
