import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";
import { publicURL } from "lib/url";

type ImagePartsDocumentProps = {
  className?: string;
  id?: string;
};

const ImagePartsDocument: React.FC<ImagePartsDocumentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Image
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>イメージの設定です。</S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-url`}>
                    <S.ListItemLabel>url</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                    <S.Required />
                  </AnchorContainer>
                  <S.ListItemDescription>画像URL</S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-objectFit`}>
                    <S.ListItemLabel>objectFit</S.ListItemLabel>
                    <S.ListItemValidation>
                      <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/object-fit">
                        css.object-fit
                      </S.A>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    画像の領域への嵌め込み方法
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <DocumentClock
                setting={{
                  title: "",
                  aspectRatio: 2,
                  background: "#333",
                  parts: [
                    {
                      type: "Image",
                      common: {
                        x: "0%",
                        y: "0%",
                        width: "100%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        url: publicURL("/images/wood.jpg"),
                        objectFit: "cover",
                      },
                    },
                    {
                      type: "FactorDotClock",
                      common: {
                        x: "35%",
                        y: "25h",
                        width: "50h",
                        height: "50h",
                        rotate: 0,
                        filter: "blur(1px)",
                        opacity: 0.8,
                        blend: "color-dodge",
                      },
                      clock: {
                        type: "Second",
                        color: "#43302C",
                      },
                    },
                  ],
                }}
              />
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(ImagePartsDocument);
