import dayjs from "dayjs";
import { produce } from "immer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { ClockPartsSetting, ClockSetting, defaultClockSetting } from "clock";
import SVGIcon from "components/atoms/SVGIcon";
import Clock from "components/clock/Clock";
import Tabs from "components/layout/Tabs";
import { SettingContextProvider } from "hooks";
import { useFixedTick } from "hooks/tick";
import {
  loadSlotClockSetting,
  loadWorkingClockSetting,
  MAX_SLOT_NUMBER,
  saveDefaultClockSetting,
  saveSlotClockSetting,
  saveWorkingClockSetting,
} from "lib/savedata";

import InfoPanel from "./panel/InfoPanel";
import InventoryPanel from "./panel/InventoryPanel";
import SettingPanel from "./panel/SettingPanel";

/**
 * NOTE 2020/11/15
 * react-jsonschema-formを利用した設定フォームを作成しようとしたが、２つ問題があり断念
 *
 * 問題1
 * oneOfでobjectを選択する場合に、formDataの内容が正しくFormに反映されない
 * 例えば、DotClockを選択しているのに、TextClockを選択しているように表示される。
 * getMathingOptionの実装が悪そう
 * https://github.com/rjsf-team/react-jsonschema-form/blob/e673a9097e587f28ffe7d82647ff70ba33908bcb/packages/core/src/utils.js#L1107
 *
 * 問題2
 * 問題1と同じ箇所で、選択肢を切り替えた時の挙動がおかしい
 * おかしい動作はFormのomitExtraDataの設定により異なるが、どちらも正しい動作にはならない。
 * omitExtraData = false 時計の設定(clockキー)の内容が複数の時計タイプの内容で混合されてしまう
 * omitExtraData = true 時計タイプを切り替えたタイミングでparts（リスト）がundefined
 */

/**
 * 時計をカスタマイズするエディタページ
 */
const ClockEditorPage: React.FC = () => {
  const history = useHistory();

  // 表示するタブの設定
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedEditTabIndex, setSelectedEditTabIndex] = useState(0);
  useEffect(() => {
    setSelectedEditTabIndex((currentSelectedTabIndex) => {
      // 編集タブ（設定 or JSON）の場合保持
      if (tabIndex === 0 || tabIndex === 1) {
        return tabIndex;
      }
      return currentSelectedTabIndex;
    });
  }, [tabIndex]);

  // 設定のロードおよび保存関連の処理
  const location = useLocation();

  // スロット番号の取得、デフォルト時計の場合はnull
  const slot = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const rawSlot = params.get("slot");
    if (rawSlot === null) {
      return null;
    }

    try {
      const slot = parseInt(rawSlot, 10);
      return slot;
    } catch (e) {
      console.warn(e);
    }
    return null;
  }, [location.search]);

  // 閉じた場合にリスト表示に戻るかどうか
  const isReturnToList = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("from") === "list";
  }, [location.search]);

  // スロットが最大値より大きい婆はスロットなしURLに置換
  useEffect(() => {
    if (slot !== null && slot > MAX_SLOT_NUMBER) {
      history.replace("/edit");
    }
  }, [slot]);

  // 最初のデータ
  const initialSetting = useMemo(() => {
    if (slot === null) {
      return loadWorkingClockSetting();
    }
    return loadSlotClockSetting(slot);
  }, [slot]);

  // 更新していくデータ
  const [setting, setSetting] = useState<ClockSetting>(
    initialSetting || defaultClockSetting
  );
  const [savedSetting, setSavedSetting] = useState<ClockSetting>(setting);

  // 設定が保存されてから更新されているか(テキストに変換して比較)
  const isEdited = useMemo(
    () => JSON.stringify(savedSetting) !== JSON.stringify(setting),
    [savedSetting, setting]
  );

  // 設定のリセット
  const resetSetting = useCallback(() => {
    if (
      confirm(
        "時計設定をリセットしてよろしいですか？\nこの変更は戻すことができません。"
      )
    ) {
      setSetting(defaultClockSetting);
    }
  }, []);

  // クリックパーツの追加
  const addClockParts = useCallback(
    (partsSetting: ClockPartsSetting) => {
      setSetting((setting) =>
        produce(setting, (draft) => {
          draft.parts.push(partsSetting);
        })
      );
      // 編集タブに遷移
      setTabIndex(selectedEditTabIndex);

      setTimeout(() => {
        // 追加したパーツにスクロールする
        document
          .getElementById(`root_parts_${setting.parts.length}`)
          ?.scrollIntoView();
      }, 100);
    },
    [selectedEditTabIndex, setting]
  );

  // 設定の更新ごとの作業中領域への保存
  useEffect(() => {
    saveWorkingClockSetting(setting);
  }, [setting]);

  // 時計画面に戻る
  const returnToClock = useCallback(() => {
    if (
      isEdited &&
      !confirm(
        [
          "保存されていない変更があります。",
          "保存する前に編集画面から離れると、変更は失われてしまいます。",
          "よろしいですか？",
        ].join("\n")
      )
    ) {
      return;
    }
    history.push("/", { clockList: isReturnToList });
  }, [isEdited, isReturnToList]);

  // デフォルト時計に設定する
  const setDefault = useCallback(() => {
    saveDefaultClockSetting(setting);
    history.push("/");
  }, [setting]);

  // 設定の保存 スロットありなしで保存先が異なる
  const saveSetting = useCallback(() => {
    setSavedSetting(setting);

    if (slot === null) {
      saveDefaultClockSetting(setting);
      return;
    }
    saveSlotClockSetting(slot, setting);
  }, [setting, slot]);

  const [showFormDetails, setShowFormDetails] = useState(false);

  const moveToInventoryTab = useCallback(() => {
    setTabIndex(2);
  }, []);

  const { setFixedTime } = useFixedTick();
  const [fixedTimeEnabled, _setFixedTimeEnabled] = useState(false);
  const setFixedTimeEnabled = useCallback(
    (fixedTimeEnabled: boolean, timeValue: string) => {
      _setFixedTimeEnabled(fixedTimeEnabled);
      if (setFixedTime) {
        if (fixedTimeEnabled) {
          const txtTime = dayjs(timeValue);
          if (txtTime.isValid()) {
            setFixedTime(txtTime);
          }
        } else {
          setFixedTime(null);
        }
      }
    },
    []
  );

  useEffect(() => {
    return () => {
      if (setFixedTime) {
        setFixedTime(null);
      }
    };
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>{`clock-f / Edit Clock ${
          slot === null ? "(Default Clock)" : `(Slot ${slot})`
        }`}</title>
      </Helmet>
      <SettingContextProvider setting={setting}>
        <ClockGrid>
          <ClockWrapper>
            <Clock displayClockAreaBorder={true} />
          </ClockWrapper>
        </ClockGrid>
        <EditorGrid>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList>
              <BackButtonTab onClick={returnToClock}>
                <Icon type="left-arrow" />
              </BackButtonTab>
              <Tab>
                <TabIcon type="pen" />
                <TabLabel>設定</TabLabel>
              </Tab>
              <Tab>
                <TabIcon type="pen" />
                <TabLabel>JSON</TabLabel>
              </Tab>
              <Tab>
                <TabIcon type="choice" />
                <TabLabel>パーツ追加</TabLabel>
              </Tab>
              <Tab>
                <TabIcon type="info" />
                <TabLabel>その他</TabLabel>
              </Tab>
            </TabList>
            <TabPanelList>
              <SettingTabPanel>
                <SettingPanel
                  type="form"
                  setting={setting}
                  isEdited={isEdited}
                  onUpdateSetting={setSetting}
                  onClickReturnToClock={returnToClock}
                  onClickSetDefault={slot ? setDefault : undefined}
                  onClickSave={saveSetting}
                  showDetails={showFormDetails}
                  setShowDetails={setShowFormDetails}
                  onClickAddParts={moveToInventoryTab}
                  setFixedTime={setFixedTime}
                  fixedTimeEnabled={fixedTimeEnabled}
                  setFixedTimeEnabled={setFixedTimeEnabled}
                />
              </SettingTabPanel>
              <SettingTabPanel>
                <SettingPanel
                  type="json"
                  setting={setting}
                  isEdited={isEdited}
                  onUpdateSetting={setSetting}
                  onClickReturnToClock={returnToClock}
                  onClickSetDefault={slot ? setDefault : undefined}
                  onClickSave={saveSetting}
                  setFixedTime={setFixedTime}
                  fixedTimeEnabled={fixedTimeEnabled}
                  setFixedTimeEnabled={setFixedTimeEnabled}
                />
              </SettingTabPanel>
              <Tabs.TabPanel>
                <InventoryPanel addClock={addClockParts} />
              </Tabs.TabPanel>
              <Tabs.TabPanel>
                <InfoPanel
                  setting={setting}
                  onClickResetSetting={resetSetting}
                />
              </Tabs.TabPanel>
            </TabPanelList>
          </Tabs>
        </EditorGrid>
      </SettingContextProvider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-left: env(safe-area-inset-left, 0);
  padding-right: env(safe-area-inset-right, 0);
`;

const GridArea = styled.div`
  width: 100%;
  height: 100%;
`;

const ClockGrid = styled(GridArea)`
  position: fixed;

  display: flex;
  justify-content: center;
  align-items: center;
  /* height: calc(100vh);
  height: calc(var(--vh, 1vh) * 100); */

  @media screen and (min-width: 1024px) {
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(100% - 640px);
  }

  @media screen and (max-width: 1024px) {
    height: 180px;
    left: env(safe-area-inset-left, 0);
    right: env(safe-area-inset-right, 0);
  }
`;

const EditorGrid = styled(GridArea)`
  > * {
    background-color: #fff;
  }

  @media screen and (min-width: 1024px) {
    padding-left: calc(100% - 640px);
  }

  @media screen and (max-width: 1024px) {
    min-height: 480px;
    padding-top: 180px;
  }
`;

const ClockWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1024px) {
    padding: 0px;
    padding-right: calc(env(safe-area-inset-right, 0) * 2);
  }
`;

const TabLabel = styled.span`
  font-weight: bold;
`;

const Icon = styled(SVGIcon).attrs(() => ({ size: 18 }))`
  margin-right: 4px;
`;

const TabIcon = styled(Icon)``;

const TabList = styled(Tabs.TabList)`
  padding-right: 16px;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  background-color: #fff;
  z-index: 1;

  @media screen and (min-width: 1024px) {
    position: fixed;
  }

  > li[class$="__tab--selected"] {
    svg {
      fill: black;
    }
    ${TabLabel} {
      /* font-weight: bold; */
      border-bottom: 1px solid black;
    }
  }

  @media screen and (max-width: 400px) {
    ${TabIcon} {
      display: none;
    }
  }

  border-bottom: 1px solid gray;
`;

const BackButtonTab = styled.div`
  text-align: center;
  padding: 16px 16px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
`;

const Tab = styled(Tabs.Tab)`
  min-width: 60px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
`;

const TabPanelList = styled(Tabs.TabPanelList)`
  @media screen and (min-width: 1024px) {
    padding-top: 52px;
  }
`;

const SettingTabPanel = styled(Tabs.TabPanel)`
  @media screen and (min-width: 1024px) {
    min-height: 480px;
    height: calc(100vh - 52px);
    height: calc(var(--vh, 1vh) * 100 - 52px);
  }

  @media screen and (max-width: 1024px) {
    min-height: 480px;
    height: calc(100vh - 180px - 52px);
    height: calc(var(--vh, 1vh) * 100 - 180px - 52px);
  }

  > * {
    height: 100%;
  }
`;

export default ClockEditorPage;
