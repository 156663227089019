import { LabelPartsSetting } from "./type";

export const defaultLabelPartsSetting: LabelPartsSetting = {
  type: "Label",
  common: {
    x: "0%",
    y: "0%",
    width: "100%",
    height: "100%",
    rotate: 0,
  },
  clock: {
    text: "clock-f",
    color: "#fff",
    fontSize: 0.2,
    fontWeight: "normal",
    whiteSpace: "pre-wrap",
  },
};
