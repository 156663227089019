import dayjs from "dayjs";
import React, { useEffect } from "react";
import { forceCheck } from "react-lazyload";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import { useWindowSizeEffect } from "hooks";
import { FixedTickContextProvider, TickContextProvider } from "hooks/tick";
import { useCustomVH } from "lib/style";
import ClockPage from "pages/clock";
import V1URLParamsClockPage from "pages/clock/V1URLParamsClockPage";
import ClockDocumentPage from "pages/docs/clock";
import SettingDocumentPage from "pages/docs/setting";
import JSONClockMakerPage from "pages/edit";

import "dayjs/locale/ja";

dayjs.locale("ja");

useCustomVH();

const App = (): JSX.Element => {
  return (
    <FixedTickContextProvider>
      <TickContextProvider>
        {/* <Router basename="/clock-f"> */}
        <Router>
          <AppInner />
        </Router>
      </TickContextProvider>
    </FixedTickContextProvider>
  );
};

const AppInner = (): JSX.Element => {
  // ウィンドウサイズ変更時に、react-lazyload使用部分のチェックを実施する
  useWindowSizeEffect(() => setTimeout(forceCheck, 0));

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");

      const element = document.getElementById(id);
      if (!element) {
        return;
      }
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  }, [location.pathname]);

  return (
    <>
      <Switch>
        <Route strict exact path="/" component={ClockPage} />
        <Redirect strict exact from="/edit" to="/edit/" />
        <Route strict exact path="/edit/" component={JSONClockMakerPage} />
        <Redirect strict exact from="/clock" to="/clock/" />
        <Route strict exact path="/clock/" component={V1URLParamsClockPage} />
        <Redirect strict exact from="/docs" to="/docs/" />
        <Route strict exact path="/docs/" component={ClockDocumentPage} />
        <Redirect strict exact from="/docs/setting" to="/docs/setting/" />
        <Route
          strict
          exact
          path="/docs/setting/"
          component={SettingDocumentPage}
        />
        <Redirect strict exact from="" to="/" />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

export default App;
