import Radian from "./Radian";

import { RectangularCoordinate } from ".";

class Dot {
  /** 描画ドットの中心点(直交座標系) */
  public center: RectangularCoordinate;
  /** 描画ドットの範囲半径 */
  public radius: number;
  /** 描画ドットの回転角 */
  public angle: Radian;
  /** 描画するかどうか */
  public visible: boolean;

  constructor(
    center: RectangularCoordinate,
    radius: number,
    angle: Radian,
    visible = true
  ) {
    this.center = center;
    this.radius = radius;
    this.angle = angle;
    this.visible = visible;
  }

  public rotate = (center: RectangularCoordinate, angle: Radian): Dot => {
    return new Dot(
      this.center.rotate(center, angle),
      this.radius,
      this.angle.add(angle)
    );
  };

  public clone = (): Dot => {
    return new Dot(this.center, this.radius, this.angle, this.visible);
  };

  public setVisible = (visible: boolean): Dot => {
    return new Dot(this.center, this.radius, this.angle, visible);
  };

  public static get ZERO(): Dot {
    return new Dot(RectangularCoordinate.ZERO, 0, Radian.ZERO);
  }
}

export default Dot;
