export const range = (first: number, second?: number): number[] => {
  if (second === undefined) {
    return range(0, first);
  }
  const [begin, end] = [first, second];
  return [...Array(end - begin)].map((_, i) => begin + i);
};

export const flatten = <T>(values: T[][]): T[] => {
  return values.flat();
};

export const isStandalone = (): boolean => {
  return window.matchMedia("(display-mode: standalone)").matches;
};
