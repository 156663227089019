import { SimpleDotClockPartsSetting } from "./type";

export const defaultSimpleDotClockPartsSetting: SimpleDotClockPartsSetting = {
  type: "SimpleDotClock",
  common: {
    x: "0%",
    y: "0%",
    width: "100%",
    height: "100%",
    rotate: 0,
  },
  clock: {
    type: "Second",
    color: "#ffffff",
    duration: 300,
    delay: 0,
    dotScale: 0.8,
    coordinator: "CircularCoordinator",
  },
};
