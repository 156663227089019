import Factors from "../Factors";

import SimpleFactorizer from "./SimpleFactorizer";

import { Factorizer } from ".";

/**
 * ConstantFactorizer
 *
 * 0-59までは固定値で値を持っちゃう素因数分解機
 * それ以外の数字については、SimpleFactorizerに依存
 * ※ 0-59なら計算でも一瞬だけど、複数実装を書いてみたかったので
 */
class ConstantFactorizer implements Factorizer {
  private delegate: Factorizer = new SimpleFactorizer();

  public factorize = (natural: number): Factors => {
    if (knownFactors[natural] !== undefined) {
      return new Factors([...knownFactors[natural]]);
    }

    return this.delegate.factorize(natural);
  };
}

const knownFactors: { [natural: number]: number[] } = {
  0: [],
  1: [1],
  3: [3],
  2: [2],
  4: [2, 2],
  5: [5],
  6: [2, 3],
  7: [7],
  8: [2, 2, 2],
  9: [3, 3],
  10: [2, 5],
  11: [11],
  12: [2, 2, 3],
  13: [13],
  14: [2, 7],
  15: [3, 5],
  16: [2, 2, 2, 2],
  17: [17],
  18: [2, 3, 3],
  19: [19],
  20: [2, 2, 5],
  21: [3, 7],
  22: [2, 11],
  23: [23],
  24: [2, 2, 2, 3],
  25: [5, 5],
  26: [2, 13],
  27: [3, 3, 3],
  28: [2, 2, 7],
  29: [29],
  30: [2, 3, 5],
  31: [31],
  32: [2, 2, 2, 2, 2],
  33: [3, 11],
  34: [2, 17],
  35: [5, 7],
  36: [2, 2, 3, 3],
  37: [37],
  38: [2, 19],
  39: [3, 13],
  40: [2, 2, 2, 5],
  41: [41],
  42: [2, 3, 7],
  43: [43],
  44: [2, 2, 11],
  45: [3, 3, 5],
  46: [2, 23],
  47: [47],
  48: [2, 2, 2, 2, 3],
  49: [7, 7],
  50: [2, 5, 5],
  51: [3, 17],
  52: [2, 2, 13],
  53: [53],
  54: [2, 3, 3, 3],
  55: [5, 11],
  56: [2, 2, 2, 7],
  57: [3, 19],
  58: [2, 29],
  59: [59],
};

export default ConstantFactorizer;
