import { range } from "lib/utils/utils";

import { ClockSetting } from "./type";

const classicClock: ClockSetting = {
  title: "Default Clock",
  background: "#000",
  aspectRatio: 1.78,
  parts: [
    {
      type: "TextClock",
      common: {
        x: "-25%",
        y: "0%",
        width: "40%",
        height: "30%",
        rotate: 0,
        opacity: 1,
      },
      clock: {
        type: "HourMinute",
        format: " ",
        text: "{CLOCK}",
        color: "white",
        fontSize: 0.2,
        fontWeight: 800,
        delay: 300,
      },
      timeline: {
        values: [],
      },
    },
    {
      type: "FactorDotClock",
      common: {
        x: "25%",
        y: "0%",
        width: "50%",
        height: "100%",
        rotate: 0,
        opacity: 1,
      },
      clock: {
        type: "Second",
        color: "white",
        duration: 300,
        delay: 0,
        dotScale: 0.8,
        dotType: "Circle",
        "coordinator:type": "ClassicFactorCoordinator",
        "coordinator:sort": "ClassicSimpleFactorSorter",
        "coordinator:merge": [4],
      },
      timeline: {
        values: [],
      },
    },
    {
      type: "TextClock",
      common: {
        x: "25%",
        y: "0%",
        width: "40%",
        height: "30%",
        rotate: 0,
        opacity: 1,
      },
      clock: {
        type: "Second",
        format: " ",
        text: "{CLOCK}",
        color: "#fff",
        fontSize: 0.05,
        fontWeight: 800,
        delay: 300,
      },
      timeline: {
        values: [
          {
            t: 1,
            clock: {
              color: "#000",
            },
          },
          {
            t: 2,
            clock: {
              color: "#fff",
            },
          },
        ],
        type: "Second",
      },
    },
  ],
};

const woodClock: ClockSetting = {
  title: "画像の利用（木目調デザイン）",
  background: "url(/images/wood.jpg)",
  aspectRatio: 1.78,
  parts: [
    {
      type: "Label",
      common: {
        x: "0%",
        y: "0%",
        width: "95w",
        height: "95w",
        rotate: 0,
        opacity: 1,
        borderRadius: "80h",
        filter: "blur(20px)",
        blend: "color-dodge",
        background: "#43302C",
      },
      clock: {
        text: "",
        color: "#43302C",
        fontSize: 0.2,
        fontWeight: 800,
      },
      timeline: {
        values: [],
      },
    },
    {
      type: "Label",
      common: {
        x: "-25%",
        y: "0%",
        width: "40%",
        height: "30%",
        rotate: 0,
        opacity: 1,
        blend: "hard-light",
      },
      clock: {
        text: "clock-f",
        color: "#43302C",
        fontSize: 0.2,
        fontWeight: 800,
      },
      timeline: {
        values: [],
      },
    },
    {
      type: "FactorDotClock",
      common: {
        x: "25%",
        y: "0%",
        width: "50%",
        height: "100%",
        rotate: 0,
        opacity: 1,
        blend: "hard-light",
      },
      clock: {
        type: "Second",
        color: "#43302C",
        duration: 300,
        delay: 0,
        dotScale: 0.8,
        dotType: "Circle",
        "coordinator:type": "ClassicFactorCoordinator",
        "coordinator:sort": "ClassicSimpleFactorSorter",
        "coordinator:merge": [4],
      },
      timeline: {
        values: [],
      },
    },
  ],
};

const rainbowClock: ClockSetting = {
  title: "Rainbow Color",
  background: "white",
  aspectRatio: 1.78,
  parts: [
    {
      type: "FactorDotClock",
      common: {
        x: "0",
        y: "0%",
        width: "50%",
        height: "100%",
      },
      clock: {
        type: "Second",
        color: "hsl(100 75% 25%)",
        "coordinator:type": "ClassicFactorCoordinator",
        "coordinator:sort": "ClassicSimpleFactorSorter",
        "coordinator:merge": [4],
        duration: 300,
      },
      timeline: {
        values: range(60).map((t) => ({
          t,
          clock: { color: `hsl(${(300 / 60) * t} 75% 50%)` },
        })),
      },
    },
  ],
};

const stripeAnimationClock: ClockSetting = {
  title: "ストライプアニメーション",
  background: "white",
  aspectRatio: 1.78,
  parts: [
    {
      type: "Label",
      common: {
        x: "-50%",
        y: "45%",
        width: "0%",
        height: "15%",
        rotate: 0,
        background: "hsl(300, 75%, 50%)",
        opacity: 0,
      },
      clock: {
        text: "",
      },
      timeline: {
        divisor: 30,
        values: [
          {
            t: 0,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 1,
            common: {
              x: "0%",
              width: "100%",
            },
          },
          {
            t: 8,
            common: {
              x: "50%",
              width: "0%",
            },
          },
          {
            t: 13,
            common: {
              opacity: 0,
            },
          },
          {
            t: 14,
            common: {
              x: "-45%",
              y: "-50%",
              width: "15%",
              height: "0%",
            },
          },
          {
            t: 15,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 16,
            common: {
              y: "0%",
              height: "100%",
            },
          },
          {
            t: 23,
            common: {
              y: "50%",
              height: "0%",
            },
          },
          {
            t: 24,
            common: {
              opacity: 0,
            },
          },
          {
            t: 25,
            common: {
              x: "-50%",
              y: "45%",
              width: "0%",
              height: "15%",
            },
          },
        ],
      },
    },
    {
      type: "Label",
      common: {
        x: "-50%",
        y: "30%",
        width: "0%",
        height: "15%",
        rotate: 0,
        background: "hsl(250, 75%, 50%)",
        opacity: 0,
      },
      clock: {
        text: "",
      },
      timeline: {
        divisor: 30,
        values: [
          {
            t: 1,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 2,
            common: {
              x: "0%",
              width: "100%",
            },
          },
          {
            t: 9,
            common: {
              x: "50%",
              width: "0%",
            },
          },
          {
            t: 14,
            common: {
              opacity: 0,
            },
          },
          {
            t: 15,
            common: {
              x: "-30%",
              y: "-50%",
              width: "15%",
              height: "0%",
            },
          },
          {
            t: 16,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 17,
            common: {
              y: "0%",
              height: "100%",
            },
          },
          {
            t: 24,
            common: {
              y: "50%",
              height: "0%",
            },
          },
          {
            t: 29,
            common: {
              opacity: 0,
            },
          },
        ],
      },
    },
    {
      type: "Label",
      common: {
        x: "-50%",
        y: "15%",
        width: "0%",
        height: "15%",
        rotate: 0,
        background: "hsl(200, 75%, 50%)",
        opacity: 0,
      },
      clock: {
        text: "",
      },
      timeline: {
        divisor: 30,
        values: [
          {
            t: 1,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 3,
            common: {
              x: "0%",
              width: "100%",
            },
          },
          {
            t: 10,
            common: {
              x: "50%",
              width: "0%",
            },
          },
          {
            t: 15,
            common: {
              opacity: 0,
            },
          },
          {
            t: 16,
            common: {
              x: "-15%",
              y: "-50%",
              width: "15%",
              height: "0%",
            },
          },
          {
            t: 17,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 18,
            common: {
              y: "0%",
              height: "100%",
            },
          },
          {
            t: 25,
            common: {
              y: "50%",
              height: "0%",
            },
          },
          {
            t: 29,
            common: {
              opacity: 0,
            },
          },
        ],
      },
    },
    {
      type: "Label",
      common: {
        x: "-50%",
        y: "0%",
        width: "0%",
        height: "15%",
        rotate: 0,
        background: "hsl(150, 75%, 50%)",
        opacity: 0,
      },
      clock: {
        text: "",
      },
      timeline: {
        divisor: 30,
        values: [
          {
            t: 1,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 4,
            common: {
              x: "0%",
              width: "100%",
            },
          },
          {
            t: 11,
            common: {
              x: "50%",
              width: "0%",
            },
          },
          {
            t: 16,
            common: {
              opacity: 0,
            },
          },
          {
            t: 17,
            common: {
              x: "0%",
              y: "-50%",
              width: "15%",
              height: "0%",
            },
          },
          {
            t: 18,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 19,
            common: {
              y: "0%",
              height: "100%",
            },
          },
          {
            t: 26,
            common: {
              y: "50%",
              height: "0%",
            },
          },
          {
            t: 29,
            common: {
              opacity: 0,
            },
          },
        ],
      },
    },
    {
      type: "Label",
      common: {
        x: "-50%",
        y: "-15%",
        width: "0%",
        height: "15%",
        rotate: 0,
        background: "hsl(100, 75%, 50%)",
        opacity: 0,
      },
      clock: {
        text: "",
      },
      timeline: {
        divisor: 30,
        values: [
          {
            t: 1,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 5,
            common: {
              x: "0%",
              width: "100%",
            },
          },
          {
            t: 12,
            common: {
              x: "50%",
              width: "0%",
            },
          },
          {
            t: 17,
            common: {
              opacity: 0,
            },
          },
          {
            t: 18,
            common: {
              x: "15%",
              y: "-50%",
              width: "15%",
              height: "0%",
            },
          },
          {
            t: 19,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 20,
            common: {
              y: "0%",
              height: "100%",
            },
          },
          {
            t: 27,
            common: {
              y: "50%",
              height: "0%",
            },
          },
          {
            t: 29,
            common: {
              opacity: 0,
            },
          },
        ],
      },
    },
    {
      type: "Label",
      common: {
        x: "-50%",
        y: "-30%",
        width: "0%",
        height: "15%",
        rotate: 0,
        background: "hsl(50, 75%, 50%)",
        opacity: 0,
      },
      clock: {
        text: "",
      },
      timeline: {
        divisor: 30,
        values: [
          {
            t: 1,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 6,
            common: {
              x: "0%",
              width: "100%",
            },
          },
          {
            t: 13,
            common: {
              x: "50%",
              width: "0%",
            },
          },
          {
            t: 18,
            common: {
              opacity: 0,
            },
          },
          {
            t: 19,
            common: {
              x: "30%",
              y: "-50%",
              width: "15%",
              height: "0%",
            },
          },
          {
            t: 20,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 21,
            common: {
              y: "0%",
              height: "100%",
            },
          },
          {
            t: 28,
            common: {
              y: "50%",
              height: "0%",
            },
          },
          {
            t: 29,
            common: {
              opacity: 0,
            },
          },
        ],
      },
    },
    {
      type: "Label",
      common: {
        x: "45%",
        y: "50%",
        width: "15%",
        height: "0%",
        rotate: 0,
        background: "hsl(0, 75%, 50%)",
        opacity: 0,
      },
      clock: {
        text: "",
      },
      timeline: {
        divisor: 30,
        values: [
          {
            t: 1,
            common: {
              opacity: 0,
            },
          },
          {
            t: 2,
            common: {
              x: "-50%",
              y: "-45%",
              width: "0%",
              height: "15%",
            },
          },
          {
            t: 6,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 7,
            common: {
              x: "0%",
              width: "100%",
            },
          },
          {
            t: 14,
            common: {
              x: "50%",
              width: "0%",
            },
          },
          {
            t: 19,
            common: {
              opacity: 0,
            },
          },
          {
            t: 20,
            common: {
              x: "45%",
              y: "-50%",
              width: "15%",
              height: "0%",
            },
          },
          {
            t: 21,
            common: {
              opacity: 0.7,
            },
          },
          {
            t: 22,
            common: {
              y: "0%",
              height: "100%",
            },
          },
          {
            t: 29,
            common: {
              y: "50%",
              height: "0%",
            },
          },
        ],
      },
    },
    {
      type: "TextClock",
      common: {
        x: "0%",
        y: "16%",
        width: "100%",
        height: "15%",
        rotate: 0,
      },
      clock: {
        type: "HourMinute",
        format: " ",
        text: "{CLOCK}",
        delay: 300,
        color: "#000",
        fontSize: 0.15,
        fontWeight: "bold",
        whiteSpace: "pre-wrap",
        fontFamily: "Helvetica, sans-serif",
      },
    },
  ],
};

const coordinateSystem: ClockSetting = {
  title: "clock-fの領域の説明",
  background: "#fff",
  aspectRatio: 1.78,
  parts: [
    {
      common: {
        x: "0%",
        y: "0%",
        width: "100w",
        height: "0.5h",
        rotate: 0,
        opacity: 1,
        background: "#666",
      },
      clock: {
        text: "",
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
    {
      common: {
        x: "0%",
        y: "0%",
        width: "0.5h",
        height: "100h",
        rotate: 0,
        opacity: 1,
        background: "#666",
      },
      clock: {
        text: "",
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
    {
      common: {
        x: "0%",
        y: "0%",
        width: "1w",
        height: "1w",
        rotate: 0,
        opacity: 1,
        background: "#333",
        borderRadius: "0.5w",
      },
      clock: {
        text: "",
        fontSize: 0.05,
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
    {
      common: {
        x: "2w",
        y: "8h",
        width: "100%",
        height: "100%",
        rotate: 0,
        opacity: 1,
      },
      clock: {
        text: "↖︎\n             (0, 0)",
        fontSize: 0.05,
        color: "#4f566b",
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
    {
      common: {
        x: "-48w",
        y: "10h",
        width: "100%",
        height: "100%",
        rotate: 0,
        opacity: 1,
      },
      clock: {
        text: "↖︎\n                   (-50w, 0)︎\n                   (-50%, 0)",
        fontSize: 0.05,
        color: "#4f566b",
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
    {
      common: {
        x: "42w",
        y: "10h",
        width: "100%",
        height: "100%",
        rotate: 0,
        opacity: 1,
      },
      clock: {
        text: "                ↗︎\n(50w, 0)︎\n(50%, 0)",
        fontSize: 0.05,
        color: "#4f566b",
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
    {
      common: {
        x: "-8w",
        y: "-40h",
        width: "100%",
        height: "100%",
        rotate: 0,
        opacity: 1,
      },
      clock: {
        text: "                ↗︎\n(0, -50h)︎\n(0, -50%)",
        fontSize: 0.05,
        color: "#4f566b",
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
    {
      common: {
        x: "-8w",
        y: "40h",
        width: "100%",
        height: "100%",
        rotate: 0,
        opacity: 1,
      },
      clock: {
        text: "(0, 50h)︎\n(0, 50%)\n                ↘︎",
        fontSize: 0.05,
        color: "#4f566b",
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
    {
      common: {
        x: "0%",
        y: "-20h",
        width: "100%",
        height: "100%",
        rotate: 0,
        opacity: 1,
      },
      clock: {
        text: "←─── 100w, 100% (x, width) ───────────────────→",
        fontSize: 0.05,
        color: "#e56f4a",
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
    {
      common: {
        x: "30%",
        y: "0%",
        width: "100%",
        height: "100%",
        rotate: 0,
        opacity: 1,
      },
      clock: {
        text: "↑\n│\n│\n│\n│\n100h, 100% (y, height) \n│\n│\n│\n│\n│\n│\n│\n↓",
        fontSize: 0.05,
        color: "#e56f4a",
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
    {
      common: {
        x: "0%",
        y: "0%",
        width: "100%",
        height: "100%",
        rotate: 0,
        opacity: 1,
        borderWidth: "0.5%",
        borderColor: "#000",
      },
      clock: {
        text: "",
      },
      timeline: {
        values: [],
      },
      type: "Label",
    },
  ],
};

export const emptyClockSetting: ClockSetting = {
  title: "New Clock",
  background: "#000",
  aspectRatio: 1.78,
  parts: [],
};

export const defaultClockSetting = classicClock;
export const defaultDemoClockSetting = woodClock;

const generateSampleClock = (
  setting: ClockSetting,
  title: string
): ClockSetting => {
  return { ...setting, title };
};
export const sampleClocks = [
  generateSampleClock(woodClock, "画像の利用（木目調デザイン）"),
  generateSampleClock(rainbowClock, "色の変化"),
  generateSampleClock(stripeAnimationClock, "ストライプアニメーション"),
  generateSampleClock(classicClock, "クラシック clock-f"),
  generateSampleClock(coordinateSystem, "clock-fの領域の説明"),
];
