import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as T from "components/atoms/Table";
import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";

import { factorDotClockSettingCC } from "./setting";

type FactorDotClockPartsDocumentProps = {
  className?: string;
  id?: string;
};

const FactorDotClockPartsDocument: React.FC<FactorDotClockPartsDocumentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Factor Dot Clock
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>素因数分解ドット時計の設定です。</S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-type`}>
                    <S.ListItemLabel>type</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#FactorDotClockPartsType">
                        FactorDotClockPartsType
                      </Link>
                    </S.ListItemValidation>
                    <S.Required />
                  </AnchorContainer>
                  <S.ListItemDescription>
                    素因数分解ドット時計のタイプ
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-color`}>
                    <S.ListItemLabel>color</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>ドットの色</S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;#fff&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-duration`}>
                    <S.ListItemLabel>duration</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ドット時計のアニメーション時間。単位はミリ秒。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>300</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-delay`}>
                    <S.ListItemLabel>delay</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ドット時計のアニメーション開始遅延時間。単位はミリ秒。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>0</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-dotScale`}>
                    <S.ListItemLabel>dotScale</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ドットのサイズ。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    1の場合、ドット同志が
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>0.8</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-coordinator:type`}>
                    <S.ListItemLabel>coordinator:type</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#FactorCoordinatorType">
                        FactorCoordinatorType
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ドットの配置タイプ
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト{" "}
                    <S.Code>&quot;ClassicFactorCoordinator&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-coordinator:sort`}>
                    <S.ListItemLabel>coordinator:sort</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#FactorSortOrderType">
                        FactorSortOrderType
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    因数のソートタイプ
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト{" "}
                    <S.Code>&quot;ClassicSimpleFactorSorter&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-coordinator:merge`}>
                    <S.ListItemLabel>coordinator:merge</S.ListItemLabel>
                    <S.ListItemValidation>number[]</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    因数のマージ設定
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    指定された数値を構成する因数を含む場合、それらの値はマージされます。例えば、
                    <S.Code>[4,6]</S.Code>を指定した場合、24は
                    <S.Code>2×2×2×3</S.Code>ではなく<S.Code>4×6</S.Code>
                    にマージされ、配置されます。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    また、デフォルト値は<S.Code>[4]</S.Code>
                    ですが、この指定をなくしてしまうと、4を因数に含む場合に棒状の配置になってしまうので、4を含めることをお勧めします。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>[4]</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>{" "}
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <DocumentClock partsSetting={factorDotClockSettingCC} />
            </S.Contents>
            <S.Contents>
              <T.Container id="FactorDotClockPartsType">
                <T.Title>FactorDotClockPartsType</T.Title>
                <T.Table>
                  <T.Row>
                    <T.Header>Year</T.Header>
                    <T.Data>年（下２桁）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Month</T.Header>
                    <T.Data>月</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Day</T.Header>
                    <T.Data>日</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Hour</T.Header>
                    <T.Data>時</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Minute</T.Header>
                    <T.Data>分</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Second</T.Header>
                    <T.Data>秒</T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
            <S.Contents>
              <T.Container id="FactorCoordinatorType">
                <T.Title>FactorCoordinatorType</T.Title>
                <T.Table headerWidth={240}>
                  <T.Row>
                    <T.Header>
                      ClassicFactor
                      <wbr />
                      Coordinator
                    </T.Header>
                    <T.Data>
                      clock-f v1と同様の配置タイプ。
                      因数に応じた配置の調整がされます
                    </T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>
                      SimpleFactor
                      <wbr />
                      Coordinator
                    </T.Header>
                    <T.Data>
                      因数リストを元に円を分割していくだけのシンプルな配置タイプ
                    </T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
            <S.Contents>
              <T.Container id="FactorSortOrderType">
                <T.Title>FactorSortOrderType</T.Title>
                <T.Table headerWidth={240}>
                  <T.Row>
                    <T.Header>
                      Classic
                      <wbr />
                      SimpleFactor
                      <wbr />
                      Sorter
                    </T.Header>
                    <T.Data>
                      clock-f v1と同様のソートタイプ。
                      基本は降順ソートですが、因数に2か4が含まれる場合ソート順が調整されます。
                    </T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>
                      Descending
                      <wbr />
                      SimpleFactor
                      <wbr />
                      Sorter
                    </T.Header>
                    <T.Data>因数のリストを降順にソートします</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>
                      Ascending
                      <wbr />
                      SimpleFactor
                      <wbr />
                      Sorter
                    </T.Header>
                    <T.Data>因数のリストを昇順にソートします</T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
            <S.Contents>
              <S.ItemListHeader>
                type: ClassicFactorCoordinator
              </S.ItemListHeader>
              <DocumentClock
                setting={{
                  title: "",
                  aspectRatio: null,
                  background: "#333",
                  parts: [
                    {
                      type: "FactorDotClock",
                      common: {
                        x: "-33%",
                        y: "0%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        type: "Second",
                        "coordinator:type": "ClassicFactorCoordinator",
                        "coordinator:sort": "ClassicSimpleFactorSorter",
                        "coordinator:merge": [4],
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "-33%",
                        y: "40%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        text: "Classic",
                        fontSize: 0.076,
                      },
                    },
                    {
                      type: "FactorDotClock",
                      common: {
                        x: "0%",
                        y: "0%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        type: "Second",
                        "coordinator:type": "ClassicFactorCoordinator",
                        "coordinator:sort": "DescendingSimpleFactorSorter",
                        "coordinator:merge": [4],
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "0%",
                        y: "40%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        text: "Descending",
                        fontSize: 0.076,
                      },
                    },
                    {
                      type: "FactorDotClock",
                      common: {
                        x: "33%",
                        y: "0%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        type: "Second",
                        "coordinator:type": "ClassicFactorCoordinator",
                        "coordinator:sort": "AscendingSimpleFactorSorter",
                        "coordinator:merge": [4],
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "33%",
                        y: "40%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        text: "Ascending",
                        fontSize: 0.076,
                      },
                    },
                  ],
                }}
              />
            </S.Contents>
            <S.Contents>
              <S.ItemListHeader>type: SimpleFactorCoordinator</S.ItemListHeader>
              <DocumentClock
                setting={{
                  title: "",
                  aspectRatio: null,
                  background: "#333",
                  parts: [
                    {
                      type: "FactorDotClock",
                      common: {
                        x: "-33%",
                        y: "0%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        type: "Second",
                        "coordinator:type": "SimpleFactorCoordinator",
                        "coordinator:sort": "ClassicSimpleFactorSorter",
                        "coordinator:merge": [4],
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "-33%",
                        y: "40%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        text: "Classic",
                        fontSize: 0.076,
                      },
                    },
                    {
                      type: "FactorDotClock",
                      common: {
                        x: "0%",
                        y: "0%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        type: "Second",
                        "coordinator:type": "SimpleFactorCoordinator",
                        "coordinator:sort": "DescendingSimpleFactorSorter",
                        "coordinator:merge": [4],
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "0%",
                        y: "40%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        text: "Descending",
                        fontSize: 0.076,
                      },
                    },
                    {
                      type: "FactorDotClock",
                      common: {
                        x: "33%",
                        y: "0%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        type: "Second",
                        "coordinator:type": "SimpleFactorCoordinator",
                        "coordinator:sort": "AscendingSimpleFactorSorter",
                        "coordinator:merge": [4],
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "33%",
                        y: "40%",
                        width: "33%",
                        height: "100%",
                        rotate: 0,
                      },
                      clock: {
                        text: "Ascending",
                        fontSize: 0.076,
                      },
                    },
                  ],
                }}
              />
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(FactorDotClockPartsDocument);
