class Radian {
  private _value: number;

  get value(): number {
    return this._value;
  }

  constructor(value: number) {
    this._value = value;
  }

  public add = (other: Radian): Radian => {
    return new Radian(this.value + other.value);
  };

  public sub = (other: Radian): Radian => {
    return new Radian(this.value - other.value);
  };

  public mul = (num: number): Radian => {
    return new Radian(this.value * num);
  };

  public div = (num: number): Radian => {
    return new Radian(this.value / num);
  };

  public static get M_PI(): Radian {
    return new Radian(Math.PI);
  }
  public static get M_2PI(): Radian {
    return new Radian(Math.PI * 2);
  }
  public static get M_PI_2(): Radian {
    return new Radian(Math.PI / 2);
  }
  public static get M_PI_4(): Radian {
    return new Radian(Math.PI / 4);
  }
  public static get ZERO(): Radian {
    return new Radian(0);
  }
}

export default Radian;
