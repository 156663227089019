import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";

type CountPartsDocumentProps = {
  className?: string;
  id?: string;
};

const TextPartsCommonSettingDocument: React.FC<CountPartsDocumentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Text Parts Common Setting
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>テキストパーツ共通の設定です。</S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-color`}>
                    <S.ListItemLabel>color</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>文字色</S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;#fff&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-fontSize`}>
                    <S.ListItemLabel>fontSize</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>文字のサイズ</S.ListItemDescription>
                  <S.ListItemDescription>
                    高さを１とした場合のサイズ
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>0.2</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-fontWeight`}>
                    <S.ListItemLabel>fontWeight</S.ListItemLabel>
                    <S.ListItemValidation>FontWeight</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>文字の太さ</S.ListItemDescription>
                  <S.ListItemDescription>
                    高さを１とした場合のサイズ。 設定可能な値は、
                    <S.Code>&quot;normal&quot;</S.Code>, <wbr />
                    <S.Code>&quot;bold&quot;</S.Code>, <wbr />
                    <S.Code>100</S.Code>, <wbr />
                    <S.Code>200</S.Code>, <wbr />
                    <S.Code>300</S.Code>, <wbr />
                    <S.Code>400</S.Code>, <wbr />
                    <S.Code>500</S.Code>, <wbr />
                    <S.Code>600</S.Code>, <wbr />
                    <S.Code>700</S.Code>, <wbr />
                    <S.Code>800</S.Code>, <wbr />
                    <S.Code>900</S.Code>
                    <wbr />
                    です。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;normal&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-fontFamily`}>
                    <S.ListItemLabel>fontFamily</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>フォント</S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-whiteSpace`}>
                    <S.ListItemLabel>whiteSpace</S.ListItemLabel>
                    <S.ListItemValidation>
                      <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/white-space">
                        css.white-space
                      </S.A>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    テキストのスペース、改行の取り扱いの設定。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;pre-wrap&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub></Section.ContentSub>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(TextPartsCommonSettingDocument);
