import { JSONSchema7 } from "json-schema";

import { ClockPartsType } from "clock/type";

import { AnalogClockPartsDefinitions } from "./AnalogClock";
import { CountPartsDefinitions } from "./Count";
import { FactorDotClockPartsDefinitions } from "./FactorDotClock";
import { GaugePartsDefinitions } from "./Gauge";
import { ImagePartsDefinitions } from "./Image";
import { LabelPartsDefinitions } from "./Label";
import { SimpleDotClockPartsDefinitions } from "./SimpleDotClock";
import { TextClockPartsDefinitions } from "./TextClock";

// 時計パーツを追加したらここに設定を追加する
export const clocks: {
  [key in ClockPartsType]: {
    title: string;
    definitions: JSONSchema7["definitions"];
  };
} = {
  FactorDotClock: {
    title: "素因数分解ドット時計",
    definitions: FactorDotClockPartsDefinitions,
  },
  SimpleDotClock: {
    title: "シンプルドット時計",
    definitions: SimpleDotClockPartsDefinitions,
  },
  TextClock: {
    title: "テキスト時計",
    definitions: TextClockPartsDefinitions,
  },
  AnalogClock: {
    title: "アナログ時計",
    definitions: AnalogClockPartsDefinitions,
  },
  Count: {
    title: "カウント",
    definitions: CountPartsDefinitions,
  },
  Label: {
    title: "ラベル",
    definitions: LabelPartsDefinitions,
  },
  Gauge: {
    title: "ゲージ",
    definitions: GaugePartsDefinitions,
  },
  Image: {
    title: "イメージ",
    definitions: ImagePartsDefinitions,
  },
};
