import React from "react";

import { ClockPartsCommonSetting } from "clock";
import { TimelineOptions } from "clock/type";
import { useClockArea } from "hooks";
import { generateTransitionStyle } from "lib/style";
import { toPx } from "lib/utils/size";

type ClockPartsWrapperProps = {
  setting: ClockPartsCommonSetting;
  timelineOptions: TimelineOptions;
};

const ClockPartsWrapper: React.FC<ClockPartsWrapperProps> = ({
  setting: {
    x = "0%",
    y = "0%",
    width = "100%",
    height = "100%",
    rotate = 0,
    opacity = 1,
    background,
    borderWidth,
    borderStyle,
    borderColor,
    borderRadius = "0%",
    filter,
    blend,
  },
  timelineOptions,
  children,
}) => {
  const areaSize = useClockArea();

  const cssVariables = {
    "--transition-duration": `${timelineOptions.duration}ms`,
    "--transition-timing-function": timelineOptions.timingFunction,
    "--transition-delay": `${timelineOptions.delay}ms`,
  } as React.CSSProperties;

  const commonStyle: React.CSSProperties = {
    ...cssVariables,
    position: "absolute" as const,
    left: `calc(50% + ${toPx(x, areaSize, "x")})`,
    top: `calc(50% + ${toPx(y, areaSize, "y")})`,
    width: `${toPx(width, areaSize, "x")}`,
    height: `${toPx(height, areaSize, "y")}`,
    transform: `translate3d(-50%, -50%, 0) rotate(${rotate}deg)`,
    background: background,
    borderWidth: `${toPx(borderWidth, areaSize, "y")}`,
    borderStyle: borderStyle,
    borderColor: borderColor,
    borderRadius: `${toPx(borderRadius, areaSize, "xy")}`,
    filter,
    mixBlendMode: blend,
    opacity,
    transition: generateTransitionStyle([
      "left",
      "top",
      "width",
      "height",
      "transform",
      "opacity",
      "background",
      "border-width",
      "border-style",
      "border-color",
      "border-radius",
    ]),
  };

  return <div style={commonStyle}>{children}</div>;
};

export default ClockPartsWrapper;
