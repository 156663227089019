import { TextClockPartsSetting } from "./type";

export const defaultTextClockPartsSetting: TextClockPartsSetting = {
  type: "TextClock",
  common: {
    x: "0%",
    y: "0%",
    width: "100%",
    height: "100%",
    rotate: 0,
  },
  clock: {
    type: "HourMinuteSecond",
    format: " ",
    text: "{CLOCK}",
    delay: 300,
    color: "#fff",
    fontSize: 0.2,
    fontWeight: "normal",
    whiteSpace: "pre-wrap",
  },
};
