import { paramCase } from "change-case";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import { backgroundStyle } from "lib/style";

type FullScreenProps = {
  className?: string;
  background?: string;
};

const FullScreen: React.FC<FullScreenProps> = ({
  className,
  background,
  children,
}) => {
  return (
    <>
      {background && (
        <Helmet>
          <style>{backgroundStyleString(background)}</style>
        </Helmet>
      )}
      <Wrapper className={className}>{children}</Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const backgroundStyleString = (background?: string): string => {
  const styles = backgroundStyle(background);
  const s = [
    "html {",
    "  height: 100%;",
    "  overflow: hidden;",
    ...Object.entries(styles).map(
      ([key, value]) => `  ${paramCase(key)}: ${value};`
    ),
    "}",
  ];
  return s.join("\n");
};

export default FullScreen;
