/* eslint-disable @typescript-eslint/no-explicit-any */
import Ajv from "ajv";
import ace from "brace";
import { JsonEditor as Editor } from "jsoneditor-react";
import React, { useCallback } from "react";
import styled from "styled-components";

import "brace/mode/json";
import "brace/theme/github";

type JsonEditorProps<T> = {
  className?: string;
  setting: T;
  schema?: { [key: string]: any };
  onUpdateSetting?: (setting: T) => void;
};

type JsonType = {
  [key: string]: any;
};

const ajv = new Ajv({ allErrors: true, verbose: true });

const JsonEditor: <T extends JsonType>(
  props: JsonEditorProps<T>
) => React.ReactElement<JsonEditorProps<T>> = <T extends JsonType>({
  className,
  setting,
  schema,
  onUpdateSetting,
}: JsonEditorProps<T>) => {
  const onChange = useCallback(
    (json: JsonType) => {
      if (schema && onUpdateSetting) {
        if (ajv.validate(schema, json)) {
          onUpdateSetting(json as T);
        }
      }
    },
    [schema, onUpdateSetting]
  );
  return (
    <Wrapper className={className}>
      <Editor
        value={setting}
        schema={schema}
        onChange={onChange}
        ajv={ajv}
        ace={ace}
        theme="ace/theme/github"
        mode="code"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  > * {
    height: 100%;
  }

  .jsoneditor {
    border: 1px solid #666;
  }
  .jsoneditor-menu {
    background-color: #666;
    border-bottom-color: #666;
  }
`;

export default JsonEditor;
