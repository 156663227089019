import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState, createContext, useContext } from "react";

/**
 * アプリケーションの時間をつかさどる処理
 */

/**
 * アプリケーション内における時間を保持するコンテキスト
 */
const FixedTickContext = createContext<{
  fixedTime: Dayjs | null;
  setFixedTime?: (fixedTime: Dayjs | null) => void;
}>({ fixedTime: null });

/**
 * １秒毎にTickContextの値が自動更新されるProvider
 */
export const FixedTickContextProvider: React.FC = ({ children }) => {
  const [fixedTime, setFixedTime] = useState<Dayjs | null>(null);

  return (
    <FixedTickContext.Provider value={{ fixedTime, setFixedTime }}>
      {children}
    </FixedTickContext.Provider>
  );
};

/**
 * アプリケーション内における時間を利用するhooks
 */
export const useFixedTick: () => {
  fixedTime: Dayjs | null;
  setFixedTime?: (fixedTime: Dayjs | null) => void;
} = () => {
  return useContext(FixedTickContext);
};

/**
 * アプリケーション内における時間を保持するコンテキスト
 */
const TickContext = createContext<Dayjs>(dayjs());

/**
 * １秒毎にTickContextの値が自動更新されるProvider
 */
export const TickContextProvider: React.FC = ({ children }) => {
  const [time, setTime] = useState<Dayjs>(dayjs());
  const tick = () => {
    setTimeout(() => {
      setTime(dayjs().millisecond(0));
      tick();
    }, 1000 - dayjs().millisecond() + 1);
  };
  useEffect(tick, []);

  return <TickContext.Provider value={time}>{children}</TickContext.Provider>;
};

/**
 * アプリケーション内における時間を利用するhooks
 */
export const useTick = (): Dayjs => {
  const { fixedTime } = useContext(FixedTickContext);
  const time = useContext(TickContext);
  return fixedTime || time;
};
