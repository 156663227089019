import styled, { css } from "styled-components";

const fontCss = css`
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    Ubuntu, sans-serif;
`;

export const P = styled.p`
  ${fontCss}
  color: #4f566b;
  line-height: 22px;
  margin-top: 20px;
`;

export const Header = styled.h1`
  ${fontCss}
  font-size: 24px;
  font-weight: 500;
`;

export const ItemList = styled.div`
  margin-top: 32px;
  color: #4f566b;
`;
export const SubsequentItemList = styled.div`
  margin-top: -11px;
  color: #4f566b;
`;
export const Contents = styled.div`
  margin-top: 32px;
  color: #4f566b;
`;
export const ItemListHeader = styled.h3`
  ${fontCss}
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 0;

  padding-bottom: 12px;
`;

export const ItemListSubHeader = styled.h4`
  ${fontCss}
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 0;

  padding-bottom: 12px;
`;

export const List = styled.ul`
  font-weight: 400;
`;

export const ListItem = styled.li`
  padding: 16px 0 15px;
  border-bottom: 1px solid rgb(227, 232, 238);

  &:nth-of-type(1) {
    border-top: 1px solid rgb(227, 232, 238);
  }

  ${SubsequentItemList} &:nth-of-type(1) {
    border-top: none;
  }
`;

export const ListItemLabel = styled.span`
  font-family: Menlo, Consolas, monospace;
  color: rgb(60, 66, 87);
  font-weight: 600;
  font-size: 13px;
  margin-right: 4px;
`;

export const ListItemValidation = styled.span`
  color: rgb(135, 146, 162);
  font-weight: 600;
  font-size: 12px;
  margin-right: 4px;
`;

export const ListItemDescription = styled(P)`
  margin-top: 4px;
`;

export const Code = styled.code``;

export const Required = styled.span`
  margin-right: 4px;
  ::before {
    content: "required";
    color: rgb(229, 111, 74);
  }
`;

export const A = styled.a.attrs(() => ({
  target: "_blank",
  rel: "noreferrer",
}))``;
