import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import IconButton from "components/molecules/IconButton";

type CopyButtonProps = {
  className?: string;
  value: string;
} & Pick<
  React.ComponentProps<typeof IconButton>,
  "label" | "clickedLabel" | "color" | "clickedColor" | "onClick"
>;

const CopyButton: React.FC<CopyButtonProps> = ({
  className,
  value,
  label = "Copy to Clipboard",
  clickedLabel = "Copied!",
  color,
  clickedColor = "#1bc1a1",
  onClick,
}) => {
  return (
    <CopyToClipboard text={value}>
      <IconButton
        className={className}
        icon="clipboard"
        clickedIcon="clipboard-copied"
        label={label}
        clickedLabel={clickedLabel}
        color={color}
        clickedColor={clickedColor}
        onClick={onClick}
      />
    </CopyToClipboard>
  );
};

export default React.memo(CopyButton);
