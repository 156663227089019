import React from "react";
import styled from "styled-components";

import { AnalogClockPartsDocument } from "clock/AnalogClock";
import { CountPartsDocument } from "clock/Count";
import { FactorDotClockPartsDocument } from "clock/FactorDotClock";
import { GaugePartsDocument } from "clock/Gauge";
import { ImagePartsDocument } from "clock/Image";
import { LabelPartsDocument } from "clock/Label";
import { SimpleDotClockPartsDocument } from "clock/SimpleDotClock";
import { TextClockPartsDocument } from "clock/TextClock";
import ClockPartsCommonSettingDocument from "clock/common/ClockPartsCommonSettingDocument";
import ClockPartsSettingDocument from "clock/common/ClockPartsSettingDocument";
import ClockPartsTimelineSettingDocument from "clock/common/ClockPartsTimelineSettingDocument";
import ClockSettingDocument from "clock/common/ClockSettingDocument";
import TextPartsCommonSettingDocument from "clock/common/TextPartsCommonSettingDocument";

type SettingDocumentContentProps = {
  className?: string;
};

const SettingDocumentContent: React.FC<SettingDocumentContentProps> = ({
  className,
}) => {
  return (
    <Wrapper className={className}>
      <ClockSettingDocument id="ClockSetting" />
      <ClockPartsSettingDocument id="ClockPartsSetting" />
      <ClockPartsCommonSettingDocument id="ClockPartsCommonSetting" />
      <ClockPartsTimelineSettingDocument id="ClockPartsTimelineSetting" />
      <div id="ClockPartsIndivisualSetting" />
      <FactorDotClockPartsDocument id="FactorDotClock" />
      <SimpleDotClockPartsDocument id="SimpleDotClock" />
      <TextClockPartsDocument id="TextClock" />
      <AnalogClockPartsDocument id="AnalogClock" />
      <CountPartsDocument id="Count" />
      <GaugePartsDocument id="Gauge" />
      <LabelPartsDocument id="Label" />
      <ImagePartsDocument id="Image" />
      <TextPartsCommonSettingDocument id="TextPartsCommonSetting" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px 24px;
`;

export default React.memo(SettingDocumentContent);
