import React, { useCallback, useState } from "react";
import styled from "styled-components";

import SVGIcon, { IconType, SVGIconProps } from "components/atoms/SVGIcon";

type IconButtonProps = {
  className?: string;
  icon: IconType;
  clickedIcon?: IconType;
  label?: string;
  clickedLabel?: string;
  color?: string;
  clickedColor?: string;
  onClick?: React.ComponentProps<"div">["onClick"];
};

const IconButton: React.FC<IconButtonProps> = ({
  className,
  icon,
  clickedIcon,
  label = "Label",
  clickedLabel,
  color = "#666",
  clickedColor,
  onClick,
}) => {
  clickedColor = clickedColor || color;
  const Icon = useCallback(
    (props: Omit<SVGIconProps, "type">) => <SVGIcon {...props} type={icon} />,
    [icon]
  );
  const ClickedIcon = useCallback(
    (props: Omit<SVGIconProps, "type">) => (
      <SVGIcon {...props} type={clickedIcon || icon} />
    ),
    [clickedIcon, icon]
  );

  const [clicked, setClicked] = useState<boolean>(false);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onClick) {
        onClick(event);
      }
      setClicked(true);
    },
    [onClick]
  );

  return (
    <Wrapper
      className={className}
      onClick={handleClick}
      clicked={clicked}
      color={color}
      clickedColor={clickedColor}
    >
      <IconContainer>{clicked ? <ClickedIcon /> : <Icon />}</IconContainer>
      <Label>{clicked && clickedLabel ? clickedLabel : label}</Label>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  clicked: boolean;
  color: string;
  clickedColor: string;
}>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  font-size: 12px;
  font-weight: normal;

  color: ${(props) => (props.clicked ? props.clickedColor : props.color)};
  svg {
    fill: ${(props) => (props.clicked ? props.clickedColor : props.color)};
  }
`;

const IconContainer = styled.figure`
  width: 18px;
  height: 18px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Label = styled.span`
  display: inline-block;
  margin-left: 4px;
`;

export default React.memo(IconButton);

export const AddButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "add" }))``
);

export const ViewDocumentButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "view-document" }))``
);

export const ClipboardButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "clipboard" }))``
);

export const DownloadButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "down-arrow" }))``
);

export const SelectClockButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "squares" }))``
);

export const EditClockButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "pen" }))``
);

export const ClockButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "clock" }))``
);

export const DeleteButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "trash" }))``
);

export const CloseButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "close" }))``
);

export const SaveButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "floppy" }))``
);

export const CopyIconButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "clipboard" }))``
);

export const QRCodeButton = React.memo(
  styled(IconButton).attrs(() => ({ icon: "qr-code" }))``
);
