import styled, { css } from "styled-components";

const defaultStyle = css`
  min-width: 100px;
  outline: none;
`;

export const Button = styled.button.attrs(({}) => ({
  className: "btn btn-default",
}))`
  ${defaultStyle}
`;

export const PrimaryButton = styled.button.attrs(({}) => ({
  className: "btn btn-primary",
}))`
  ${defaultStyle}
`;

export const SecondayButton = styled.button.attrs(({}) => ({
  className: "btn btn-seconday",
}))`
  ${defaultStyle}
`;

export const DangerButton = styled.button.attrs(({}) => ({
  className: "btn btn-danger",
}))`
  ${defaultStyle}
`;
