import React from "react";
import styled from "styled-components";

import { Centered } from "components/layout";
import { useClockArea } from "hooks";
import { generateTransitionStyle } from "lib/style";
import { FontWeight } from "types";

interface SwitchingTextPartsProps {
  text: string;
  prevText: string;
  color?: string;
  fontSize?: number;
  fontWeight?: FontWeight;
  fontFamily?: React.CSSProperties["fontFamily"];
  fontSizeAdjust?: React.CSSProperties["fontSizeAdjust"];
  fontStretch?: React.CSSProperties["fontStretch"];
  fontVariant?: React.CSSProperties["fontVariant"];
  whiteSpace?: React.CSSProperties["whiteSpace"];
  delay?: number;
  isEven: boolean;
}

const SwitchingTextParts: React.FC<SwitchingTextPartsProps> = ({
  text,
  prevText,
  color,
  fontSize = 0.2,
  fontWeight,
  fontFamily,
  fontSizeAdjust,
  fontStretch,
  fontVariant,
  whiteSpace,
  delay = 300,
  isEven,
}) => {
  const { height: baseSize } = useClockArea();

  // 変化の設定
  const transitionStyle = generateTransitionStyle(
    ["color", "font-size", "font-weight", "font-size-adjust", "font-stretch"],
    [["opacity", 0, "ease-in-out", delay * 0.8]]
  );

  // 表示タイミングを微調整するために、２つのテキストを用意して、
  // transitionDelayで表示タイミングをずらして、表示を切り替えている
  const style: React.CSSProperties = {
    fontSize: `${fontSize * baseSize}px`,
    fontWeight,
    fontFamily,
    fontSizeAdjust,
    fontStretch,
    fontVariant,
    whiteSpace,
    color,
    transition: transitionStyle,
  };

  const currentTextStyle = {
    opacity: 1,
    // transitionの設定は↑でまとめてる
    // transitionDelay: `${delay * 0.8}ms`,
  };
  const prevTextStyle = {
    opacity: 0,
    // transitionDelay: `${delay * 0.8}ms`,
  };

  return (
    <Wrapper>
      <Label
        style={{ ...style, ...(isEven ? prevTextStyle : currentTextStyle) }}
      >
        {isEven ? prevText : text}
      </Label>
      <Label
        style={{ ...style, ...(isEven ? currentTextStyle : prevTextStyle) }}
      >
        {isEven ? text : prevText}
      </Label>
    </Wrapper>
  );
};

const Wrapper = styled(Centered)``;

const Label = styled.div`
  position: absolute;
  // transitionの設定は↑でまとめてる
  /* transition-property: "opacity";
  transition-timing-function: "ease-in-out"; */
  text-align: center;
  white-space: pre;
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック",
    YuGothic, "メイリオ", Meiryo, sans-serif;
`;

export default SwitchingTextParts;
