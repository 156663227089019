/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const canUseLocalStorage = () => {
  try {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("feature_test", "yes");
      if (localStorage.getItem("feature_test") === "yes") {
        localStorage.removeItem("feature_test");
        // localStorage is enabled
        return true;
      } else {
        // localStorage is disabled
        return false;
      }
    } else {
      // localStorage is not available
      return false;
    }
  } catch (e) {
    // localStorage is disabled
    return false;
  }
};

export const set = <T>(key: string, object: T): void => {
  if (canUseLocalStorage()) {
    localStorage.setItem(key, JSON.stringify(object));
  }
};

export const get = <T>(key: string): T | null => {
  if (canUseLocalStorage()) {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item);
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const del = (key: string) => {
  if (canUseLocalStorage()) {
    localStorage.removeItem(key);
  }
};

export const clear = () => {
  if (canUseLocalStorage()) {
    localStorage.clear();
  }
};
