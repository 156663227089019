import Factors from "models/coordinator/factorization/Factors";

import { Factorizer } from ".";

/**
 * SimpleFactorizer
 *
 * 基本的に総当たりで素因数分解を行う素因数分解機
 */
class SimpleFactorizer implements Factorizer {
  public factorize = (natural: number): Factors => {
    const factors = [];
    if (natural === 1) {
      return new Factors([1]);
    }

    let quotient: number = natural;
    outer: for (let i = 2; i <= Math.sqrt(natural); i += 1) {
      while (quotient % i === 0) {
        factors.push(i);
        quotient /= i;

        if (quotient === 1) {
          break outer;
        }
      }
    }

    return new Factors(factors);
  };
}

export default SimpleFactorizer;
