import QRCode from "qrcode.react";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { ClockSetting } from "clock";
import { Button, DangerButton } from "components/atoms/Button";
import { Centered } from "components/layout";
import Section from "components/layout/Section";
import CopyButton from "components/molecules/CopyButton";
import { createClockURL } from "lib/url";

type InfoPanelProps = {
  setting: ClockSetting;
  onClickResetSetting: () => void;
};

const InfoPanel: React.FC<InfoPanelProps> = ({
  setting,
  onClickResetSetting,
}) => {
  const permanentURL = useMemo(() => createClockURL(setting), [setting]);

  const [shortURL, setShortURL] = useState<string | null>(null);
  const generateShortURL = useCallback(() => {
    fetch("https://api-ssl.bitly.com/v4/shorten", {
      method: "POST",
      headers: {
        Authorization: " Bearer 76e8ce44582b8465bc94dbb58c268f53418e370c",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ long_url: permanentURL }),
    })
      .then((response) => response.json())
      .then((data) => setShortURL(data.link));
  }, [permanentURL]);
  return (
    <Wrapper>
      <Section>
        <Section.Header>
          時計URL
          <CopyURLButton label="URLをコピーする" value={permanentURL} />
        </Section.Header>
        <Section.Content>
          <Link href={permanentURL}>{permanentURL}</Link>
        </Section.Content>
        <Section.Content>
          <Centered>
            <QRCode value={permanentURL} size={240} />
          </Centered>
        </Section.Content>
      </Section>
      <Section>
        <Section.Header>
          短縮URL
          {shortURL && (
            <CopyURLButton label="URLをコピーする" value={shortURL} />
          )}
        </Section.Header>
        <Section.Content>
          {!shortURL && (
            <Button onClick={generateShortURL}>短縮URLを生成する</Button>
          )}
          {shortURL && <Link href={shortURL}>{shortURL}</Link>}
        </Section.Content>
      </Section>
      <Section>
        <Section.Header>時計のリセット</Section.Header>
        <Section.Content>
          <DangerButton onClick={onClickResetSetting}>Reset</DangerButton>
        </Section.Content>
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  padding: 16px;
  color: #666;
  overflow-y: scroll;
`;

const CopyURLButton = styled(CopyButton)`
  margin-left: 8px;
`;

const Link = styled.a.attrs(() => ({
  target: "_blank",
}))`
  display: inline-block;
  font-size: 12px;
  color: #666;
  word-break: break-all;
  line-height: 1.8;
  margin-bottom: 32px;
`;

export default React.memo(InfoPanel);
