import React, { useMemo } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";

import { loadDefaultClockSetting } from "lib/savedata";
import { deserializeSetting } from "lib/setting";

import ClockPageTemplate from "./ClockPageTemplate";

type ClockPageLocationState = { clockList?: boolean };
/**
 * メインの時計ページ
 */
const ClockPage: React.FC<RouteComponentProps<
  {
    //
  },
  {
    //
  },
  ClockPageLocationState
>> = () => {
  const location = useLocation<ClockPageLocationState>();

  const [setting, isDefaultSetting] = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const s = params.get("s");

    if (s != null) {
      return [deserializeSetting(s), false];
    } else {
      return [loadDefaultClockSetting(), true];
    }
  }, [location.search]);

  return (
    <ClockPageTemplate
      setting={setting}
      displayDownloadButton={!isDefaultSetting}
      state={location.state || {}}
    />
  );
};

export default ClockPage;
