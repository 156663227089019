import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as T from "components/atoms/Table";
import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";

type ClockPartsDocumentContentProps = {
  className?: string;
  id?: string;
};

const ClockPartsCommonSettingDocument: React.FC<ClockPartsDocumentContentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Clock Parts Common Setting
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>時計タイプによらない共通の設定です。</S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-x`}>
                    <S.ListItemLabel>x</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#SizeType">
                        size
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツ中心のX座標。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;0%&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-y`}>
                    <S.ListItemLabel>y</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#SizeType">
                        size
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツ中心のY座標。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;0%&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-width`}>
                    <S.ListItemLabel>width</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#SizeType">
                        size
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>時計パーツ幅。</S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;100%&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-height`}>
                    <S.ListItemLabel>height</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#SizeType">
                        size
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツ中心の高さ。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;100%&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-rotate`}>
                    <S.ListItemLabel>rotate</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツ回転角度。度（degree）で指定。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>0</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-timeline`}>
                    <S.ListItemLabel>timeline</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to={`/docs#${id}-TimelineSetting`}>
                        TimelineSetting
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツの位置、サイズ、回転のタイミングの設定。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-background`}>
                    <S.ListItemLabel>background</S.ListItemLabel>
                    <S.ListItemValidation>
                      <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/background">
                        css.background
                      </S.A>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツの背景。CSSの<S.Code>background</S.Code>
                    に用いることができる値なら、何でも適用可能。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-opacity`}>
                    <S.ListItemLabel>opacity</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツの不透明度。設定可能な値は<S.Code>0</S.Code>~
                    <S.Code>1</S.Code>で、<S.Code>0</S.Code>
                    の場合完全に透明になります。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>1</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <S.ItemListHeader>
                <AnchorContainer id={`${id}-coordinate-system`}>
                  Coordinate System
                </AnchorContainer>
              </S.ItemListHeader>
              <DocumentClock
                setting={{
                  title: "coordinate system description",
                  background: "#fff",
                  aspectRatio: 1.78,
                  parts: [
                    {
                      type: "Label",
                      common: {
                        x: "0%",
                        y: "0%",
                        width: "100w",
                        height: "0.5h",
                        background: "#666",
                      },
                      clock: {
                        text: "",
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "0%",
                        y: "0%",
                        width: "0.5h",
                        height: "100h",
                        background: "#666",
                      },
                      clock: {
                        text: "",
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "0%",
                        y: "0%",
                        width: "1w",
                        height: "1w",
                        background: "#333",
                        borderRadius: "0.5w",
                      },
                      clock: {
                        text: "",
                        fontSize: 0.05,
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "2w",
                        y: "8h",
                      },
                      clock: {
                        text: "↖︎\n             (0, 0)",
                        fontSize: 0.05,
                        color: "#4f566b",
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "-48w",
                        y: "10h",
                      },
                      clock: {
                        text:
                          "↖︎\n                   (-50w, 0)︎\n                   (-50%, 0)",
                        fontSize: 0.05,
                        color: "#4f566b",
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "42w",
                        y: "10h",
                      },
                      clock: {
                        text: "                ↗︎\n(50w, 0)︎\n(50%, 0)",
                        fontSize: 0.05,
                        color: "#4f566b",
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "-8w",
                        y: "-40h",
                      },
                      clock: {
                        text: "                ↗︎\n(0, -50h)︎\n(0, -50%)",
                        fontSize: 0.05,
                        color: "#4f566b",
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "-8w",
                        y: "40h",
                      },
                      clock: {
                        text: "(0, 50h)︎\n(0, 50%)\n                ↘︎",
                        fontSize: 0.05,
                        color: "#4f566b",
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "0%",
                        y: "-20h",
                      },
                      clock: {
                        text: "←─── 100w, 100% (x, width) ───────────────────→",
                        fontSize: 0.05,
                        color: "#e56f4a",
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        x: "30%",
                        y: "0%",
                      },
                      clock: {
                        text:
                          "↑\n│\n│\n│\n│\n100h, 100% (y, height) \n│\n│\n│\n│\n│\n│\n│\n↓",
                        fontSize: 0.05,
                        color: "#e56f4a",
                      },
                    },
                  ],
                }}
                editorHeight={340}
                displayClockAreaBorder
              />
            </S.Contents>
            <S.Contents>
              <T.Container id="SizeType">
                <T.Title>SIZE TYPE</T.Title>
                <T.Table>
                  <T.Row>
                    <T.Header>w</T.Header>
                    <T.Data>時計エリアの横幅を100wとした時の長さ</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>h</T.Header>
                    <T.Data>時計エリアの横幅を100hとした時の長さ</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>%</T.Header>
                    <T.Data>
                      x, widthの場合は時計エリアの横幅を100%とした時の長さ。
                      <br />
                      y, heightの場合は時計エリアの縦幅を100%とした時の長さ。
                    </T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>単位なし</T.Header>
                    <T.Data>
                      x, widthの場合は時計エリアの横幅を1とした時の長さ。
                      <br />
                      y, heightの場合は時計エリアの縦幅を1とした時の長さ。
                    </T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.SubsequentItemList>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-borderWidth`}>
                    <S.ListItemLabel>borderWidth</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#SizeType">
                        size
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツのボーダーの幅。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-borderStyle`}>
                    <S.ListItemLabel>borderStyle</S.ListItemLabel>
                    <S.ListItemValidation>
                      <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/border-style">
                        css.border-style
                      </S.A>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツのボーダーのスタイル。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-borderColor`}>
                    <S.ListItemLabel>borderColor</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツのボーダーの色。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-borderRadius`}>
                    <S.ListItemLabel>borderRadius</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#SizeType">
                        size
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツの角丸の半径。
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.SubsequentItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <DocumentClock
                setting={{
                  title: "border sample",
                  background: "#333",
                  aspectRatio: 1.78,
                  parts: [
                    {
                      type: "Label",
                      common: {
                        width: "50%",
                        height: "50%",
                        borderWidth: "0.5w",
                        borderStyle: "dashed",
                        borderColor: "#ff9900",
                        borderRadius: "5w",
                      },
                      clock: {
                        text: "",
                      },
                    },
                  ],
                }}
                editerOpen={false}
                displayClockAreaBorder
              />
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.SubsequentItemList>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-filter`}>
                    <S.ListItemLabel>filter</S.ListItemLabel>
                    <S.ListItemValidation>
                      <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/filter">
                        css.filter
                      </S.A>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツのグラフィック効果。値はCSSの
                    <S.Code>filter</S.Code>
                    に指定可能な値は全て設定可能です。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    本プロパティを指定することで、ぼかしや色変化などのグラフィック効果を要素に適用します。
                    例えば<S.Code>blur()</S.Code>を適用するとぼかし効果を、
                    <S.Code>drop-shadow()</S.Code>
                    を適用するとドロップシャドウを適用できます。その他の効果については、
                    <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/filter">
                      css.filter
                    </S.A>
                    を参照してください。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-blend`}>
                    <S.ListItemLabel>blend</S.ListItemLabel>
                    <S.ListItemValidation>
                      <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/mix-blend-mode">
                        css.mix-blend-mode
                      </S.A>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計パーツと他の時計パーツや背景とどのように混合するかを設定します。値はCSSの
                    <S.Code>mix-blend-mode</S.Code>
                    に指定可能な値は全て設定可能です。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    設定可能な値は、
                    <S.Code>color-burn</S.Code>, <S.Code>color</S.Code>,
                    <S.Code>color-dodge</S.Code>, <S.Code>darken</S.Code>,
                    <S.Code>difference</S.Code>, <S.Code>exclusion</S.Code>,
                    <S.Code>hard-light</S.Code>, <S.Code>hue</S.Code>,
                    <S.Code>lighten</S.Code>, <S.Code>luminosity</S.Code>,
                    <S.Code>multiply</S.Code>, <S.Code>normal</S.Code>,
                    <S.Code>overlay</S.Code>, <S.Code>saturation</S.Code>,
                    <S.Code>screen</S.Code>, <S.Code>soft-light</S.Code>
                    です。効果については、
                    <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/mix-blend-mode">
                      css.mix-blend-mode
                    </S.A>
                    を参照ください。
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.SubsequentItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <DocumentClock
                setting={{
                  title: "filter/blend sample",
                  background: "#333",
                  aspectRatio: 1.78,
                  parts: [
                    {
                      type: "Label",
                      common: {
                        width: "70h",
                        height: "70h",
                        background: "#ff0000",
                        filter: "blur(3px)",
                        blend: "screen",
                      },
                      clock: {
                        text: "sample",
                        color: "#ffffff",
                      },
                    },
                    {
                      type: "Label",
                      common: {
                        width: "70h",
                        height: "70h",
                        background: "#0000ff",
                        rotate: 30,
                        filter: "blur(3px)",
                        blend: "screen",
                      },
                      clock: {
                        text: "",
                      },
                    },
                  ],
                }}
                editerOpen={false}
                displayClockAreaBorder
              />
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(ClockPartsCommonSettingDocument);
