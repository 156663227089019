import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as T from "components/atoms/Table";
import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";

import { defaultTextClockPartsSetting } from "./setting";

type TextClockPartsDocumentProps = {
  className?: string;
  id?: string;
};

const TextClockPartsDocument: React.FC<TextClockPartsDocumentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Text Clock
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>テキスト時計の設定です。</S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-type`}>
                    <S.ListItemLabel>type</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#TextClockPartsType">
                        TextClockPartsType
                      </Link>
                    </S.ListItemValidation>
                    <S.Required />
                  </AnchorContainer>
                  <S.ListItemDescription>
                    テキスト時計のタイプ
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-format`}>
                    <S.ListItemLabel>format</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    カスタムタイプ利用時のフォーマット文字列
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    内部的にdayjsを利用してフォーマットしているため、
                    <S.A href="https://day.js.org/docs/en/display/format">
                      dayjsのFormat
                    </S.A>
                    文字列を利用可能です。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-text`}>
                    <S.ListItemLabel>text</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>全体の文字列</S.ListItemDescription>
                  <S.ListItemDescription>
                    <S.Code>{`{CLOCK}`}</S.Code>は時計の文字列で置換されます
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;{`{CLOCK}`}&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-delay`}>
                    <S.ListItemLabel>delay</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    切り替え遅延時間。単位はミリ秒。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>300</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-color`}>
                    <S.ListItemLabel>color</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-fontSize`}>
                    <S.ListItemLabel>fontSize</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-fontWeight`}>
                    <S.ListItemLabel>fontWeight</S.ListItemLabel>
                    <S.ListItemValidation>FontWeight</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-fontFamily`}>
                    <S.ListItemLabel>fontFamily</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-whiteSpace`}>
                    <S.ListItemLabel>whiteSpace</S.ListItemLabel>
                    <S.ListItemValidation>
                      <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/white-space">
                        css.white-space
                      </S.A>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    <Link smooth to="#TextPartsCommonSetting">
                      Text Parts Common Setting
                    </Link>
                    を参照
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <DocumentClock partsSetting={defaultTextClockPartsSetting} />
            </S.Contents>
            <S.Contents>
              <T.Container id="TextClockPartsType">
                <T.Title>TextClockPartsType</T.Title>
                <T.Table>
                  <T.Row>
                    <T.Header>Year</T.Header>
                    <T.Data>年</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Month</T.Header>
                    <T.Data>月（1~12）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Day</T.Header>
                    <T.Data>日（1~31）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>MonthDay</T.Header>
                    <T.Data>月/日</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>YearMonthDay</T.Header>
                    <T.Data>年/月/日</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Hour</T.Header>
                    <T.Data>時（0~23）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Minute</T.Header>
                    <T.Data>分（0~59）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Second</T.Header>
                    <T.Data>秒（0~59）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>HourMinute</T.Header>
                    <T.Data>分:秒</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>HourMinuteSecond</T.Header>
                    <T.Data>時:分:秒</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>DayOfYear</T.Header>
                    <T.Data>年内の何日目か (1~366)</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>MinuteOfDay</T.Header>
                    <T.Data>１日内の何分目か（0~1439）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>SecondOfDay</T.Header>
                    <T.Data>１日内の何秒目か（0~86399）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>SecondOfHour</T.Header>
                    <T.Data>１時間内の何秒目か（0~3599）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Custom</T.Header>
                    <T.Data>
                      カスタムフォーマット。
                      <S.A href="https://day.js.org/docs/en/display/format">
                        dayjsのFormat
                      </S.A>
                      による指定が行えます。
                    </T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(TextClockPartsDocument);
