import { JSONSchema7, JSONSchema7Definition } from "json-schema";

import { generateTimelineSettingSchema } from "lib/schema";

const GaugePartsSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "ゲージの設定",
  properties: {
    type: {
      title: "パーツタイプ",
      enum: ["Gauge"],
    },
    common: { $ref: "#/definitions/ClockPartsCommonSetting" },
    clock: { $ref: "#/definitions/GaugePartsIndividualSetting" },
    timeline: { $ref: "#/definitions/GaugePartsTimelineSetting" },
  },
  required: ["type", "common", "clock"],
};

const GaugePartsIndividualSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "ゲージの個別設定",
  properties: {
    type: {
      $ref: "#/definitions/GaugePartsType",
      description: "ゲージのタイプ",
      default: "Minute",
    },
    divisor: {
      type: "number",
      description: "繰り返し単位",
    },
    color: {
      type: "string",
      description: "ゲージの色",
    },
    duration: {
      type: "number",
      description: "ゲージのアニメーション時間（ミリ秒）",
      minimum: 0,
      maximum: 1000,
      default: 300,
    },
    delay: {
      type: "number",
      description: "ゲージのアニメーションの開始遅延時間（ミリ秒）",
      minimum: 0,
      maximum: 1000,
      default: 0,
    },
  },
  required: ["type"],
};

const GaugePartsTimelineSetting = generateTimelineSettingSchema(
  GaugePartsIndividualSetting
);

const GaugePartsType: JSONSchema7Definition = {
  enum: ["Day", "Hour", "Minute"],
};

export const GaugePartsDefinitions: JSONSchema7["definitions"] = {
  GaugePartsSetting,
  GaugePartsIndividualSetting,
  GaugePartsTimelineSetting,
  GaugePartsType,
};
