import React, { useMemo } from "react";
import styled from "styled-components";

import { ClockPartsSetting, ClockSetting } from "clock";
import { TimelineOptions, generateTimelineSetting } from "clock/type";
import DebugInfo from "components/clock/DebugInfo";
import { SettingContextProvider, useSetting, useTick } from "hooks";
import { convertTime } from "lib/date";
import { invertedColor } from "lib/style";

import ClockArea from "./ClockArea";
import ClockParts from "./ClockParts";
import ClockPartsWrapper from "./ClockPartsWrapper";

type ClockProps = {
  className?: string;
  title?: string;
  displayClockAreaBorder?: boolean;
  displayTitle?: boolean;
};

const Clock: React.FC<ClockProps> = ({
  displayClockAreaBorder,
  displayTitle = false,
}) => {
  const time = useTick();
  const {
    title,
    aspectRatio,
    background = "#000",
    parts: _parts = [],
  } = useSetting();

  const parts = useMemo(() => {
    const parts = _parts.map((setting) => generateTimelineSetting(setting));
    return parts;
  }, [_parts]);

  return (
    <ClockArea
      aspectRatio={aspectRatio || undefined}
      background={background}
      displayBorder={displayClockAreaBorder}
    >
      {parts.map((part, i) => {
        let setting: ClockPartsSetting = part.base;
        let timelineOptions: TimelineOptions = {
          duration: 300,
          delay: 0,
          timingFunction: "ease",
          divisor: null,
        };
        if (part.timeline) {
          let targetTime = convertTime(time, part.timeline.type || "Second");
          if (part.timeline.divisor) {
            targetTime = targetTime % part.timeline.divisor;
          }
          const timelineItem = part.timeline.value(targetTime);
          if (timelineItem) {
            setting = {
              type: setting.type,
              ...timelineItem,
            } as ClockPartsSetting;
          }

          timelineOptions = {
            duration: part.timeline.duration || timelineOptions.duration,
            delay: part.timeline.delay || timelineOptions.delay,
            timingFunction:
              part.timeline.timingFunction || timelineOptions.timingFunction,
          };
        }

        return (
          <ClockPartsWrapper
            key={i}
            setting={setting.common}
            timelineOptions={timelineOptions}
          >
            <ClockParts setting={setting} />
          </ClockPartsWrapper>
        );
      })}
      {displayTitle && (
        <Title style={{ color: invertedColor(background) }}>{title}</Title>
      )}
      <DebugInfo />
    </ClockArea>
  );
};

const Title = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  opacity: 0.75;
`;

type PartsClockProps = {
  className?: string;
  title?: string;
  displayClockAreaBorder?: boolean;
  displayTitle?: boolean;
  clockPartsSetting: ClockPartsSetting;
};

export const PartsClock: React.FC<PartsClockProps> = ({
  className,
  title,
  displayClockAreaBorder,
  clockPartsSetting,
}) => {
  const setting: ClockSetting = {
    title: title || "",
    aspectRatio: 2,
    background: "#333",
    parts: [clockPartsSetting],
  };

  return (
    <SettingContextProvider setting={setting}>
      <Clock
        className={className}
        title={title}
        displayTitle={!!title}
        displayClockAreaBorder={displayClockAreaBorder}
      />
    </SettingContextProvider>
  );
};

export default Clock;
