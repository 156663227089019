/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

type AnyObject = { [key: string]: any };
type StringObject = { [key: string]: string };

/**
 * オブジェクトの型文字列を取得する
 */
const typeOf = (obj: any) => {
  return toString.call(obj).slice(8, -1).toLowerCase();
};

/**
 * 対象のオブジェクトのキー変換表, 値変換表を元に再帰的に変換したオブジェクトを返す
 * Object, Array, プリミティブ型以外が含まれる場合の動作は未検証
 * @param obj 対象のオブジェクト
 * @param keyMapping キー変換表
 * @param valueMaping 値換表
 * @param keyValueMapping キー・値ペアの変換表
 */
export const transform = (
  obj: any,
  keyMapping: StringObject,
  valueMaping: StringObject,
  keyValueMapping?: [
    [string, any | null],
    [string, any | ((value: any) => { [key: string]: any })]
  ][]
): any => {
  const _obj = JSON.parse(JSON.stringify(obj));
  switch (typeOf(_obj)) {
    case "object": {
      const result: AnyObject = {};

      for (const [key, value] of Object.entries(_obj)) {
        // キー・値ペアの変換表に該当するものがあるかをチェック
        const kvMapping = (keyValueMapping || []).find(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([[k, v], _]) => key === k && (v === null || value === v)
        );
        if (kvMapping) {
          // 元の値は利用しない
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, [keyTo, valueTo]] = kvMapping;

          if (valueTo && typeof valueTo === "function") {
            for (const [_keyTo, _valueTo] of Object.entries(valueTo(value))) {
              result[_keyTo] = _valueTo;
            }
          } else {
            if (valueTo !== undefined) {
              result[keyTo] = valueTo;
            }
          }
          continue;
        }

        const mappedKey = keyMapping[key] || key;
        const mappedValue = transform(
          value,
          keyMapping,
          valueMaping,
          keyValueMapping
        );
        result[mappedKey] = mappedValue;
      }

      return result;
    }
    case "array": {
      return _obj.map((value: any) =>
        transform(value, keyMapping, valueMaping, keyValueMapping)
      );
    }
    case "string":
      return valueMaping[_obj] || _obj;
    default:
      return _obj;
  }
};

/**
 * オブジェクトのkey, valueを入れ替える
 * @param obj 対象のオブジェクト
 */
export const invert = (obj: StringObject): StringObject => {
  const result: StringObject = {};
  for (const [key, value] of Object.entries(obj)) {
    result[value] = key;
  }

  return result;
};
