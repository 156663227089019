import { Size } from "types";

const sizeRegex = /^(\-?\d+(?:\.\d+)?)([wh%]?)$/;

export const toPx = (
  value: string | undefined,
  areaSize: Size,
  direction: "x" | "y" | "xy"
): string => {
  if (!value) {
    return "0px";
  }
  const match = value.match(sizeRegex);
  if (match) {
    if (direction == "xy" && match[2] == "%") {
      return value;
    }

    let num = parseFloat(match[1]);
    let baseSize: number;
    switch (match[2]) {
      case "w":
        num = num / 100;
        baseSize = areaSize.width;
        break;

      case "h":
        num = num / 100;
        baseSize = areaSize.height;
        break;

      case "%":
        num = num / 100;
        baseSize = direction === "x" ? areaSize.width : areaSize.height;
        break;

      default:
        baseSize = direction === "x" ? areaSize.width : areaSize.height;
    }
    return `${baseSize * num}px`;
  }
  return value;
};
