import React, { useMemo, useState } from "react";
import LazyLoad from "react-lazyload";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

import { ClockPartsSetting } from "clock";
import { PartsClock } from "components/clock/Clock";
import JsonEditor from "components/editor/JsonEditor";
import Accordion from "components/molecules/Accordion";
import CopyButton from "components/molecules/CopyButton";
import { AddButton, ViewDocumentButton } from "components/molecules/IconButton";
import { generateClockPartsSettingSchema } from "lib/setting";

type DocumentPartsClockProps = {
  className?: string;
  clockPartsSetting: ClockPartsSetting;
  title?: string;
  addClock?: (partsSetting: ClockPartsSetting) => void;
  documentLink?: string;
  documentLinkTarget?: string;
  editerOpen?: boolean;
  overflow?: boolean;
};

const DocumentPartsClock: React.FC<DocumentPartsClockProps> = ({
  className,
  clockPartsSetting,
  title,
  addClock,
  documentLink,
  documentLinkTarget = "_blank",
  editerOpen = false,
  overflow = false,
}) => {
  const [setting, setSetting] = useState<ClockPartsSetting>(clockPartsSetting);
  const settingJson = useMemo(() => {
    return JSON.stringify(setting, null, 2);
  }, [setting]);

  const schema = useMemo(
    () => generateClockPartsSettingSchema(setting.type),
    []
  );
  const uniqueKey = useMemo(() => {
    return Math.random().toString(32).substring(2, 10);
  }, []);

  return (
    <Wrapper className={className}>
      <LazyLoad height={200} overflow={overflow} unmountIfInvisible>
        <ClockContainer>
          <PartsClock clockPartsSetting={setting} title={title} />
        </ClockContainer>
      </LazyLoad>
      <Accordion accordionKey={uniqueKey} open={editerOpen}>
        <Accordion.OpenContent>
          <EditorContainer>
            <JsonEditor
              setting={setting}
              schema={schema}
              onUpdateSetting={setSetting}
            />
          </EditorContainer>
        </Accordion.OpenContent>
      </Accordion>
      <ActionContainer>
        {addClock && (
          <ActionItem>
            <AddButton
              label="Add to Clock"
              onClick={() => addClock(setting)}
            ></AddButton>
          </ActionItem>
        )}
        {documentLink && (
          <ActionItem>
            <Link smooth to={documentLink} target={documentLinkTarget}>
              <StyledViewDocumentButton label="view document" />
            </Link>
          </ActionItem>
        )}
        <ActionItem>
          <CopyButton value={settingJson} label="Copy Setting"></CopyButton>
        </ActionItem>
        <ActionItem>
          <Accordion.Link type="switch" accordionKey={uniqueKey}>
            <ViewDocumentButton label="View Setting" />
          </Accordion.Link>
        </ActionItem>
      </ActionContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
`;

const ClockContainer = styled.div`
  height: 240px;
`;
const EditorContainer = styled.div`
  padding-bottom: 1px;
  > * {
    height: 480px;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4px;
`;

const ActionItem = styled.div`
  height: 18px;
  font-size: 12px;
  margin-right: 8px;

  :hover {
    text-decoration: underline;
  }
`;

const StyledViewDocumentButton = styled(ViewDocumentButton)`
  display: inline-flex;
  margin-left: 16px;
`;

export default React.memo(DocumentPartsClock);
