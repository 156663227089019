/* eslint-disable @typescript-eslint/no-explicit-any */
import { JSONSchema7, JSONSchema7Definition } from "json-schema";

import { clocks } from "clock/parts";
import { generatePartialSettingSchema } from "lib/schema";
import { transform } from "lib/utils/object";

const StringSize: JSONSchema7Definition = {
  type: "string",
  pattern: "^-?[0-9]+(?:\\.[0-9]+)?[wh%]?$",
};
const ClockPartsType: JSONSchema7Definition = {
  enum: ["TextClock", "DotClock", "Label"],
  description: "時計パーツのタイプ",
};
const ClockPartsCommonSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "時計パーツ共通の設定",
  properties: {
    x: {
      title: "X座標",
      description: "中心のX座標、領域中心は0（単位: %,w,h）",
      $ref: "#/definitions/StringSize",
      default: "0%",
    },
    y: {
      title: "Y座標",
      description: "中心のY座標、領域中心は0（単位: %,w,h）",
      $ref: "#/definitions/StringSize",
      default: "0%",
    },
    width: {
      title: "幅",
      description: "幅（単位: %,w,h）",
      $ref: "#/definitions/StringSize",
      default: "100%",
    },
    height: {
      title: "高さ",
      description: "高さ（単位: %,w,h）",
      $ref: "#/definitions/StringSize",
      default: "100%",
    },
    rotate: {
      title: "回転",
      description: "degで指定",
      type: "number",
      default: 0,
    },
    background: {
      title: "背景",
      description:
        "CSS backgroundプロパティに設定可能な値を設定可能。'#000', 'linear-gradient(white, navy)'など",
      type: "string",
    },
    opacity: {
      title: "不透明度",
      description: "設定可能な値は0-1",
      type: "number",
      default: 1,
    },
    borderWidth: {
      title: "ボーダーの幅",
      description: "ボーダーの幅（単位: %,w,h）",
      type: "string",
    },
    borderStyle: {
      title: "ボーダーのスタイル",
      description: "ボーダーのスタイル",
      type: "string",
    },
    borderColor: {
      title: "ボーダーの色",
      description: "ボーダーの色",
      type: "string",
    },
    borderRadius: {
      title: "角丸の半径",
      description: "角丸の半径（単位: %,w,h）",
      type: "string",
    },
    filter: {
      title: "フィルター",
      description: "フィルター",
      type: "string",
    },
    blend: {
      title: "ブレンド",
      description: "背景とのブレンド方法",
      enum: [
        "color-burn",
        "color",
        "color-dodge",
        "darken",
        "difference",
        "exclusion",
        "hard-light",
        "hue",
        "lighten",
        "luminosity",
        "multiply",
        "normal",
        "overlay",
        "saturation",
        "screen",
        "soft-light",
      ],
    },
  },
  required: [],
};

const ClockPartsCommonSettingForTimeline = generatePartialSettingSchema(
  ClockPartsCommonSetting
);

export const ClockFSettingSchema: JSONSchema7 = {
  $id: "http://example.com/schemas/schema.json",
  $schema: "http://json-schema.org/draft-07/schema",

  title: "設定",
  description: "時計全般の設定",

  type: "object",
  additionalProperties: false,

  properties: {
    title: {
      title: "設定の名前",
      type: "string",
      default: "",
    },
    version: {
      title: "設定スキーマのバージョン",
      enum: [1, 2],
      default: 2,
    },
    background: {
      title: "背景の設定",
      type: "string",
      description: "",
    },
    aspectRatio: {
      title: "時計領域の縦横比（横幅/縦幅）",
      type: ["number", "null"],
      minimum: 0.01,
    },
    parts: {
      title: "時計パーツリスト",
      type: "array",
      items: {
        type: "object",
        oneOf: Object.entries(clocks).map(([type, meta]) => ({
          title: meta.title,
          $ref: `#/definitions/${type}PartsSetting`,
        })),
      },
    },
  },

  required: [],

  // definitions に定義した値は、$ref としてスキーマ内から参照可能
  definitions: {
    StringSize,
    ClockPartsType,
    ClockPartsCommonSetting,
    ClockPartsCommonSettingForTimeline,

    ...Object.entries(clocks).reduce<JSONSchema7["definitions"]>(
      (accumulator, current) => ({ ...accumulator, ...current[1].definitions }),
      {}
    ),
  },
};

export const ClockFSettingSchemaForForm = transform(
  ClockFSettingSchema,
  {},
  {},
  [
    [
      ["$ref", "#/definitions/StringSize"],
      ["type", "string"],
    ],
    [
      ["default", null],
      ["default", undefined],
    ],
  ]
);
