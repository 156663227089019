import React, { useEffect, useState, createContext, useContext } from "react";

import { Size } from "types";

type ClockAreaContextProviderProps = {
  /** 全体のサイズ */
  containerSize: Size;
  /** 時計のエリアとして切り取る矩形のアスペクト比 */
  aspectRatio?: number;
  /** エリアの枠線を表示するか */
  borderColor?: string;
};

const ClockAreaContext = createContext<Size>({ width: 0, height: 0 });

export const ClockAreaContextProvider: React.FC<ClockAreaContextProviderProps> = ({
  containerSize,
  aspectRatio,
  borderColor,
  children,
}) => {
  const [size, setSize] = useState<Size>(containerSize);

  useEffect(() => {
    const containerHeight = containerSize.height;
    const containerWidth = containerSize.width;

    const containerSizeAspectRatio =
      containerHeight > 0 ? containerWidth / containerHeight : 1;
    const clockAreaAspectRatio = aspectRatio
      ? aspectRatio
      : containerSizeAspectRatio;

    let clockSize: Size;
    if (containerSizeAspectRatio >= clockAreaAspectRatio) {
      clockSize = {
        width: containerHeight * clockAreaAspectRatio,
        height: containerHeight,
      };
    } else {
      clockSize = {
        width: containerWidth,
        height: containerWidth / clockAreaAspectRatio,
      };
    }

    setSize(clockSize);
  }, [aspectRatio, containerSize]);

  const style: React.CSSProperties = {
    width: size.width,
    height: size.height,
    position: "relative",
  };
  return (
    <ClockAreaContext.Provider value={size}>
      <div style={style}>
        {children}
        {borderColor && <Border color={borderColor} />}
      </div>
    </ClockAreaContext.Provider>
  );
};

const Border: React.FC<{ color: string }> = ({ color }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: color,
    }}
  />
);

export const useClockArea = (): Size => {
  return useContext(ClockAreaContext);
};
