import React, { createContext, useContext } from "react";

import { ClockSetting, defaultClockSetting } from "clock";

type SettingContextProviderProps = {
  setting: ClockSetting;
};

const SettingContext = createContext<ClockSetting>(defaultClockSetting);

export const SettingContextProvider: React.FC<SettingContextProviderProps> = ({
  setting,
  children,
}) => {
  return (
    <SettingContext.Provider value={setting}>
      {children}
    </SettingContext.Provider>
  );
};

export const useSetting = (): ClockSetting => {
  return useContext(SettingContext);
};
