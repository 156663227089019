import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";

import SwitchingText from "components/clock/SwitchingText";
import { useTick } from "hooks/tick";

import { CountPartsSetting, CountDirectionType } from "./type";

dayjs.extend(customParseFormat);
// 相対時間の有効化
dayjs.extend(relativeTime);

interface CountPartsProps {
  setting: CountPartsSetting;
}

type DiffFormat = "y" | "w" | "d" | "h" | "m" | "s";
const formats: {
  [key in CountPartsSetting["clock"]["type"]]?: DiffFormat;
} = {
  Year: "y",
  Week: "w",
  Day: "d",
  Hour: "h",
  Minute: "m",
  Second: "s",
};

const CountParts: React.FC<CountPartsProps> = ({
  setting: {
    clock: {
      type,
      target,
      direction = "Both",
      text: textFormat = "{CLOCK}",
      color = "#fff",
      fontSize = 0.2,
      fontWeight = "normal",
      fontFamily,
      fontSizeAdjust,
      fontStretch,
      fontVariant,
      whiteSpace = "pre-wrap",
      delay = 300,
    },
  },
}) => {
  const targetTime = dayjs(target);
  const time = useTick();
  const timeText = formatTime(targetTime, time, type, direction);
  const prevTimeText = formatTime(
    targetTime,
    time.add(-1, "s"),
    type,
    direction
  );
  const text = textFormat.replace("{CLOCK}", timeText);
  const prevText = textFormat.replace("{CLOCK}", prevTimeText);
  const isEven = time.get("second") % 2 === 0;

  return (
    <SwitchingText
      text={text}
      prevText={prevText}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      fontSizeAdjust={fontSizeAdjust}
      fontStretch={fontStretch}
      fontVariant={fontVariant}
      whiteSpace={whiteSpace}
      delay={delay}
      isEven={isEven}
    />
  );
};

const formatTime = (
  targetTime: Dayjs,
  time: Dayjs,
  type: CountPartsSetting["clock"]["type"],
  direction: CountDirectionType
) => {
  let t: Dayjs;
  const sign = direction === "CountUp" ? -1 : 1;
  if (
    (direction == "CountDown" && time >= targetTime) ||
    (direction == "CountUp" && time <= targetTime)
  ) {
    t = targetTime;
  } else {
    t = time;
  }
  switch (type) {
    case "HourMinute": {
      const hour = sign * targetTime.diff(t, "h");
      const minute = Math.abs((sign * targetTime.diff(t, "m")) % 60);

      const hourStr = hour.toString().padStart(2, "0");
      const minStr = minute.toString().padStart(2, "0");

      return `${hourStr}:${minStr}`;
    }
    case "HourMinuteSecond": {
      const hour = sign * targetTime.diff(t, "h");
      const minute = Math.abs((sign * targetTime.diff(t, "m")) % 60);
      const second = Math.abs((sign * targetTime.diff(t, "s")) % 60);

      const hourStr = hour.toString().padStart(2, "0");
      const minStr = minute.toString().padStart(2, "0");
      const secStr = second.toString().padStart(2, "0");

      return `${hourStr}:${minStr}:${secStr}`;
    }
    case "HourMinuteCutOff": {
      const hour = (sign * targetTime.diff(t, "h")) % 24;
      const minute = Math.abs((sign * targetTime.diff(t, "m")) % 60);

      const hourStr = hour.toString().padStart(2, "0");
      const minStr = minute.toString().padStart(2, "0");

      return `${hourStr}:${minStr}`;
    }
    case "HourMinuteSecondCutOff": {
      const hour = (sign * targetTime.diff(t, "h")) % 24;
      const minute = Math.abs((sign * targetTime.diff(t, "m")) % 60);
      const second = Math.abs((sign * targetTime.diff(t, "s")) % 60);

      const hourStr = hour.toString().padStart(2, "0");
      const minStr = minute.toString().padStart(2, "0");
      const secStr = second.toString().padStart(2, "0");

      return `${hourStr}:${minStr}:${secStr}`;
    }
    case "RelativeTime":
      return targetTime.from(t, true);
    default:
      return `${sign * targetTime.diff(t, formats[type])}`;
  }
};
export default CountParts;
