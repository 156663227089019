import React from "react";
import styled from "styled-components";

import { Centered } from "components/layout";
import { useClockArea } from "hooks";
import { generateTransitionStyle } from "lib/style";

import { LabelPartsSetting } from "./type";

interface LabelPartsProps {
  setting: LabelPartsSetting;
}

const LabelParts: React.FC<LabelPartsProps> = ({
  setting: {
    clock: {
      text = "",
      color = "#fff",
      fontSize = 0.2,
      fontWeight = "normal",
      fontFamily,
      fontSizeAdjust,
      fontStretch,
      fontVariant,
      whiteSpace = "pre-wrap",
    },
  },
}) => {
  const { height: baseSize } = useClockArea();

  // 変化の設定
  const transitionStyle = generateTransitionStyle([
    "color",
    "font-size",
    "font-weight",
    "font-size-adjust",
    "font-stretch",
  ]);

  const style: React.CSSProperties = {
    fontSize: `${fontSize * baseSize}px`,
    fontWeight,
    fontFamily,
    fontSizeAdjust,
    fontStretch,
    fontVariant,
    whiteSpace,
    color,
    transition: transitionStyle,
  };
  return (
    <Wrapper>
      <Label style={style}>{text}</Label>
    </Wrapper>
  );
};

const Wrapper = styled(Centered)``;

const Label = styled.div`
  position: absolute;
  text-align: center;
`;

export default LabelParts;
