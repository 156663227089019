import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

import SVGIcon from "components/atoms/SVGIcon";

type AnchorContainerProps = {
  id: string;
  offset?: number;
  color?: string;
};

const AnchorContainer: React.FC<AnchorContainerProps> = ({
  id,
  offset = 16,
  color = "rgb(60, 66, 87)",
  children,
}) => {
  return (
    <Wrapper>
      <Anchor id={id} offset={offset} />
      <AnchorLink smooth to={`#${id}`}>
        <AnchorIcon color={color} />
      </AnchorLink>
      {children}
    </Wrapper>
  );
};

const AnchorLink = styled(Link)`
  position: absolute;
  padding: 4px;
  top: calc(50% - 14px);
  left: 0px;

  display: none;
`;

const Wrapper = styled.div`
  position: relative;

  margin-left: -18px;
  padding-left: 18px;

  &:hover {
    ${AnchorLink} {
      display: block;
    }
  }
`;

const Anchor = styled.div<{ offset: number }>`
  position: absolute;
  top: -${({ offset }) => offset}px;
`;

const AnchorIcon = styled(SVGIcon).attrs(() => ({ type: "link", size: 10 }))<{
  color: string;
}>`
  svg {
    fill: ${({ color }) => color};
  }
`;

export default AnchorContainer;
