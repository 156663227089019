import React from "react";

import { useTick } from "hooks/tick";
import { generateTransitionStyle } from "lib/style";

import { GaugePartsSetting } from "./type";

interface GaugePartsProps {
  setting: GaugePartsSetting;
}

const GaugeParts: React.FC<GaugePartsProps> = ({
  setting: {
    clock: { type, divisor, color = "#fff", duration = 300, delay = 0 },
  },
}) => {
  const time = useTick();
  let num: number;
  let max: number;
  switch (type) {
    case "Day": {
      num = time.hour() * 3600 + time.minute() * 60 + time.second();
      max = 24 * 60 * 60;
      if (divisor) {
        num = num % divisor;
        max = divisor;
      }
      break;
    }

    case "Hour": {
      num = time.minute() * 60 + time.second();
      max = 60 * 60;
      if (divisor) {
        num = num % divisor;
        max = divisor;
      }
      break;
    }

    case "Minute": {
      num = time.second();
      max = 60;
      if (divisor) {
        num = num % divisor;
        max = divisor;
      }
      break;
    }
  }

  const progress1 = (num / max) * 100;
  const progress2 = num === 0 ? 100 : num === 1 ? 0 : (num / max) * 100;

  const transition = generateTransitionStyle(
    ["background-color"],
    [["width", duration, "linear", delay]]
  );

  const style: React.CSSProperties = {
    position: "absolute",
    backgroundColor: color,
    top: -1,
    bottom: -1,
    left: -1,

    transition,
  };

  const style1 = {
    ...style,
    width: `calc(${progress1.toFixed(5)}% + 2px)`,
  };
  const style2 = {
    ...style,
    width: `calc(${progress2.toFixed(5)}% + 2px)`,
  };

  return (
    <>
      <div style={style1} />
      <div style={style2} />
    </>
  );
};

export default GaugeParts;
