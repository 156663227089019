import { JSONSchema7, JSONSchema7Definition } from "json-schema";

import { generateTimelineSettingSchema } from "lib/schema";

const AnalogClockPartsSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "アナログ時計の設定",
  properties: {
    type: {
      title: "パーツタイプ",
      enum: ["AnalogClock"],
    },
    common: { $ref: "#/definitions/ClockPartsCommonSetting" },
    clock: { $ref: "#/definitions/AnalogClockPartsIndividualSetting" },
    timeline: { $ref: "#/definitions/AnalogClockPartsTimelineSetting" },
  },
  required: ["type", "common", "clock"],
};

const AnalogClockPartsIndividualSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "アナログ時計の個別設定",
  properties: {
    type: {
      $ref: "#/definitions/AnalogClockPartsType",
      default: "HourMinuteSecond",
    },
    handWidth: {
      title: "針の幅",
      description: "幅（単位: w,h）",
      $ref: "#/definitions/StringSize",
      default: "2h",
    },
    color: {
      type: "string",
      description: "アナログ時計の文字色",
      default: "#fff",
    },
    delay: {
      type: "number",
      description: "時計パーツの切り替え遅延時間",
      minimum: 0,
      maximum: 1000,
      default: 300,
    },
  },
  required: ["type"],
};

const AnalogClockPartsTimelineSetting = generateTimelineSettingSchema(
  AnalogClockPartsIndividualSetting
);

const AnalogClockPartsType: JSONSchema7Definition = {
  enum: ["HourMinuteSecond", "HourMinute", "Hour", "Minute", "Second"],
  description: "アナログ時計のタイプ",
};

export const AnalogClockPartsDefinitions: JSONSchema7["definitions"] = {
  AnalogClockPartsSetting,
  AnalogClockPartsIndividualSetting,
  AnalogClockPartsTimelineSetting,
  AnalogClockPartsType,
};
