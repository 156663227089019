import dayjs from "dayjs";
import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as T from "components/atoms/Table";
import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";

type CountPartsDocumentProps = {
  className?: string;
  id?: string;
};

const CountPartsDocument: React.FC<CountPartsDocumentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Count
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>カウントダウン・アップの設定です。</S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-type`}>
                    <S.ListItemLabel>type</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#CountPartsType">
                        CountPartsType
                      </Link>
                    </S.ListItemValidation>
                    <S.Required />
                  </AnchorContainer>
                  <S.ListItemDescription>
                    テキスト時計のタイプ
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-target`}>
                    <S.ListItemLabel>target</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#CountPartsType">
                        CountPartsType
                      </Link>
                    </S.ListItemValidation>
                    <S.Required />
                  </AnchorContainer>
                  <S.ListItemDescription>
                    カウントのターゲット日時
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    <S.Code>YYYY/MM/DD HH:mm:ss</S.Code>形式か
                    <S.Code>YYYY-MM-DD HH:mm:ss</S.Code>
                    形式で指定してください。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-direction`}>
                    <S.ListItemLabel>direction</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#CountPartsDirection">
                        CountPartsDirection
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    カウントする方向
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;Both&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-text`}>
                    <S.ListItemLabel>text</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>全体の文字列</S.ListItemDescription>
                  <S.ListItemDescription>
                    <S.Code>{`{CLOCK}`}</S.Code>は時計の文字列で置換されます
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;{`{CLOCK}`}&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-delay`}>
                    <S.ListItemLabel>delay</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    切り替え遅延時間。単位はミリ秒。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>300</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-color`}>
                    <S.ListItemLabel>color</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-fontSize`}>
                    <S.ListItemLabel>fontSize</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-fontWeight`}>
                    <S.ListItemLabel>fontWeight</S.ListItemLabel>
                    <S.ListItemValidation>FontWeight</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-fontFamily`}>
                    <S.ListItemLabel>fontFamily</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-whiteSpace`}>
                    <S.ListItemLabel>whiteSpace</S.ListItemLabel>
                    <S.ListItemValidation>
                      <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/white-space">
                        css.white-space
                      </S.A>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    <Link smooth to="#TextPartsCommonSetting">
                      Text Parts Common Setting
                    </Link>
                    を参照
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <DocumentClock
                partsSetting={{
                  type: "Count",
                  common: {
                    x: "0%",
                    y: "0%",
                    width: "100%",
                    height: "100%",
                  },
                  clock: {
                    type: "Second",
                    target: dayjs().add(1, "day").format("YYYY/MM/DD 00:00:00"),
                    direction: "CountDown",
                    text: "明日まで\nあと\n{CLOCK} 秒",
                    delay: 300,
                    color: "#fff",
                    fontSize: 0.15,
                    fontWeight: "normal",
                  },
                }}
              />
            </S.Contents>
            <S.Contents>
              <T.Container id="CountPartsType">
                <T.Title>CountPartsType</T.Title>
                <T.Table>
                  <T.Row>
                    <T.Header>Year</T.Header>
                    <T.Data>年数</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Week</T.Header>
                    <T.Data>週数</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Day</T.Header>
                    <T.Data>日数</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Hour</T.Header>
                    <T.Data>時数</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Minute</T.Header>
                    <T.Data>分数</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Second</T.Header>
                    <T.Data>秒数</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>HourMinute</T.Header>
                    <T.Data>分数:秒数</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>HourMinuteCutoff</T.Header>
                    <T.Data>
                      分数:秒数 ただし、分数は60で割ったあまりの数
                    </T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>HourMinuteSecond</T.Header>
                    <T.Data>時数:分数:秒数</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>HourMinuteSecondCutoff</T.Header>
                    <T.Data>
                      時数:分数:秒数 ただし、時数は60で割ったあまりの数
                    </T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
            <S.Contents>
              <T.Container id="CountPartsDirection">
                <T.Title>CountPartsDirection</T.Title>
                <T.Table>
                  <T.Row>
                    <T.Header>Both</T.Header>
                    <T.Data>
                      ターゲット日時に到達していない場合はカウントダウン表示、
                      ターゲット日時を超えた場合はマイナス表示でカウントを表示する。
                    </T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>CountDown</T.Header>
                    <T.Data>
                      ターゲット日時までの残り時間を表示します。ターゲット日時を超えた場合は0表示になります。
                    </T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>CountUp</T.Header>
                    <T.Data>
                      ターゲット日時からの経過時間を表示します。ターゲット日時の前は0表示になります。
                    </T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(CountPartsDocument);
