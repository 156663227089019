import React, { useMemo, useState } from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import {
  ClockFSettingSchema,
  ClockSetting,
  ClockPartsSetting,
  emptyClockSetting,
} from "clock";
import Clock from "components/clock/Clock";
import JsonEditor from "components/editor/JsonEditor";
import Accordion from "components/molecules/Accordion";
import CopyButton from "components/molecules/CopyButton";
import { ViewDocumentButton } from "components/molecules/IconButton";
import { SettingContextProvider } from "hooks";

type DocumentClockProps = {
  className?: string;
  setting?: ClockSetting;
  partsSetting?: ClockPartsSetting;
  displayClockAreaBorder?: boolean;
  editorHeight?: number;
  editerOpen?: boolean;
  overflow?: boolean;
};

const DocumentClock: React.FC<DocumentClockProps> = ({
  className,
  setting: origSetting,
  partsSetting: origPartsSetting,
  displayClockAreaBorder,
  editorHeight = 480,
  editerOpen = false,
  overflow = false,
}) => {
  const [setting, setSetting] = useState<ClockSetting>(() => {
    if (origSetting) {
      return origSetting;
    }

    if (origPartsSetting) {
      return {
        title: "",
        aspectRatio: 2,
        background: "#333",
        parts: [origPartsSetting],
      };
    }

    return emptyClockSetting;
  });
  const settingJson = useMemo(() => {
    return JSON.stringify(setting, null, 2);
  }, [setting]);

  const uniqueKey = useMemo(() => {
    return Math.random().toString(32).substring(2, 10);
  }, []);

  return (
    <Wrapper className={className}>
      <LazyLoad height={240} overflow={overflow} unmountIfInvisible>
        <ClockContainer>
          <SettingContextProvider setting={setting}>
            <Clock displayClockAreaBorder={displayClockAreaBorder} />
          </SettingContextProvider>
        </ClockContainer>
      </LazyLoad>
      <Accordion accordionKey={uniqueKey} open={editerOpen}>
        <Accordion.OpenContent>
          <EditorContainer height={editorHeight}>
            <JsonEditor
              setting={setting}
              schema={ClockFSettingSchema}
              onUpdateSetting={setSetting}
            />
          </EditorContainer>
        </Accordion.OpenContent>
      </Accordion>
      <ActionContainer>
        <ActionItem>
          <CopyButton value={settingJson} label="Copy Setting"></CopyButton>
        </ActionItem>
        <ActionItem>
          <Accordion.Link type="switch" accordionKey={uniqueKey}>
            <ViewDocumentButton label="View Setting" />
          </Accordion.Link>
        </ActionItem>
      </ActionContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
`;

const ClockContainer = styled.div`
  height: 240px;
`;
const EditorContainer = styled.div<{ height: number }>`
  padding-bottom: 1px;
  > * {
    height: ${({ height }) => height}px;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4px;
`;

const ActionItem = styled.div`
  height: 18px;
  font-size: 12px;
  margin-right: 8px;

  :hover {
    text-decoration: underline;
  }
`;

export const StyledDocumentClock = styled(React.memo(DocumentClock))``;
export default DocumentClock;
