/* eslint-disable @typescript-eslint/no-explicit-any */

import styled from "styled-components";

import { Header } from "components/atoms/style";

const SectionSection = styled.section`
  margin-bottom: 96px;
`;
const SectionHeader = styled(Header)`
  position: relative;
  padding-top: 36px;
  margin-top: -16px;

  > a {
    text-decoration: inherit;
    color: inherit;
  }
`;

const SectionSubHeader = styled.h4`
  > a {
    text-decoration: inherit;
    color: inherit;
  }
`;
const SectionContent = styled.div``;
const SectionPaneContent = styled(SectionContent)`
  @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 600px));
    grid-column-gap: 80px;
    grid-template-rows: auto;
    grid-template-areas: "main sub";
  }
`;
const SectionContentMain = styled.div`
  grid-area: main;
`;
const SectionContentSub = styled.div`
  grid-area: sub;
`;
type SectionType = typeof SectionSection & {
  Header: typeof Section.Header;
  SubHeader: typeof SectionSubHeader;
  Content: typeof SectionContent;
  PaneContent: typeof SectionPaneContent;
  ContentMain: typeof SectionContentMain;
  ContentSub: typeof SectionContentSub;
};
const Section: any = SectionSection;
Section.Header = SectionHeader;
Section.SubHeader = SectionSubHeader;
Section.Content = SectionContent;
Section.PaneContent = SectionPaneContent;
Section.ContentMain = SectionContentMain;
Section.ContentSub = SectionContentSub;

export default Section as SectionType;
