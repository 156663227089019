import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

import { ClockPartsSetting } from "clock";
import { defaultAnalogClockPartsSetting } from "clock/AnalogClock/setting";
import { defaultCountPartsSetting } from "clock/Count/setting";
import { factorDotClockSettingCC } from "clock/FactorDotClock/setting";
import { defaultGaugePartsSetting } from "clock/Gauge/setting";
import { defaultImagePartsSetting } from "clock/Image/setting";
import { defaultLabelPartsSetting } from "clock/Label/setting";
import { defaultSimpleDotClockPartsSetting } from "clock/SimpleDotClock/setting";
import { defaultTextClockPartsSetting } from "clock/TextClock/setting";
import Section from "components/layout/Section";
import DocumentPartsClock from "components/molecules/DocumentPartsClock";
import { AddButton } from "components/molecules/IconButton";

export type DocumentPartsClockType = React.FC<
  Omit<React.ComponentProps<typeof DocumentPartsClock>, "overflow">
>;

type InventoryPanelProps = {
  className?: string;
  addClock: (partsSetting: ClockPartsSetting) => void;
};
const InventoryPanel: React.FC<InventoryPanelProps> = ({
  className,
  addClock,
}) => {
  return (
    <Wrapper className={className}>
      <Section>
        <Section.Header>設定方法</Section.Header>
        <Section.Content>
          設定の詳細については <Link to="/docs/setting/">Clock Setting</Link>{" "}
          を参照してください。
        </Section.Content>
        <Section.Content>
          以下にあるパーツのインベントリでは、「時計にパーツを追加する」をクリックすることで、編集中の時計に時計にパーツを追加することができます。
        </Section.Content>
        <Section.Content>
          追加したい時計を事前にカスタマイズしたい場合は、「View
          Setting」をクリックして設定を開き、カスタマイズ可能です。
        </Section.Content>
      </Section>

      <Section>
        <Section.Header>
          FactorDotClock
          <ButtonContainer>
            <AddButton
              label="時計にパーツを追加する"
              onClick={() => addClock(factorDotClockSettingCC)}
            />
          </ButtonContainer>
        </Section.Header>

        <Section.Content>
          <p>素因数分解ドット時計</p>
        </Section.Content>
        <Section.Content>
          <DocumentPartsClock
            documentLink="/docs/setting/#FactorDotClock"
            clockPartsSetting={factorDotClockSettingCC}
          />
        </Section.Content>
      </Section>

      <Section>
        <Section.Header>
          SimpleDotClock
          <ButtonContainer>
            <AddButton
              label="時計にパーツを追加する"
              onClick={() => addClock(defaultSimpleDotClockPartsSetting)}
            />
          </ButtonContainer>
        </Section.Header>
        <Section.Content>
          <p>シンプルドット時計</p>
        </Section.Content>
        <Section.Content>
          <DocumentPartsClock
            documentLink="/docs/setting/#SimpleDotClock"
            clockPartsSetting={defaultSimpleDotClockPartsSetting}
          />
        </Section.Content>
      </Section>

      <Section>
        <Section.Header>
          TextClock
          <ButtonContainer>
            <AddButton
              label="時計にパーツを追加する"
              onClick={() => addClock(defaultTextClockPartsSetting)}
            />
          </ButtonContainer>
        </Section.Header>
        <Section.Content>
          <p>テキスト時計</p>
        </Section.Content>
        <Section.Content>
          <DocumentPartsClock
            documentLink="/docs/setting/#TextClock"
            clockPartsSetting={defaultTextClockPartsSetting}
          />
        </Section.Content>
      </Section>

      <Section>
        <Section.Header>
          AnalogClock
          <ButtonContainer>
            <AddButton
              label="時計にパーツを追加する"
              onClick={() => addClock(defaultAnalogClockPartsSetting)}
            />
          </ButtonContainer>
        </Section.Header>
        <Section.Content>
          <p>アナログ時計</p>
        </Section.Content>
        <Section.Content>
          <DocumentPartsClock
            documentLink="/docs/setting/#AnalogClock"
            clockPartsSetting={defaultAnalogClockPartsSetting}
          />
        </Section.Content>
      </Section>

      <Section>
        <Section.Header>
          Count
          <ButtonContainer>
            <AddButton
              label="時計にパーツを追加する"
              onClick={() => addClock(defaultCountPartsSetting)}
            />
          </ButtonContainer>
        </Section.Header>
        <Section.Content>
          <p>カウント</p>
        </Section.Content>
        <Section.Content>
          <DocumentPartsClock
            documentLink="/docs/setting/#Count"
            clockPartsSetting={defaultCountPartsSetting}
          />
        </Section.Content>
      </Section>

      <Section>
        <Section.Header>
          Label
          <ButtonContainer>
            <AddButton
              label="時計にパーツを追加する"
              onClick={() => addClock(defaultLabelPartsSetting)}
            />
          </ButtonContainer>
        </Section.Header>
        <Section.Content>
          <p>ラベル</p>
        </Section.Content>
        <Section.Content>
          <DocumentPartsClock
            documentLink="/docs/setting/#Label"
            clockPartsSetting={defaultLabelPartsSetting}
          />
        </Section.Content>
      </Section>

      <Section>
        <Section.Header>
          Gauge
          <ButtonContainer>
            <AddButton
              label="時計にパーツを追加する"
              onClick={() => addClock(defaultGaugePartsSetting)}
            />
          </ButtonContainer>
        </Section.Header>
        <Section.Content>
          <p>ゲージ</p>
        </Section.Content>
        <Section.Content>
          <DocumentPartsClock
            documentLink="/docs/setting/#Gauge"
            clockPartsSetting={defaultGaugePartsSetting}
          />
        </Section.Content>
      </Section>

      <Section>
        <Section.Header>
          Image
          <ButtonContainer>
            <AddButton
              label="時計にパーツを追加する"
              onClick={() => addClock(defaultImagePartsSetting)}
            />
          </ButtonContainer>
        </Section.Header>
        <Section.Content>
          <p>イメージ</p>
        </Section.Content>
        <Section.Content>
          <DocumentPartsClock
            documentLink="/docs/setting/#Image"
            clockPartsSetting={defaultImagePartsSetting}
          />
        </Section.Content>
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  color: #666;
  padding: 16px;

  line-height: 22px;
`;

const ButtonContainer = styled.div`
  display: inline-block;
  margin-left: 16px;
  span {
    color: rgb(85, 108, 214);
    font-weight: bold;
  }
  svg {
    fill: rgb(85, 108, 214);
  }
`;

export default React.memo(InventoryPanel);
