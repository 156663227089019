import { publicURL } from "lib/url";

import { ImagePartsSetting } from "./type";

export const defaultImagePartsSetting: ImagePartsSetting = {
  type: "Image",
  common: {
    x: "0%",
    y: "0%",
    width: "100%",
    height: "100%",
    rotate: 0,
  },
  clock: {
    url: publicURL("/images/wood.jpg"),
    objectFit: "contain",
  },
};
