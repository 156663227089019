import { JSONSchema7, JSONSchema7Definition } from "json-schema";

import { generateTimelineSettingSchema } from "lib/schema";

const LabelPartsSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "テキストクロックの設定",
  properties: {
    type: {
      title: "パーツタイプ",
      enum: ["Label"],
    },
    common: { $ref: "#/definitions/ClockPartsCommonSetting" },
    clock: { $ref: "#/definitions/LabelPartsIndividualSetting" },
    timeline: { $ref: "#/definitions/LabelPartsTimelineSetting" },
  },
  required: ["type", "common", "clock"],
};

const LabelPartsIndividualSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "テキストの個別設定",
  properties: {
    text: {
      type: "string",
      description: "ラベルテキスト",
    },
    color: {
      type: "string",
      description: "テキストクロックの文字色",
    },
    fontSize: {
      type: "number",
      description: "文字サイズ（高さを１とした場合のサイズ）",
    },
    fontWeight: {
      enum: ["normal", "bold", 100, 200, 300, 400, 500, 600, 700, 800, 900],
      description: "文字の太さ",
    },
    fontFamily: {
      type: "string",
      description: "テキスト時計のフォント",
    },
    fontSizeAdjust: {
      type: "string",
      description: "",
    },
    fontStretch: {
      type: "string",
      description: "",
    },
    fontVariant: {
      type: "string",
      description: "",
    },
    whiteSpace: {
      enum: ["normal", "nowrap", "pre", "pre-wrap", "pre-line", "break-spaces"],
      description: "テキストのスペース、改行の扱い",
    },
  },
  required: ["text"],
};

const LabelPartsTimelineSetting = generateTimelineSettingSchema(
  LabelPartsIndividualSetting
);

export const LabelPartsDefinitions: JSONSchema7["definitions"] = {
  LabelPartsSetting,
  LabelPartsIndividualSetting,
  LabelPartsTimelineSetting,
};
