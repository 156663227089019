import React, { useMemo } from "react";

import Dots from "components/clock/Dots";
import { useTick } from "hooks";

import { getCoordinator } from "./module";
import {
  FactorDotClockPartsSetting,
  V1SchemaFactorCoordinatorSetting,
} from "./type";

interface FactorDotClockPartsProps {
  setting: FactorDotClockPartsSetting;
}

const FactorDotClockParts: React.FC<FactorDotClockPartsProps> = ({
  setting,
}) => {
  const {
    clock: { type, color = "#fff", duration, delay, dotScale, dotType },
  } = setting;

  const coordinator: V1SchemaFactorCoordinatorSetting = {
    type: setting.clock["coordinator:type"],
    sort: setting.clock["coordinator:sort"],
    merge: setting.clock["coordinator:merge"],
  };
  const time = useTick();
  let num: number;
  switch (type) {
    case "Year":
      num = time.year() % 100;
      break;
    case "Month":
      num = time.month() + 1;
      break;
    case "Day":
      num = time.date();
      break;
    case "Hour":
      num = time.hour();
      break;
    case "Minute":
      num = time.minute();
      break;
    case "Second":
      num = time.second();
      break;
    default:
      num = 0;
      break;
  }

  const cd = useMemo(() => getCoordinator(coordinator), [coordinator]);
  const dots = cd.calc(num);

  return (
    <Dots
      dots={dots}
      color={color}
      transitionDuration={duration}
      transitionDelay={delay}
      dotScale={dotScale}
      dotType={dotType}
    />
  );
};

export default FactorDotClockParts;
2;
