import { flatten } from "lib/utils/utils";

import { RectangularCoordinate, Dot, Radian, DotList } from "./coordinate";
import Factors from "./factorization/Factors";
import { Factorizer } from "./factorization/factorizer";
import { FactorSorter } from "./factorization/factorsorter";

import { FactorCoordinator } from ".";

abstract class AbstractFactorCoordinator implements FactorCoordinator {
  private factorizer: Factorizer;
  private sorter: FactorSorter;

  constructor(factorizer: Factorizer, sorter: FactorSorter) {
    this.factorizer = factorizer;
    this.sorter = sorter;
  }

  public calc = (num: number): DotList => {
    if (num === 0) {
      return new DotList([]);
    }

    const factors = this.sorter.sort(this.factorizer.factorize(num));
    return new DotList(this.calcByFactors(factors));
  };

  private calcByFactors = (factors: Factors): Dot[] => {
    const baseCircle = new Dot(
      RectangularCoordinate.ZERO,
      1,
      Radian.M_PI_2.mul(-1)
    );
    return this.divideCircles(factors, [baseCircle], new Factors());
  };

  private divideCircles = (
    factors: Factors,
    circles: Dot[],
    prevFactors: Factors
  ): Dot[] => {
    const factor = factors.first();
    if (factor == null || factor === 1) {
      return circles;
    }
    const restFactors = factors.shift();

    const dividedCircles: Dot[] = flatten(
      circles.map((circle) =>
        this.divideCircleByFactor(factor, circle, prevFactors)
      )
    );

    const nextPrevFactors = prevFactors.add(factor);

    return this.divideCircles(restFactors, dividedCircles, nextPrevFactors);
  };

  protected abstract divideCircleByFactor(
    factor: number,
    circle: Dot,
    prevFactors: Factors
  ): Dot[];
}

export default AbstractFactorCoordinator;
