import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as T from "components/atoms/Table";
import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";

type GaugePartsDocumentProps = {
  className?: string;
  id?: string;
};

const GaugePartsDocument: React.FC<GaugePartsDocumentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Gauge
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>ゲージの設定です。</S.P>
          <S.P>
            ゲージに外枠をつけたい場合は、
            <Link smooth to="#ClockPartsCommonSetting">
              Clock Parts Common Setting
            </Link>
            から枠線の設定を追加してください。
          </S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-type`}>
                    <S.ListItemLabel>type</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#GaugePartsType">
                        GaugePartsType
                      </Link>
                    </S.ListItemValidation>
                    <S.Required />
                  </AnchorContainer>
                  <S.ListItemDescription>ゲージのタイプ</S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-divisor`}>
                    <S.ListItemLabel>divisor</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ゲージの繰り返し単位
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    設定されない場合、とゲージの最大値は
                    <S.Code>
                      <Link smooth to={`#${id}-type`}>
                        type
                      </Link>
                    </S.Code>
                    ごとの最大値となります。設定された場合、ゲージの値はこの値で割ったあまりの数になります。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-color`}>
                    <S.ListItemLabel>color</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>ゲージの色</S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;#fff&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-duration`}>
                    <S.ListItemLabel>duration</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ゲージのアニメーション時間。単位はミリ秒。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-delay`}>
                    <S.ListItemLabel>delay</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ゲージのアニメーションの遅延時間。単位はミリ秒。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>0</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <DocumentClock
                partsSetting={{
                  type: "Gauge",
                  common: {
                    x: "0%",
                    y: "0%",
                    width: "80%",
                    height: "10%",
                    borderColor: "#fff",
                    borderWidth: "2h",
                    rotate: 0,
                  },
                  clock: {
                    type: "Minute",
                    divisor: 10,
                    color: "#fff",
                    duration: 300,
                    delay: 0,
                  },
                }}
              />
            </S.Contents>
            <S.Contents>
              <T.Container id="GaugePartsType">
                <T.Title>GaugePartsType</T.Title>
                <T.Table>
                  <T.Row>
                    <T.Header>Day</T.Header>
                    <T.Data>１日の秒数（86400秒）を元にゲージ表示</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Hour</T.Header>
                    <T.Data>１時間の秒数（3600秒）を元にゲージ表示</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Minute</T.Header>
                    <T.Data>１分の秒数（3600秒）を元にゲージ表示</T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(GaugePartsDocument);
