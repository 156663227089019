import { Coordinator, SimpleFactorCoordinator } from "models/coordinator";
import ClassicFactorCoordinator from "models/coordinator/ClassicFactorCoordinator";
import { ConstantFactorizer } from "models/coordinator/factorization/factorizer";
import { FactorSorter } from "models/coordinator/factorization/factorsorter";
import {
  AscendingSimpleFactorSorter,
  ClassicSimpleFactorSorter,
  DescendingSimpleFactorSorter,
} from "models/coordinator/factorization/factorsorter/SimpleFactorSorter";

import { defaultCoordinatorSetting } from "./setting";
import { V1SchemaFactorCoordinatorSetting } from "./type";

/**
 * Coordinator設定からCoordinatorを取得する
 */
export const getCoordinator = (
  options: V1SchemaFactorCoordinatorSetting = defaultCoordinatorSetting
): Coordinator => {
  const {
    type = defaultCoordinatorSetting.type,
    sort = defaultCoordinatorSetting.sort,
    merge = defaultCoordinatorSetting.merge,
  } = options;

  let coordinatorClass:
    | typeof ClassicFactorCoordinator
    | typeof SimpleFactorCoordinator;
  switch (type) {
    case "ClassicFactorCoordinator":
      coordinatorClass = ClassicFactorCoordinator;
      break;

    case "SimpleFactorCoordinator":
      coordinatorClass = SimpleFactorCoordinator;
      break;
  }

  const factorizer = new ConstantFactorizer();
  const mergeFactors = merge.map((num) => factorizer.factorize(num));

  let sorter: FactorSorter;
  switch (sort) {
    case "ClassicSimpleFactorSorter":
      sorter = new ClassicSimpleFactorSorter(mergeFactors);
      break;
    case "AscendingSimpleFactorSorter":
      sorter = new AscendingSimpleFactorSorter(mergeFactors);
      break;
    case "DescendingSimpleFactorSorter":
      sorter = new DescendingSimpleFactorSorter(mergeFactors);
      break;
  }

  return new coordinatorClass(factorizer, sorter);
};
