import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import { defaultDemoClockSetting } from "clock/setting";
import DocumentClock, {
  StyledDocumentClock,
} from "components/molecules/DocumentClock";

import SettingDocumentContent from "./SettingDocumentContent";
import SettingDocumentMenu from "./SettingDocumentMenu";

type ClockDocumentProps = {
  className?: string;
};

const SettingDocumentPage: React.FC<ClockDocumentProps> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Helmet>
        <title>{"clock-f / Document"}</title>
      </Helmet>
      <ClockGrid>
        <DocumentClock setting={defaultDemoClockSetting} />
      </ClockGrid>
      <MenuGrid>
        <SettingDocumentMenu />
      </MenuGrid>
      <ContentsGrid>
        <SettingDocumentContent />
      </ContentsGrid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-left: env(safe-area-inset-left, 0);
  padding-right: env(safe-area-inset-right, 0);
  @media screen and (min-width: 1024px) {
    display: grid;

    grid-template-rows: auto 1fr;
    grid-template-columns: 280px 1fr;

    grid-template-areas:
      "menu clock"
      "menu contents";
  }
`;

const ClockGrid = styled.div`
  @media screen and (min-width: 1024px) {
    grid-area: clock;

    width: 100%;
    margin-bottom: 32px;
  }
`;

const MenuGrid = styled.div`
  @media screen and (min-width: 1024px) {
    grid-area: menu;

    width: 280px;
    position: fixed;
    height: 100vh;
    border-right: 1px solid rgb(227, 232, 238);
  }
`;

const ContentsGrid = styled.div`
  @media screen and (min-width: 1024px) {
    grid-area: contents;

    width: 100%;
    display: flex;
    justify-content: center;
  }

  ${StyledDocumentClock} {
    @media screen and (max-width: 1024px) {
      margin-left: -24px;
      margin-right: -24px;
    }
  }
`;

export default React.memo(SettingDocumentPage);
