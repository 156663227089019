import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";

type ClockDocumentContentProps = {
  className?: string;
  id?: string;
};

const ClockSettingDocument: React.FC<ClockDocumentContentProps> = ({
  className,
  id,
}) => {
  return (
    <Section className={className} id={id}>
      <Section.Header>
        <Link smooth to={`#${id}`}>
          Clock Setting
        </Link>
      </Section.Header>
      <Section.Content>
        <S.P>clock-fの時計全体の設定です。</S.P>
      </Section.Content>
      <Section.PaneContent>
        <Section.ContentMain>
          <S.ItemList>
            <S.ItemListHeader>Properties</S.ItemListHeader>
            <S.List>
              <S.ListItem>
                <AnchorContainer id={`${id}-title`}>
                  <S.ListItemLabel>title</S.ListItemLabel>
                  <S.ListItemValidation>string</S.ListItemValidation>
                </AnchorContainer>
                <S.ListItemDescription>
                  時計の名前。時計リストなどで判別しやすい名前をつけてください。
                </S.ListItemDescription>
                <S.ListItemDescription>
                  デフォルト <S.Code>&quot;&quot;</S.Code>
                </S.ListItemDescription>
              </S.ListItem>

              <S.ListItem>
                <AnchorContainer id={`${id}-background`}>
                  <S.ListItemLabel>background</S.ListItemLabel>
                  <S.ListItemValidation>
                    <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/background">
                      css.background
                    </S.A>
                  </S.ListItemValidation>
                </AnchorContainer>
                <S.ListItemDescription>
                  時計の背景。CSSの<S.Code>background</S.Code>
                  に用いることができる値なら、何でも適用可能。
                </S.ListItemDescription>
                <S.ListItemDescription>
                  デフォルト <S.Code>&quot;#000&quot;</S.Code>
                </S.ListItemDescription>
              </S.ListItem>

              <S.ListItem>
                <AnchorContainer id={`${id}-aspectRatio`}>
                  <S.ListItemLabel>aspectRatio</S.ListItemLabel>
                  <S.ListItemValidation>number</S.ListItemValidation>
                </AnchorContainer>
                <S.ListItemDescription>
                  時計領域のアスペクト比（横幅 /
                  縦幅）。値を指定することで、どのような画面サイズでも、同じ見た目を再現することができます
                  <sup>※</sup>。
                  値を指定しなかった場合、その環境ごとの画面サイズの縦横比が適用されるため、表示が崩れる可能性があります。時計の共有を考えているならば設定することを勧めます。
                </S.ListItemDescription>
                <S.ListItemDescription>
                  ※
                  背景に画像を用いた場合は、画像と時計パーツの位置関係が崩れることがあります。
                </S.ListItemDescription>
              </S.ListItem>

              <S.ListItem>
                <AnchorContainer id={`${id}-parts`}>
                  <S.ListItemLabel>parts</S.ListItemLabel>
                  <S.ListItemValidation>
                    <Link smooth to="#ClockPartsSetting">
                      ClockPartsSetting[]
                    </Link>
                  </S.ListItemValidation>
                </AnchorContainer>
                <S.ListItemDescription>
                  時計を構成するパーツの設定。パーツの設定については、各パーツのドキュメントを参照してください。
                </S.ListItemDescription>
                <S.ListItemDescription>
                  デフォルト <S.Code>[]</S.Code>
                </S.ListItemDescription>
              </S.ListItem>
            </S.List>
          </S.ItemList>
        </Section.ContentMain>
        <Section.ContentSub>
          <S.Contents>
            <DocumentClock
              setting={{
                title: "clock-f setting sample",
                background: "#333",
                aspectRatio: 1,
                parts: [
                  {
                    type: "Label",
                    common: {
                      background: "lightgray",
                    },
                    clock: {
                      text: "lightgray area\n=\nclock area",
                      color: "#333",
                      fontSize: 0.1,
                    },
                  },
                ],
              }}
              editorHeight={340}
              editerOpen
            />
          </S.Contents>
        </Section.ContentSub>
      </Section.PaneContent>
    </Section>
  );
};

export default React.memo(ClockSettingDocument);
