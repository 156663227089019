import { produce } from "immer";

import {
  V1SchemaFactorCoordinatorSetting,
  FactorDotClockPartsSetting,
} from "./type";

export const defaultCoordinatorSetting: V1SchemaFactorCoordinatorSetting = {
  type: "ClassicFactorCoordinator",
  sort: "ClassicSimpleFactorSorter",
  merge: [4],
};

export const defaultFactorDotClockPartsSetting: FactorDotClockPartsSetting = {
  type: "FactorDotClock",
  common: {
    x: "0%",
    y: "0%",
    width: "100%",
    height: "100%",
    rotate: 0,
  },
  clock: {
    type: "Second",
    color: "#ffffff",
    duration: 300,
    delay: 0,
    dotScale: 0.8,
    dotType: "Circle",
    coordinator: defaultCoordinatorSetting,
  },
};

export const factorDotClockSettingCC: FactorDotClockPartsSetting = produce(
  defaultFactorDotClockPartsSetting,
  (draft) => {
    draft.clock.coordinator = {
      type: "ClassicFactorCoordinator",
      sort: "ClassicSimpleFactorSorter",
      merge: [4],
    };
  }
);

export const factorDotClockSettingCA: FactorDotClockPartsSetting = produce(
  defaultFactorDotClockPartsSetting,
  (draft) => {
    draft.clock.coordinator = {
      type: "ClassicFactorCoordinator",
      sort: "AscendingSimpleFactorSorter",
      merge: [4],
    };
  }
);

export const factorDotClockSettingCD: FactorDotClockPartsSetting = produce(
  defaultFactorDotClockPartsSetting,
  (draft) => {
    draft.clock.coordinator = {
      type: "ClassicFactorCoordinator",
      sort: "DescendingSimpleFactorSorter",
      merge: [4],
    };
  }
);

export const factorDotClockSettingSC: FactorDotClockPartsSetting = produce(
  defaultFactorDotClockPartsSetting,
  (draft) => {
    draft.clock.coordinator = {
      type: "SimpleFactorCoordinator",
      sort: "ClassicSimpleFactorSorter",
      merge: [4],
    };
  }
);

export const factorDotClockSettingSA: FactorDotClockPartsSetting = produce(
  defaultFactorDotClockPartsSetting,
  (draft) => {
    draft.clock.coordinator = {
      type: "SimpleFactorCoordinator",
      sort: "AscendingSimpleFactorSorter",
      merge: [4],
    };
  }
);

export const factorDotClockSettingSD: FactorDotClockPartsSetting = produce(
  defaultFactorDotClockPartsSetting,
  (draft) => {
    draft.clock.coordinator = {
      type: "SimpleFactorCoordinator",
      sort: "DescendingSimpleFactorSorter",
      merge: [4],
    };
  }
);
