import React from "react";
import { useLocation } from "react-router-dom";

import { ClockSetting } from "clock";
import { useWindowSize } from "hooks";

import ClockPageTemplate from "./ClockPageTemplate";

/**
 * v1のURLParamsClockの後方互換性のためのページ
 */
const V1URLParamsClockPage: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const color = params.get("color") || "ffffff";
  const dotColor = params.get("dot-color") || color;
  const background = params.get("background-color") || "000000";

  const windowSize = useWindowSize();
  const aspectRatio = windowSize.width / windowSize.height;

  // 縦長横長で配置が異なる
  const size =
    aspectRatio > 1
      ? round(Math.min(0.5, aspectRatio / 4) * 2)
      : round(Math.min(0.5, aspectRatio));
  const fontSize = round((size / 4) * 0.9);
  const setting: ClockSetting = {
    title: "clock-f v1 clock",
    background: `#${background}`,
    aspectRatio,
    parts: [
      {
        type: "TextClock",
        common: {
          x: `${aspectRatio > 1 ? -0.25 : 0}`,
          y: `${aspectRatio > 1 ? 0 : -0.25}`,
          width: `${aspectRatio > 1 ? 0.5 : 1}`,
          height: `${aspectRatio > 1 ? 1 : 0.5}`,
          rotate: 0,
        },
        clock: {
          type: "HourMinute",
          color: `#${color}`,
          fontSize,
          fontWeight: 700,
          delay: 300,
        },
      },
      {
        type: "FactorDotClock",
        common: {
          x: `${aspectRatio > 1 ? 0.25 : 0}`,
          y: `${aspectRatio > 1 ? 0 : 0.25}`,
          width: `${aspectRatio > 1 ? 0.5 : 1}`,
          height: `${aspectRatio > 1 ? 1 : 0.5}`,
          rotate: 0,
        },
        clock: {
          type: "Second",
          color: `#${dotColor}`,
          "coordinator:type": "ClassicFactorCoordinator",
          "coordinator:sort": "ClassicSimpleFactorSorter",
          "coordinator:merge": [4],
          duration: 300,
        },
      },
    ],
  };

  return <ClockPageTemplate setting={setting} />;
};

const round = (num: number): number => {
  return Math.round(num * 100) / 100;
};

export default V1URLParamsClockPage;
