import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { DotList, RectangularCoordinate } from "models/coordinator/coordinate";

import { Centered } from "../layout";

type DotType = "Circle" | "Square";
interface DotsProps {
  dots: DotList;
  color: string;
  transitionDuration?: number;
  transitionDelay?: number;
  reservedDotsCount?: number;
  dotScale?: number;
  dotType?: DotType;
}

const Dots: React.FC<DotsProps> = ({
  dots,
  color,
  transitionDuration = 300,
  transitionDelay = 0,
  reservedDotsCount = 60,
  dotScale = 0.8,
  dotType = "Circle",
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [opacity, setOpacity] = useState(0);

  let maxSize: number;
  if (ref.current) {
    const width: number = ref.current.clientWidth;
    const height: number = ref.current.clientHeight;
    maxSize = (Math.min(width, height) / 2) * 0.85;
  } else {
    maxSize = 0;
  }

  // 初期表示が中心から広がる感じでダサいので、それを隠蔽するための処理
  useEffect(() => {
    // マウントされるタイミングが微妙なのでsetTimeoutで刻んでチェックする
    const checkAndShow = () => {
      if (ref.current && ref.current.clientWidth > 0) {
        setOpacity(1);
      } else {
        setTimeout(checkAndShow, 10);
      }
    };
    checkAndShow();
  }, []);

  // reservedDotsCount に足りない分を埋める
  dots.fill(reservedDotsCount);

  return (
    <Wrapper
      ref={ref}
      opacity={opacity}
      transitionDelay={transitionDelay + transitionDuration} // 最初に表示されるまでのディレイ
    >
      <CoordinateSystem
        transitionDuration={transitionDuration}
        transitionDelay={transitionDelay}
      >
        {dots.list.map((dot, i) => (
          <ControlPoint key={i} coordinate={dot.center.mul(maxSize)}>
            <CircleDot
              size={maxSize * dot.radius * 2 * dotScale}
              color={color}
              visible={dot.visible}
              dotType={dotType}
            />
          </ControlPoint>
        ))}
      </CoordinateSystem>
    </Wrapper>
  );
};

const Wrapper = styled(Centered)<{ opacity: number; transitionDelay: number }>`
  width: 100%;
  height: 100%;
  opacity: ${({ opacity }) => opacity};
  transition-property: opacity;
  transition-duration: 300ms;
  transition-delay: ${({ transitionDelay }) => transitionDelay}ms;
  transition-timing-function: ease-in-out;
`;

type CoordinateSystemProps = {
  transitionDuration: number;
  transitionDelay: number;
};

const CoordinateSystem = styled.div<CoordinateSystemProps>`
  width: 0;
  height: 0;
  overflow: visible;
  position: relative;
  margin: auto;

  * {
    transition-property: width, height, top, left, border-radius,
      background-color;
    transition-duration: ${({ transitionDuration }) => transitionDuration}ms;
    transition-delay: ${({ transitionDelay }) => transitionDelay}ms;
    transition-timing-function: ease-in-out;
  }
`;

interface ControlPointProps {
  coordinate: RectangularCoordinate;
}

const ControlPoint: React.FC<ControlPointProps> = ({
  coordinate,
  children,
}) => {
  return (
    <div
      style={{
        position: "relative",
        width: 0,
        height: 0,
        overflow: "visible",
        left: `${coordinate.x}px`,
        top: `${coordinate.y}px`,
      }}
    >
      {children}
    </div>
  );
};

interface DotProps {
  size: number;
  color: string;
  visible: boolean;
  dotType: DotType;
}

const CircleDot: React.FC<DotProps> = ({ size, color, visible, dotType }) => {
  const borderRadius = dotType === "Circle" ? size / 2 : 0;
  return (
    <div
      style={{
        position: "absolute",
        width: size,
        height: size,
        left: -size / 2,
        top: -size / 2,
        borderRadius,
        backgroundColor: color,
        opacity: visible ? 1 : 0,
      }}
    />
  );
};

export default Dots;
