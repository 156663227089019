import React, { useCallback, useState } from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import { ClockSetting, emptyClockSetting } from "clock";
import Clock from "components/clock/Clock";
import {
  ClockButton,
  DeleteButton,
  SaveButton,
  EditClockButton,
} from "components/molecules/IconButton";
import { SettingContextProvider } from "hooks";

type SlotClockPorps = {
  className?: string;
  slotName?: string;
  setting: ClockSetting | null;
  onClickSetDefaultClock?: () => void;
  onClickEditClock?: () => void;
  onClickDelete?: () => void;
  onClickSave?: () => void;
};

const SlotClock: React.FC<SlotClockPorps> = ({
  className,
  slotName,
  setting: baseSetting,
  onClickSetDefaultClock,
  onClickEditClock,
  onClickDelete,
  onClickSave,
}) => {
  const [isDisplayActions, setIsDisplayActions] = useState(false);
  const showActions = useCallback(() => setIsDisplayActions(true), []);
  const hideActions = useCallback(() => setIsDisplayActions(false), []);
  const isNewSlot = !baseSetting;
  const setting = baseSetting || emptyClockSetting;
  const existsSetting = baseSetting !== null;

  return (
    <SettingContextProvider setting={setting}>
      <Wrapper className={className}>
        <SlotName>
          <b>{slotName}</b>
          <br />
          <span style={{ display: "inline-block" }}>{setting.title}</span>
        </SlotName>
        <ClockWrapper
          style={{ background: setting.background || "#000" }}
          onClick={showActions}
          onMouseEnter={showActions}
          onMouseLeave={hideActions}
        >
          <LazyLoad height={200} overflow unmountIfInvisible>
            <Clock />
            <ActionContainer active={isDisplayActions} empty={isNewSlot}>
              {isDisplayActions && (
                <>
                  <ButtonContainer>
                    {onClickSetDefaultClock && existsSetting && (
                      <ClockButton
                        // label="Set to Default"
                        label="ロード（デフォルトで表示する時計に設定）"
                        color="#fff"
                        onClick={onClickSetDefaultClock}
                      />
                    )}
                    {onClickEditClock && (
                      <EditClockButton
                        // label="Edit Clock"
                        label={isNewSlot ? "時計の新規作成" : "時計の編集"}
                        color="#fff"
                        onClick={onClickEditClock}
                      />
                    )}
                    {onClickDelete && existsSetting && (
                      <DeleteButton
                        // label="Edit Clock"
                        label="時計を削除"
                        color="#fff"
                        onClick={onClickDelete}
                      />
                    )}
                    {onClickSave && !existsSetting && (
                      <SaveButton
                        // label="Save"
                        label="表示中の時計を保存"
                        color="#fff"
                        onClick={onClickSave}
                      />
                    )}
                  </ButtonContainer>
                </>
              )}
            </ActionContainer>
          </LazyLoad>
        </ClockWrapper>
      </Wrapper>
    </SettingContextProvider>
  );
};

const Wrapper = styled.div`
  width: 354px;

  @media screen and (max-width: 1024px) {
    width: 240px;
  }
`;

const SlotName = styled.div`
  padding: 2px;
  color: #fff;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ClockWrapper = styled.div`
  position: relative;
  width: 354px;
  height: 200px;

  @media screen and (max-width: 1024px) {
    width: 240px;
    height: 136px;
  }
`;

const ActionContainer = styled.div<{ active: boolean; empty: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: ${({ active }) =>
    active ? "rgba(0, 0, 0, 0.95)" : "transparent"};
  border: ${({ active, empty }) =>
    active
      ? "1px solid rgba(255, 255, 255, 1)"
      : empty
      ? "1px dashed rgba(255, 255, 255, 0.5)"
      : "1px solid rgba(255, 255, 255, 0.5)"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    padding: 8px;
    margin: 0 4px;
  }
`;

export const EmptySlotClock = styled(Wrapper)`
  height: 0;
`;

export default React.memo(SlotClock);
