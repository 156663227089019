import React from "react";
import styled from "styled-components";

import { Centered } from "components/layout";
import { useClockArea, useTick } from "hooks";
import { generateTransitionStyle } from "lib/style";
import { toPx } from "lib/utils/size";

import { AnalogClockPartsSetting } from "./type";

interface AnalogClockPartsProps {
  setting: AnalogClockPartsSetting;
}

const AnalogClockParts: React.FC<AnalogClockPartsProps> = ({
  setting: {
    clock: { type, handWidth = "2h", color = "#fff", delay = 300 },
  },
}) => {
  const time = useTick();
  const areaSize = useClockArea();
  const hw = toPx(handWidth, areaSize, "xy");

  let displayHour: boolean;
  let displayMinute: boolean;
  let displaySecond: boolean;
  switch (type) {
    case "HourMinuteSecond":
      [displayHour, displayMinute, displaySecond] = [true, true, true];
      break;
    case "HourMinute":
      [displayHour, displayMinute, displaySecond] = [true, true, false];
      break;
    case "Hour":
      [displayHour, displayMinute, displaySecond] = [true, false, false];
      break;
    case "Minute":
      [displayHour, displayMinute, displaySecond] = [false, true, false];
      break;
    case "Second":
      [displayHour, displayMinute, displaySecond] = [false, false, true];
      break;
  }

  const secondInDay = time.hour() * 3600 + time.minute() * 60 + time.second();
  const hourAngle = 90 + ((secondInDay % (3600 * 12)) / (3600 * 12)) * 360;
  const minuteAngle = 90 + ((secondInDay % 3600) / 3600) * 360;
  const secondAngle = 90 + ((secondInDay % 60) / 60) * 360;

  const handWrapperStyle: React.CSSProperties = {
    height: hw,
    transitionDelay: `${delay}ms`,
  };
  const handStyle: React.CSSProperties = {
    backgroundColor: color,
    borderRadius: `calc(${hw} / 2)`,
    transition: generateTransitionStyle(["background-color"]),
  };

  return (
    <Wrapper>
      <Centered>
        {displayHour && (
          <HandWrapper
            style={{
              ...handWrapperStyle,
              width: "75%",
              transform: [
                "translate3d(-50%, -50%, 0)",
                `rotate(${hourAngle}deg)`,
              ].join(" "),
            }}
          >
            <Hand style={handStyle}></Hand>
          </HandWrapper>
        )}
        {displayMinute && (
          <HandWrapper
            style={{
              ...handWrapperStyle,
              width: "95%",
              transform: [
                "translate3d(-50%, -50%, 0)",
                `rotate(${minuteAngle}deg)`,
              ].join(" "),
            }}
          >
            <Hand style={handStyle}></Hand>
          </HandWrapper>
        )}
        {displaySecond && (
          <HandWrapper
            style={{
              ...handWrapperStyle,
              width: "90%",
              height: `calc(${hw} / 2)`,
              transform: [
                "translate3d(-50%, -50%, 0)",
                `rotate(${secondAngle}deg)`,
              ].join(" "),
            }}
          >
            <Hand
              style={{ ...handStyle, borderRadius: `calc(${hw} / 4)` }}
            ></Hand>
          </HandWrapper>
        )}
      </Centered>
    </Wrapper>
  );
};

const Wrapper = styled(Centered)`
  position: relative;
`;

const HandWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
  width: 100%;
`;
const Hand = styled.div`
  position: absolute;
  height: 100%;
  width: 54%;
`;

export default AnalogClockParts;
