import React from "react";
import styled from "styled-components";

import { ReactComponent as AddIcon } from "assets/images/icon/add.svg";
import { ReactComponent as BookIcon } from "assets/images/icon/book.svg";
import { ReactComponent as ChoiceIcon } from "assets/images/icon/choice.svg";
import { ReactComponent as ClipboardCopiedIcon } from "assets/images/icon/clipboard-copied.svg";
import { ReactComponent as ClipboardIcon } from "assets/images/icon/clipboard.svg";
import { ReactComponent as ClockIcon } from "assets/images/icon/clock.svg";
import { ReactComponent as CloseIcon } from "assets/images/icon/close.svg";
import { ReactComponent as DownArrowIcon } from "assets/images/icon/down-arrow.svg";
import { ReactComponent as FloppyIcon } from "assets/images/icon/floppy.svg";
import { ReactComponent as InfoIcon } from "assets/images/icon/info.svg";
import { ReactComponent as JigsawIcon } from "assets/images/icon/jigsaw.svg";
import { ReactComponent as LeftArrowIcon } from "assets/images/icon/left-arrow.svg";
import { ReactComponent as LinkIcon } from "assets/images/icon/link.svg";
import { ReactComponent as PenIcon } from "assets/images/icon/pen.svg";
import { ReactComponent as QRCodeIcon } from "assets/images/icon/qr-code.svg";
import { ReactComponent as SquaresIcon } from "assets/images/icon/squares.svg";
import { ReactComponent as TrashIcon } from "assets/images/icon/trash.svg";
import { ReactComponent as ViewDocumentIcon } from "assets/images/icon/view-document.svg";

export type IconType =
  | "add"
  | "view-document"
  | "clipboard"
  | "clipboard-copied"
  | "book"
  | "down-arrow"
  | "pen"
  | "squares"
  | "book"
  | "jigsaw"
  | "info"
  | "clock"
  | "trash"
  | "close"
  | "floppy"
  | "choice"
  | "link"
  | "left-arrow"
  | "qr-code";

type SVGComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

const icons: { [key in IconType]: SVGComponent } = {
  add: AddIcon,
  "view-document": ViewDocumentIcon,
  clipboard: ClipboardIcon,
  "clipboard-copied": ClipboardCopiedIcon,
  book: BookIcon,
  "down-arrow": DownArrowIcon,
  "left-arrow": LeftArrowIcon,
  pen: PenIcon,
  squares: SquaresIcon,
  jigsaw: JigsawIcon,
  info: InfoIcon,
  clock: ClockIcon,
  trash: TrashIcon,
  close: CloseIcon,
  floppy: FloppyIcon,
  choice: ChoiceIcon,
  link: LinkIcon,
  "qr-code": QRCodeIcon,
};

export type SVGIconProps = {
  className?: string;
  type: IconType;
  size?: number;
};

const SVGIcon: React.FC<SVGIconProps> = ({ className, type, size = 18 }) => {
  const Icon = icons[type];
  return (
    <Wrapper className={className} size={size}>
      <Icon />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ size: number }>`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export default SVGIcon;
