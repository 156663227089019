import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

import SVGIcon from "components/atoms/SVGIcon";

type SettingDocumentMenuProps = {
  className?: string;
};

const SettingDocumentMenu: React.FC<SettingDocumentMenuProps> = ({
  className,
}) => {
  return (
    <Wrapper className={className}>
      <MenuList>
        <MenuItem>
          <NavLink smooth to="/">
            <Icon type="left-arrow" /> clock-f
          </NavLink>
        </MenuItem>
        <MenuHeader>CLOCK SETTING</MenuHeader>
        <MenuItem>
          <Link smooth to="#ClockSetting">
            Clock Setting
          </Link>
        </MenuItem>
      </MenuList>
      <MenuHeader>CLOCK PARTS SETTING</MenuHeader>
      <MenuList>
        <MenuItem>
          <Link smooth to="#ClockPartsSetting">
            Clock Parts Setting
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#ClockPartsCommonSetting">
            Clock Parts Common Setting
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#ClockPartsTimelineSetting">
            Clock Parts Timeline Setting
          </Link>
        </MenuItem>
        <MenuHeader>CLOCK PARTS INDIVISUAL SETTING</MenuHeader>
        <MenuItem>
          <Link smooth to="#FactorDotClock">
            Factor Dot Clock
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#SimpleDotClock">
            Simple Dot Clock
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#TextClock">
            Text Clock
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#AnalogClock">
            Analog Clock
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#Count">
            Count
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#Gauge">
            Gauge
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#Label">
            Label
          </Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="#Image">
            Image
          </Link>
        </MenuItem>
        <MenuHeader>---</MenuHeader>
        <MenuItem>
          <Link smooth to="#TextPartsCommonSetting">
            Text Parts Common Setting
          </Link>
        </MenuItem>
      </MenuList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
`;
const MenuHeader = styled.h3`
  font-size: 12px;
  font-weight: bold;
`;

const MenuList = styled.ul``;
const MenuItem = styled.li`
  line-height: 2;
  font-weight: 500;

  > a {
    color: rgb(105, 115, 134);
  }
`;

const Icon = styled(SVGIcon)``;
const NavLink = styled(Link)`
  display: flex;
  align-items: center;

  ${Icon} {
    margin-right: 8px;
  }
`;

export default React.memo(SettingDocumentMenu);
