import { JSONSchema7, JSONSchema7Definition } from "json-schema";

import { generateTimelineSettingSchema } from "lib/schema";

const CountPartsSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "カウントパーツの設定",
  properties: {
    type: {
      title: "パーツタイプ",
      enum: ["Count"],
    },
    common: { $ref: "#/definitions/ClockPartsCommonSetting" },
    clock: { $ref: "#/definitions/CountPartsIndividualSetting" },
    timeline: { $ref: "#/definitions/CountPartsTimelineSetting" },
  },
  required: ["type", "common", "clock"],
};

const CountPartsIndividualSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "カウントパーツの個別設定",
  properties: {
    type: {
      $ref: "#/definitions/CountPartsType",
      default: "Day",
    },
    direction: {
      enum: ["Both", "CountDown", "CountUp"],
      description: "カウントする方向",
    },
    target: {
      type: "string",
      format: "date-time",
      // pattern:
      //   "^[0-9]{4}[\\/\\-](0[1-9]|1[0-2])[\\/\\-](0[1-9]|[12][0-9]|3[01]) ([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$",
      description: "カウントのターゲット日時",
    },
    text: {
      type: "string",
      description: "全体のテキスト {CLOCK}は時計の文字列で置換されます",
    },
    color: {
      type: "string",
      description: "カウントパーツの文字色",
      default: "#fff",
    },
    fontSize: {
      type: "number",
      description: "カウントパーツの文字サイズ（高さを１とした場合のサイズ）",
      default: 0.2,
    },
    fontWeight: {
      enum: ["normal", "bold", 100, 200, 300, 400, 500, 600, 700, 800, 900],
      description: "カウントパーツの文字の太さ",
      default: "normal",
    },
    fontFamily: {
      type: "string",
      description: "テキスト時計のフォント",
    },
    fontSizeAdjust: {
      type: "string",
      description: "",
    },
    fontStretch: {
      type: "string",
      description: "",
    },
    fontVariant: {
      type: "string",
      description: "",
    },
    whiteSpace: {
      enum: ["normal", "nowrap", "pre", "pre-wrap", "pre-line", "break-spaces"],
      description: "テキストのスペース、改行の扱い",
    },
    delay: {
      type: "number",
      description: "カウントパーツの切り替え遅延時間",
      minimum: 0,
      maximum: 1000,
      default: 300,
    },
  },
  required: ["type", "target"],
};

const CountPartsTimelineSetting = generateTimelineSettingSchema(
  CountPartsIndividualSetting
);

const CountPartsType: JSONSchema7Definition = {
  enum: [
    "Year",
    "Week",
    "Day",
    "Hour",
    "Minute",
    "Second",
    "HourMinute",
    "HourMinuteCutOff",
    "HourMinuteSecond",
    "HourMinuteSecondCutOff",
    "RelativeTime",
  ],
  description: "カウントパーツのタイプ",
};

export const CountPartsDefinitions: JSONSchema7["definitions"] = {
  CountPartsSetting,
  CountPartsIndividualSetting,
  CountPartsTimelineSetting,
  CountPartsType,
};
