import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import * as S from "components/atoms/style";
import Section from "components/layout/Section";

type ClockDocumentContentProps = {
  className?: string;
};

const ClockDocumentContent: React.FC<ClockDocumentContentProps> = ({
  className,
}) => {
  return (
    <Wrapper className={className}>
      <StyledSection className={className} id="About">
        <Section.Header>About clock-f</Section.Header>
        <PaneContent>
          <Section.ContentMain>
            <S.P>
              clock-f は、
              <Link to="/docs/setting/#FactorDotClock">
                可視化した素因数分解（Prime Factorization）
              </Link>
              を中心とした、様々なパーツを組み合わせて自分だけの時計を作ることができるWEBアプリケーションです。
            </S.P>
            <S.P>
              2014年にリリースした
              <a
                href="http://touchlab.jp/2014/01/clock_f_iphone/"
                target="_blank"
                rel="noreferrer"
              >
                iOSアプリ
              </a>
              のWEBアプリケーション版で、カスタマイズの自由度を大きく向上させ、利用できるプラットフォームを問わずに利用できる形に作り直しました。
            </S.P>
          </Section.ContentMain>
        </PaneContent>
      </StyledSection>

      <StyledSection className={className} id="GettingStarted">
        <PaneContent>
          <Section.Header>Getting Started</Section.Header>
        </PaneContent>
        <PaneContent>
          <Section.ContentMain id="GettingStarted-Main">
            <SectionSubHeader>
              <Link to="/">メイン画面</Link>
            </SectionSubHeader>
            <S.P>作成した時計を表示することができます。</S.P>
            <S.P>画面をクリックすると、</S.P>
            <ul>
              <li>Edit Clock</li>
              <li>Clock List</li>
              <li>Document</li>
              <li>Copy Share URL</li>
              <li>QRCode</li>
            </ul>
            <S.P>のメニューから操作を行うことができます。</S.P>
          </Section.ContentMain>
        </PaneContent>
        <PaneContent>
          <Section.ContentMain id="GettingStarted-Edit">
            <Section.Content>
              <SectionSubHeader>
                <Link to="/edit/">時計編集画面</Link>
              </SectionSubHeader>
              <S.P>時計のカスタマイズができます。</S.P>
              <S.P>
                現在のところ、設定の変更は、JSON形式の設定を直接変更することのみ対応しています。各時計パーツの設定については、
                <Link to="/docs/setting/">Clock Setting</Link>を参照ください。
              </S.P>
            </Section.Content>
          </Section.ContentMain>
        </PaneContent>
      </StyledSection>
      <StyledSection className={className} id="Share">
        <PaneContent id="Share-Share">
          <Section.Header>作った時計をシェアする</Section.Header>
        </PaneContent>
        <PaneContent>
          <Section.ContentMain>
            <S.P>
              メイン画面のCopy Share
              URLメニューやQRCodeでカスタム時計URLのコピーやQRCode表示をすることができます。
              また、時計編集画面のInfoタブでは短縮形式のURLの発行が行えます。
            </S.P>
          </Section.ContentMain>
        </PaneContent>
      </StyledSection>
      <StyledSection className={className} id="Use">
        <PaneContent>
          <Section.Header>普段使いする</Section.Header>
        </PaneContent>
        <PaneContent>
          <Section.ContentMain id="Use-iPhone">
            <SectionSubHeader>iPhoneを置き時計として利用する</SectionSubHeader>
            <ol>
              <li>
                Safariで時計のカスタム時計リンクにアクセスし、Safariのメニューから「ホーム画面に追加」をタップ
              </li>
              <li>iPhoneの自動ロック設定を「なし」に設定する</li>
              <li>
                ホーム画面で、保存されたアプリアイコンをクリックして起動する
              </li>
            </ol>
            <S.P>
              カスタム時計リンクをホーム画面に追加すると、フルスクリーン表示のスタンドアロンモードで起動するようになっています。
            </S.P>
          </Section.ContentMain>
        </PaneContent>
        <PaneContent>
          <Section.ContentMain id="Use-Screensaver">
            <SectionSubHeader>Macのデスクトップに配置する</SectionSubHeader>
            <S.P>
              <a
                href="https://www.tynsoe.org/v2/geektool/"
                target="_blank"
                rel="noreferrer"
              >
                GeekTool
              </a>
              などを利用してデスクトップに配置します。GeekToolの場合、Web
              Geekletを追加し、カスタム時計リンクを設定すればOK。
            </S.P>
          </Section.ContentMain>
        </PaneContent>
        <PaneContent>
          <Section.ContentMain id="Use-Screensaver">
            <SectionSubHeader>
              Macのスクリーンセーバーに設定する
            </SectionSubHeader>
            <S.P>
              <a
                href="https://github.com/liquidx/webviewscreensaver"
                target="_blank"
                rel="noreferrer"
              >
                WebViewScreenSaver
              </a>
              をインストールし、カスタム時計リンクを設定してください。
            </S.P>
          </Section.ContentMain>
        </PaneContent>
      </StyledSection>

      <StyledSection className={className} id="About">
        <Section.Header>Release Note</Section.Header>
        <PaneContent>
          <Section.ContentMain>
            <ul>
              <li>
                <Date>2014/01</Date>
                <Version>v1.0.0</Version>
                <a
                  href="http://touchlab.jp/2014/01/clock_f_iphone/"
                  target="_blank"
                  rel="noreferrer"
                >
                  iOSアプリ
                </a>
                をリリース
              </li>
              <li>
                <Date>2020/12/06</Date>
                <Version>v2.0.0</Version>
                WEBアプリを公開
              </li>
              <li>
                <Date>2023/09/21</Date>
                <Version>v2.1.0</Version>
                設定のタイムライン機能（時間に応じて設定値を変化させる機能）を追加
              </li>
            </ul>
          </Section.ContentMain>
        </PaneContent>
      </StyledSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px 24px;
`;

const StyledSection = styled(Section)`
  margin-bottom: 32px;

  ol {
    list-style-type: decimal;
    line-height: 22px;
    margin-top: 20px;
  }

  ul {
    line-height: 22px;
    margin-top: 20px;
  }

  ol > li {
    margin-left: 16px;
  }
`;
const Date = styled.span`
  display: inline-block;
  font-weight: bold;
  width: 90px;
`;
const Version = styled.span`
  display: inline-block;
  width: 48px;
`;
const PaneContent = styled(Section.PaneContent)`
  margin-bottom: 32px;
`;

const SectionSubHeader = styled.h4``;

export default React.memo(ClockDocumentContent);
