/* eslint-disable @typescript-eslint/no-explicit-any */
import { produce } from "immer";
import { JSONSchema7 } from "json-schema";

import {
  ClockPartsCommonSetting,
  ClockPartsSetting,
  ClockPartsType,
  ClockSetting,
  ClockFSettingSchema,
  emptyClockSetting,
} from "clock";
import {
  AnalogClockPartsIndividualSetting,
  AnalogClockPartsType,
} from "clock/AnalogClock/type";
import {
  CountPartsIndividualSetting,
  CountPartsType,
  CountDirectionType,
} from "clock/Count/type";
import {
  FactorDotClockPartsIndividualSetting,
  FactorDotClockPartsType,
  FactorDotClockCoordinatorType,
  FactorSortOrderType,
} from "clock/FactorDotClock/type";
import { GaugePartsIndividualSetting, GaugePartsType } from "clock/Gauge/type";
import { ImagePartsIndividualSetting } from "clock/Image/type";
import { LabelPartsIndividualSetting } from "clock/Label/type";
import {
  SimpleDotClockPartsIndividualSetting,
  SimpleDotClockPartsType,
  SimpleDotClockCoordinatorType,
} from "clock/SimpleDotClock/type";
import {
  TextClockPartsIndividualSetting,
  TextClockPartsType,
} from "clock/TextClock/type";
import {
  TimelineApplyMethodType,
  TimelineItem,
  TimelineSetting,
} from "clock/type";
import { deflate, inflate } from "lib/compress";
import { transform, invert } from "lib/utils/object";

type KeysOfUnionType<T> = T extends any ? keyof T : never;

// 設定のキー変換表 (後方互換のために値の変更はしない、追加はOK)
const serializeKeyMapping: {
  [key in
    | keyof ClockSetting
    | KeysOfUnionType<ClockPartsSetting>
    | keyof ClockPartsCommonSetting
    | keyof TextClockPartsIndividualSetting
    | keyof SimpleDotClockPartsIndividualSetting
    | keyof FactorDotClockPartsIndividualSetting
    | keyof AnalogClockPartsIndividualSetting
    | keyof LabelPartsIndividualSetting
    | keyof ImagePartsIndividualSetting
    | keyof CountPartsIndividualSetting
    | keyof GaugePartsIndividualSetting
    | keyof TimelineItem<ClockPartsSetting>
    | keyof TimelineSetting<ClockPartsSetting>]: string;
} = {
  // ClockSetting
  title: "tt",
  background: "bg",
  aspectRatio: "ar",
  parts: "cs", // 旧 clocks のためcs
  version: "v",

  // ClockPartsSetting
  type: "t",
  common: "cm",
  clock: "ck",

  // ClockPartsCommonSetting
  x: "x",
  y: "y",
  width: "w",
  height: "h",
  rotate: "r",
  timeline: "tl",
  borderWidth: "bw",
  borderStyle: "bs",
  borderColor: "bc",
  borderRadius: "br",
  filter: "f",
  blend: "b",
  opacity: "op",

  // TextClockPartsSetting
  // type: "t",
  format: "fm",
  color: "cl",
  fontSize: "fs",
  fontWeight: "fw",
  fontFamily: "ff",
  fontSizeAdjust: "fsa",
  fontStretch: "fst",
  fontVariant: "fv",
  whiteSpace: "ws",
  delay: "dl",

  // DotClockPartsSetting
  // type: "t",
  // color: "cl",
  coordinator: "co",
  duration: "d",
  dotScale: "ds",
  dotType: "dt",

  // AnalogClockPartsIndividualSetting
  handWidth: "hw",

  // LabelPartsIndividualSetting
  text: "tx",

  // ImagePartsIndividualSetting
  url: "u",
  objectFit: "of",

  // CountPartsIndividualSetting
  target: "tg",
  direction: "dr",

  // GaugePartsIndividualSetting
  divisor: "dv",

  // CoordinatorSetting
  // type: "t",
  "coordinator:type": "ct",
  "coordinator:sort": "cso",
  "coordinator:merge": "cmg",

  t: "_t",
  values: "vs",
  timingFunction: "tf",
  apply: "a",
};

// 設定の値変換表 (後方互換のために値の変更はしない、追加はOK)
const serializeValueMapping: {
  [key in
    | ClockPartsType
    | TextClockPartsType
    | SimpleDotClockPartsType
    | FactorDotClockPartsType
    | AnalogClockPartsType
    | CountPartsType
    | CountDirectionType
    | GaugePartsType
    | SimpleDotClockCoordinatorType
    | FactorDotClockCoordinatorType
    | FactorSortOrderType
    | TimelineApplyMethodType]: string;
} = {
  SimpleDotClock: "scd",
  FactorDotClock: "sfd",
  TextClock: "tc",
  AnalogClock: "ac",
  Label: "l",
  Image: "i",
  Count: "ct",
  Gauge: "g",

  Year: "yr",
  Month: "mn",
  Day: "d",
  MonthDay: "md",
  YearMonthDay: "ymd",
  Hour: "h",
  Minute: "m",
  Second: "s",
  HourMinute: "hm",
  HourMinuteSecond: "hms",
  DayOfYear: "doy",
  MinuteOfDay: "mod",
  SecondOfDay: "sod",
  SecondOfHour: "soh",
  RelativeTime: "rt",

  Week: "w",
  HourMinuteCutOff: "hmc",
  HourMinuteSecondCutOff: "hmsc",

  Custom: "cs",
  ClassicFactorCoordinator: "cfc",
  SimpleFactorCoordinator: "sfc",
  CircularCoordinator: "cc",
  ClassicSimpleFactorSorter: "csf",
  AscendingSimpleFactorSorter: "asf",
  DescendingSimpleFactorSorter: "dsf",

  // CountDirectionType
  Both: "b",
  CountDown: "cd",
  CountUp: "cu",

  // TimelineApplyMethodType
  merge: "mg",
  spot: "sp",
};

/**
 * 設定オブジェクトをkey,valueを圧縮したオブジェクトに変換する
 */
const compressSetting = (setting: ClockSetting): any => {
  return transform(setting, serializeKeyMapping, serializeValueMapping);
};

/**
 * 圧縮された設定オブジェクトを復元する
 */
const decompressSetting = (compressedSetting: any): ClockSetting => {
  return transform(
    compressedSetting,
    invert(serializeKeyMapping),
    invert(serializeValueMapping)
  );
};

/**
 * 設定をBase64文字列に圧縮・変換する
 * @param setting 時計の設定
 */
export const serializeSetting = (setting: ClockSetting): string => {
  return deflate(JSON.stringify(compressSetting(setting)));
};

/**
 * serializeSettingによって圧縮された設定を復元する
 * @param str serializeSettingによって圧縮された設定
 */
export const deserializeSetting = (str: string): ClockSetting => {
  try {
    return decompressSetting(JSON.parse(inflate(str)));
  } catch (e) {
    console.error("failed deserialize setting");
    return emptyClockSetting;
  }
};

export const generateClockPartsSettingSchema = (
  type: ClockPartsType
): JSONSchema7 =>
  produce(ClockFSettingSchema, (draft) => {
    return {
      ...draft,
      $id: `http://example.com/schemas/${type}.json`,
      required: [],
      properties: undefined,
      $ref: `#/definitions/${type}PartsSetting`,
    } as JSONSchema7;
  });

const validateMapping = (mapping: { [key: string]: string }): boolean => {
  let isValid = true;
  const obj: { [key: string]: string } = {};
  for (const [k, v] of Object.entries(mapping)) {
    if (obj[v] !== undefined) {
      console.error(`duplicate mapping value "${v}" (mapping key "${k}")`);
      isValid = false;
    } else {
      obj[v] = k;
    }
  }

  return isValid;
};

validateMapping(serializeKeyMapping);
validateMapping(serializeValueMapping);
