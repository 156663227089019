import { CircularCoordinator, Coordinator } from "models/coordinator";

import { SimpleDotClockCoordinatorType } from "./type";

/**
 * Coordinator設定からCoordinatorを取得する
 */
export const getCoordinator = (
  coordinator: SimpleDotClockCoordinatorType = "CircularCoordinator"
): Coordinator => {
  switch (coordinator) {
    case "CircularCoordinator":
      return new CircularCoordinator();
  }
};
