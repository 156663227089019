import { range } from "lib/utils/utils";

import Dot from "./Dot";

class DotList {
  private _list: Dot[];

  constructor(list: Dot[]) {
    this._list = list;
  }

  get list(): Dot[] {
    return this._list;
  }

  /**
   * 指定された数までリストの最後のドットを複製して埋める
   * @param count
   */
  fill = (count: number): void => {
    const fillDot =
      this._list.length === 0
        ? Dot.ZERO
        : this._list[this._list.length - 1].clone().setVisible(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fillDots = range(this._list.length, count).map((_) => fillDot);
    this._list = this._list.concat(fillDots);
  };
}

export default DotList;
