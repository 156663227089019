import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useClockArea } from "hooks";
import { useTick } from "hooks/tick";

interface DebugClockProps {
  className?: string;
}

const DebugInfo: React.FC<DebugClockProps> = ({ className }) => {
  const time = useTick();
  const areaSize = useClockArea();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  if (!(params.get("debug") === "true" || params.get("debug") === "1")) {
    return <noscript />;
  }

  return (
    <Wrapper className={className}>
      <Info>{`${Math.round(areaSize.width)} x ${Math.round(
        areaSize.height
      )}`}</Info>
      <Info>{time.format("HH:mm:ss")}</Info>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 0;

  color: #999;
`;

const Info = styled.span`
  padding: 4px;
`;

export default React.memo(DebugInfo);
