import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import { defaultLabelPartsSetting } from "clock/Label/setting";
import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";

type LabelPartsDocumentProps = {
  className?: string;
  id?: string;
};

const LabelPartsDocument: React.FC<LabelPartsDocumentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Label
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>ラベルの設定です。</S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-text`}>
                    <S.ListItemLabel>text</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    ラベルテキストです
                  </S.ListItemDescription>

                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-color`}>
                    <S.ListItemLabel>color</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-fontSize`}>
                    <S.ListItemLabel>fontSize</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-fontWeight`}>
                    <S.ListItemLabel>fontWeight</S.ListItemLabel>
                    <S.ListItemValidation>FontWeight</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-fontFamily`}>
                    <S.ListItemLabel>fontFamily</S.ListItemLabel>
                    <S.ListItemValidation>string</S.ListItemValidation>
                  </AnchorContainer>
                  <AnchorContainer id={`${id}-whiteSpace`}>
                    <S.ListItemLabel>whiteSpace</S.ListItemLabel>
                    <S.ListItemValidation>
                      <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/white-space">
                        css.white-space
                      </S.A>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    <Link smooth to="#TextPartsCommonSetting">
                      Text Parts Common Setting
                    </Link>
                    を参照
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <DocumentClock partsSetting={defaultLabelPartsSetting} />
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(LabelPartsDocument);
