import React, { useCallback } from "react";
import styled from "styled-components";

import { FullScreen } from "components/layout";
import CopyButton from "components/molecules/CopyButton";
import {
  DownloadButton,
  EditClockButton,
  ViewDocumentButton,
  SelectClockButton,
  QRCodeButton,
} from "components/molecules/IconButton";

type OverlayMenuProps = {
  className?: string;
  clockTitle?: string;
  shareURL?: string;
  onClickDownload?: () => void;
  onClickEdit?: () => void;
  onClickViewDocument?: () => void;
  onClickSwitchClock?: () => void;
  onClickViewQRCode?: () => void;
  onCloseMenu: () => void;
};
const OverlayMenu: React.FC<OverlayMenuProps> = ({
  className,
  clockTitle = "",
  shareURL,
  onClickDownload,
  onClickEdit,
  onClickViewDocument,
  onClickSwitchClock,
  onClickViewQRCode,
  onCloseMenu,
}) => {
  const onClickCopyURLButton = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      setTimeout(onCloseMenu, 750);
    },
    [onCloseMenu]
  );

  return (
    <BluredFullScreen>
      <Wrapper className={className} onClick={onCloseMenu}>
        <ClockTitle>{clockTitle}</ClockTitle>
        <ButtonContainer>
          {onClickDownload && (
            <DownloadButton
              // label="Set to default clock"
              label="デフォルトで表示する時計に設定"
              color="#fff"
              onClick={onClickDownload}
            />
          )}
          {onClickEdit && (
            <EditClockButton
              // label="Edit Clock"
              label="時計の編集"
              color="#fff"
              onClick={onClickEdit}
            />
          )}
          {onClickSwitchClock && (
            <SelectClockButton
              // label="Clock List"
              label="時計一覧の表示・切り替え"
              color="#fff"
              onClick={onClickSwitchClock}
            />
          )}
          {onClickViewDocument && (
            <ViewDocumentButton
              // label="Document"
              label="説明書を表示"
              color="#fff"
              onClick={onClickViewDocument}
            />
          )}
          {shareURL && (
            <CopyURLButton
              label="時計URLをコピー"
              value={shareURL}
              onClick={onClickCopyURLButton}
            />
          )}
          {onClickViewQRCode && (
            <QRCodeButton
              // label="QRCode"
              label="時計URLのQRCodeを表示"
              color="#fff"
              onClick={onClickViewQRCode}
            />
          )}
        </ButtonContainer>
      </Wrapper>
    </BluredFullScreen>
  );
};

const BluredFullScreen = styled(FullScreen)`
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(16px);

  z-index: 100;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  transform: scale(1.5);
  max-width: 240px;
  > * {
    padding: 8px;
    margin: 0 4px;
  }
`;

const ClockTitle = styled.div`
  position: absolute;
  top: 64px;
  right: auto;
  left: auto;
  color: #fff;
  font-size: 24px;

  text-decoration: underline;
`;

const CopyURLButton = styled(CopyButton).attrs(() => ({
  color: "#fff",
  clickedColor: "#fff",
}))``;

export default React.memo(OverlayMenu);
