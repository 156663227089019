import { JSONSchema7, JSONSchema7Definition } from "json-schema";

import { generateTimelineSettingSchema } from "lib/schema";

const TextClockPartsSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "テキスト時計の設定",
  properties: {
    type: {
      title: "パーツタイプ",
      enum: ["TextClock"],
    },
    common: { $ref: "#/definitions/ClockPartsCommonSetting" },
    clock: { $ref: "#/definitions/TextClockPartsIndividualSetting" },
    timeline: { $ref: "#/definitions/TextClockPartsTimelineSetting" },
  },
  required: ["type", "common", "clock"],
};

const TextClockPartsIndividualSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "テキスト時計の個別設定",
  properties: {
    type: {
      $ref: "#/definitions/TextClockPartsType",
      default: "HourMinuteSecond",
    },
    format: {
      type: "string",
      description: "カスタムタイプ利用時のフォーマット文字列",
      default: " ",
    },
    text: {
      type: "string",
      description: "全体のテキスト {CLOCK}は時計の文字列で置換されます",
      default: "{CLOCK}",
    },
    color: {
      type: "string",
      description: "テキスト時計の文字色",
      default: "#fff",
    },
    fontSize: {
      type: "number",
      description: "テキスト時計の文字サイズ（高さを１とした場合のサイズ）",
      default: 0.2,
    },
    fontWeight: {
      enum: ["normal", "bold", 100, 200, 300, 400, 500, 600, 700, 800, 900],
      description: "テキスト時計の文字の太さ",
      default: "normal",
    },
    fontFamily: {
      type: "string",
      description: "テキスト時計のフォント",
    },
    fontSizeAdjust: {
      type: "string",
      description: "",
    },
    fontStretch: {
      type: "string",
      description: "",
    },
    fontVariant: {
      type: "string",
      description: "",
    },
    whiteSpace: {
      enum: ["normal", "nowrap", "pre", "pre-wrap", "pre-line", "break-spaces"],
      description: "テキストのスペース、改行の扱い",
    },
    delay: {
      type: "number",
      description: "時計パーツの切り替え遅延時間",
      minimum: 0,
      maximum: 1000,
      default: 300,
    },
  },
  required: ["type"],
};

const TextClockPartsTimelineSetting = generateTimelineSettingSchema(
  TextClockPartsIndividualSetting
);

const TextClockPartsType: JSONSchema7Definition = {
  enum: [
    "Year",
    "Month",
    "Day",
    "MonthDay",
    "YearMonthDay",
    "Hour",
    "Minute",
    "Second",
    "HourMinute",
    "HourMinuteSecond",
    "DayOfYear",
    "MinuteOfDay",
    "SecondOfDay",
    "SecondOfHour",
    "Custom",
  ],
  description: "テキスト時計のタイプ",
};

export const TextClockPartsDefinitions: JSONSchema7["definitions"] = {
  TextClockPartsSetting,
  TextClockPartsIndividualSetting,
  TextClockPartsTimelineSetting,
  TextClockPartsType,
};
