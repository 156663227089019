import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as T from "components/atoms/Table";
import * as S from "components/atoms/style";
import Section from "components/layout/Section";
import AnchorContainer from "components/molecules/AnchorContainer";
import DocumentClock from "components/molecules/DocumentClock";

type ClockPartsDocumentContentProps = {
  className?: string;
  id?: string;
};

const ClockPartsTimelineSettingDocument: React.FC<ClockPartsDocumentContentProps> = ({
  className,
  id,
}) => {
  return (
    <>
      <Section className={className} id={id}>
        <Section.Header>
          <Link smooth to={`#${id}`}>
            Clock Parts Timeline Setting
          </Link>
        </Section.Header>
        <Section.Content>
          <S.P>
            時間変化により設定値を変化させる設定です。この設定により、時間に応じた位置移動や、色の変化など時計パーツに時間に応じた変化を加えることができます。
          </S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-type`}>
                    <S.ListItemLabel>type</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#TimelineType">
                        TimelineType
                      </Link>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    基本となるタイムラインの時間の値のタイプ
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;Second&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>
                <S.ListItem>
                  <AnchorContainer id={`${id}-duration`}>
                    <S.ListItemLabel>duration</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    遷移アニメーション時間。単位はミリ秒。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>300</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>
                <S.ListItem>
                  <AnchorContainer id={`${id}-delay`}>
                    <S.ListItemLabel>delay</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    遷移アニメーションの遅延時間。単位はミリ秒。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>0</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>
                <S.ListItem>
                  <AnchorContainer id={`${id}-timingFunction`}>
                    <S.ListItemLabel>timingFunction</S.ListItemLabel>
                    <S.ListItemValidation>
                      <S.A href="https://developer.mozilla.org/ja/docs/Web/CSS/transition-timing-function">
                        css.transition-timing-function
                      </S.A>
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    遷移アニメーションの変化の仕方。
                  </S.ListItemDescription>
                  <S.ListItemDescription>
                    デフォルト <S.Code>&quot;ease&quot;</S.Code>
                  </S.ListItemDescription>
                </S.ListItem>
                <S.ListItem>
                  <AnchorContainer id={`${id}-divisor`}>
                    <S.ListItemLabel>divisor</S.ListItemLabel>
                    <S.Required />
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時間の値の繰り返し単位。時間の値は常に
                    <Link smooth to="#TimelineType">
                      TimelineType
                    </Link>
                    により決まった値を本値で割ったあまりになります。
                  </S.ListItemDescription>
                </S.ListItem>
                <S.ListItem>
                  <AnchorContainer id={`${id}-values`}>
                    <S.ListItemLabel>values</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to={`#${id}-TimelineItem`}>
                        TimelineItem[]
                      </Link>
                    </S.ListItemValidation>
                    <S.Required />
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時間ごとの設定。全ての時間に対して設定を行う必要はありません。（設定が存在しない時間は設定が補完されます）
                    <br />
                    各時間ごとに時計パーツの共通設定、個別設定をオーバーライドされ、設定されなかった項目についてはそれより前の時間の設定が引き継がれます。
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
          <Section.ContentSub>
            <S.Contents>
              <T.Container id="TimelineType">
                <T.Title>TimelineType</T.Title>
                <T.Table>
                  <T.Row>
                    <T.Header>Year</T.Header>
                    <T.Data>年</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Month</T.Header>
                    <T.Data>月（1~12）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Day</T.Header>
                    <T.Data>日（1~31）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Hour</T.Header>
                    <T.Data>時（0~23）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Minute</T.Header>
                    <T.Data>分（0~59）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>Second</T.Header>
                    <T.Data>秒（0~59）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>DayOfYear</T.Header>
                    <T.Data>年内の何日目か (1~366)</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>MinuteOfYear</T.Header>
                    <T.Data>１日内の何分目か（0~527039）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>SecondOfYear</T.Header>
                    <T.Data>１日内の何分目か（0~31622400）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>MinuteOfDay</T.Header>
                    <T.Data>１日内の何分目か（0~1439）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>SecondOfDay</T.Header>
                    <T.Data>１日内の何秒目か（0~86399）</T.Data>
                  </T.Row>
                  <T.Row>
                    <T.Header>SecondOfHour</T.Header>
                    <T.Data>１時間内の何秒目か（0~3599）</T.Data>
                  </T.Row>
                </T.Table>
              </T.Container>
            </S.Contents>
            <S.Contents>
              <S.ItemListHeader>
                <AnchorContainer id={`${id}-timeline-sample`}>
                  Timeline Sample
                </AnchorContainer>
              </S.ItemListHeader>
              <DocumentClock
                setting={{
                  title: "Timeline Sample",
                  background: "black",
                  aspectRatio: 1.78,
                  parts: [
                    {
                      type: "TextClock",
                      common: {
                        x: "-25%",
                        y: "0%",
                        width: "40%",
                        height: "30%",
                      },
                      clock: {
                        type: "Second",
                        color: "white",
                        fontSize: 0.2,
                        fontWeight: 800,
                      },
                      timeline: {
                        type: "Second",
                        duration: 300,
                        delay: 0,
                        timingFunction: "ease",
                        divisor: 20,
                        values: [
                          {
                            t: 5,
                            common: {
                              x: "0%",
                              y: "25%",
                            },
                          },
                          {
                            t: 10,
                            common: {
                              x: "25%",
                              y: "0%",
                            },
                            clock: {
                              color: "red",
                            },
                          },
                          {
                            t: 15,
                            common: {
                              x: "0%",
                              y: "-25%",
                            },
                          },
                        ],
                      },
                    },
                  ],
                }}
                editorHeight={340}
                displayClockAreaBorder
              />
            </S.Contents>
          </Section.ContentSub>
        </Section.PaneContent>
      </Section>
      <Section>
        <Section.SubHeader>
          <AnchorContainer id={`${id}-TimelineItem`}>
            TimelineItem
          </AnchorContainer>
        </Section.SubHeader>

        <Section.Content>
          <S.P>各時間ごとの時計パーツの設定</S.P>
        </Section.Content>
        <Section.PaneContent>
          <Section.ContentMain>
            <S.ItemList>
              <S.ItemListHeader>Properties</S.ItemListHeader>
              <S.List>
                <S.ListItem>
                  <AnchorContainer id={`${id}-t`}>
                    <S.ListItemLabel>t</S.ListItemLabel>
                    <S.ListItemValidation>number</S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    設定対象の時間。時間の取りうる範囲は
                    <Link smooth to="#TimelineType">
                      TimelineType
                    </Link>
                    により異なります。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-common`}>
                    <S.ListItemLabel>common</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#ClockPartsCommonSetting">
                        ClockPartsCommonSetting
                      </Link>
                      （必須項目もオプションになっています）
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計タイプによらない共通の設定。
                  </S.ListItemDescription>
                </S.ListItem>

                <S.ListItem>
                  <AnchorContainer id={`${id}-clock`}>
                    <S.ListItemLabel>clock</S.ListItemLabel>
                    <S.ListItemValidation>
                      <Link smooth to="#ClockPartsIndivisualSetting">
                        ClockPartsIndivisualSetting
                      </Link>
                      （必須項目もオプションになっています）
                    </S.ListItemValidation>
                  </AnchorContainer>
                  <S.ListItemDescription>
                    時計タイプごとの設定。
                  </S.ListItemDescription>
                </S.ListItem>
              </S.List>
            </S.ItemList>
          </Section.ContentMain>
        </Section.PaneContent>
      </Section>
    </>
  );
};

export default React.memo(ClockPartsTimelineSettingDocument);
