import React, { useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ClockSetting } from "clock";
import Clock from "components/clock/Clock";
import { Centered, FullScreen } from "components/layout";
import { SettingContextProvider } from "hooks";
import {
  deleteClockSetting,
  saveDefaultClockSetting,
  saveSlotClockSetting,
  saveWorkingClockSetting,
} from "lib/savedata";
import { createClockURL } from "lib/url";

import OverlayClockList from "./OverlayClockList";
import OverlayMenu from "./OverlayMenu";
import OverlayQRCode from "./OverlayQRCode";

type ClockPageTemplateProps = {
  setting: ClockSetting;
  displayDownloadButton?: boolean;
  state?: { clockList?: boolean };
};

const ClockPageTemplate: React.FC<ClockPageTemplateProps> = ({
  setting: originalSetting,
  displayDownloadButton = true,
  state,
}) => {
  const history = useHistory();

  const [setting, setSetting] = useState(originalSetting);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenSwitchClock, setIsOpenSwitchClock] = useState(
    !!state?.clockList
  );
  const [isOpenQRCode, setIsOpenQRCode] = useState(false);

  const openMenu = useCallback(() => {
    setIsOpenMenu(true);
  }, [setIsOpenMenu]);
  const closeMenu = useCallback(() => {
    setIsOpenMenu(false);
  }, [setIsOpenMenu]);

  const setDefaultClock = useCallback(
    (setting: ClockSetting) => {
      setSetting(setting);
      saveDefaultClockSetting(setting);
      history.push("/");
    },
    [history]
  );

  const startEdit = useCallback(
    (setting: ClockSetting, slot?: number, from?: "list") => {
      saveWorkingClockSetting(setting);
      const params = new URLSearchParams();
      if (slot) {
        params.set("slot", slot.toString());
      }
      if (from) {
        params.set("from", from);
      }
      const search = params.toString();
      const path = search ? `/edit/?${search}` : "/edit";
      history.push(path, { from });
    },
    [history]
  );

  const startEditFromList = useCallback(
    (setting: ClockSetting, slot?: number) => {
      startEdit(setting, slot, "list");
    },
    []
  );

  const gotoDocument = useCallback(() => {
    history.push("/docs/");
  }, [history]);

  const showSwitchClock = useCallback(() => {
    setIsOpenSwitchClock(true);
    setIsOpenMenu(false);
  }, []);

  const closeSwitchClock = useCallback(() => {
    setIsOpenSwitchClock(false);
  }, []);

  const deleteClock = useCallback((slot: number) => {
    deleteClockSetting(slot);
  }, []);

  const saveClock = useCallback((setting: ClockSetting, slot: number) => {
    saveSlotClockSetting(slot, setting);
  }, []);

  const showQRCode = useCallback(() => {
    setIsOpenQRCode(true);
    setIsOpenMenu(false);
  }, []);

  const closeQRCode = useCallback(() => {
    setIsOpenQRCode(false);
  }, []);

  const shareURL = useMemo(() => createClockURL(setting), [setting]);

  return (
    <Wrapper>
      {setting.title && (
        <Helmet>
          <title>{`clock-f / ${setting.title}`}</title>
        </Helmet>
      )}
      {isOpenMenu && (
        <OverlayMenu
          clockTitle={setting.title}
          shareURL={shareURL}
          onClickDownload={
            displayDownloadButton ? () => setDefaultClock(setting) : undefined
          }
          onClickEdit={() => startEdit(setting)}
          onClickViewDocument={gotoDocument}
          onClickSwitchClock={showSwitchClock}
          onClickViewQRCode={showQRCode}
          onCloseMenu={closeMenu}
        />
      )}
      {isOpenSwitchClock && (
        <OverlayClockList
          currentSetting={setting}
          onChangeDefaultClock={setDefaultClock}
          onClickEditClock={startEditFromList}
          onDeleteClock={deleteClock}
          onSaveClock={saveClock}
          onClose={closeSwitchClock}
        />
      )}
      {isOpenQRCode && (
        <OverlayQRCode shareURL={shareURL} onClose={closeQRCode} />
      )}
      <ClockContainer onClick={openMenu}>
        <SettingContextProvider setting={setting}>
          <FullScreen background={setting.background || "#000"}>
            <Centered>
              <Clock />
            </Centered>
          </FullScreen>
        </SettingContextProvider>
      </ClockContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
`;

const ClockContainer = styled.div``;

export default ClockPageTemplate;
