import { JSONSchema7, JSONSchema7Definition } from "json-schema";

import { generateTimelineSettingSchema } from "lib/schema";

const ImagePartsSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "テキストクロックの設定",
  properties: {
    type: {
      title: "パーツタイプ",
      enum: ["Image"],
    },
    common: { $ref: "#/definitions/ClockPartsCommonSetting" },
    clock: { $ref: "#/definitions/ImagePartsIndividualSetting" },
    timeline: { $ref: "#/definitions/ImagePartsTimelineSetting" },
  },
  required: ["type", "common", "clock"],
};

const ImagePartsIndividualSetting: JSONSchema7Definition = {
  type: "object",
  additionalProperties: false,
  description: "画像の個別設定",
  properties: {
    url: {
      type: "string",
      description: "画像URL",
    },
    objectFit: {
      enum: ["contain", "cover", "fill", "none", "scale-down"],
      description: "画像のフィット方法",
      default: "contain",
    },
  },
  required: ["url"],
};

const ImagePartsTimelineSetting = generateTimelineSettingSchema(
  ImagePartsIndividualSetting
);

export const ImagePartsDefinitions: JSONSchema7["definitions"] = {
  ImagePartsSetting,
  ImagePartsIndividualSetting,
  ImagePartsTimelineSetting,
};
