import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 0px 1px;
  color: rgb(79, 86, 107);
  background-color: rgb(247, 250, 252);
  margin-bottom: 16px;
`;
export const Title = styled.div`
  color: rgb(105, 115, 134);
  font-size: 12px;
  font-weight: 500;
  background-color: rgb(227, 232, 238);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 16px;
`;

export const Row = styled.tr`
  font-size: 13px;
`;
export const Header = styled.th`
  font-weight: 600;
  width: 175px;
  padding: 9px 20px;
  text-align: right;
`;
export const Data = styled.td`
  padding: 9px 20px;
`;

export const Table: React.FC<{ className?: string; headerWidth?: number }> = ({
  className,
  headerWidth,
  children,
}) => (
  <TableWrapper className={className} headerWidth={headerWidth}>
    <tbody>{children}</tbody>
  </TableWrapper>
);

const TableWrapper = styled.table<{ headerWidth?: number }>`
  ${Header} {
    width: ${({ headerWidth }) =>
      headerWidth ? `${headerWidth}px` : undefined};

    @media screen and (max-width: 1024px) {
      width: 120px;
    }
  }
`;
