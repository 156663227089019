import { AnalogClockPartsSetting } from "./type";

export const defaultAnalogClockPartsSetting: AnalogClockPartsSetting = {
  type: "AnalogClock",
  common: {
    x: "0%",
    y: "0%",
    width: "95h",
    height: "95h",
    borderColor: "#fff",
    borderWidth: "2h",
    borderRadius: "50%",
    rotate: 0,
  },
  clock: {
    type: "HourMinuteSecond",
    handWidth: "2h",
    color: "#fff",
    delay: 300,
  },
};
