import React, { useMemo } from "react";

import Dots from "components/clock/Dots";
import { useTick } from "hooks";

import { getCoordinator } from "./module";
import { SimpleDotClockPartsSetting } from "./type";

interface DotClockPartsProps {
  setting: SimpleDotClockPartsSetting;
}

const SimpleDotClockParts: React.FC<DotClockPartsProps> = ({
  setting: {
    clock: { type, color = "#fff", coordinator, duration, delay, dotScale },
  },
}) => {
  const time = useTick();
  let num: number;
  switch (type) {
    case "Hour":
      num = time.hour();
      break;
    case "Minute":
      num = time.minute();
      break;
    case "Second":
      num = time.second();
      break;
    default:
      num = 0;
      break;
  }

  const cd = useMemo(() => getCoordinator(coordinator), [coordinator]);
  const dots = cd.calc(num);

  return (
    <Dots
      dots={dots}
      color={color}
      transitionDuration={duration}
      transitionDelay={delay}
      dotScale={dotScale}
    />
  );
};

export default SimpleDotClockParts;
