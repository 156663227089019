import * as ArrayUtils from "lib/utils/array";

class Factors {
  private _values: number[];
  get values(): number[] {
    return [...this._values];
  }
  constructor(values: number[] = []) {
    this._values = values;
  }

  public copy = (): Factors => {
    return new Factors([...this._values]);
  };

  public first = (): number | undefined => {
    return this._values[0];
  };

  public last = (): number | undefined => {
    return this._values[this._values.length - 1];
  };

  public shift = (): Factors => {
    return new Factors(this._values.slice(1));
  };

  /** 全ての因数の数をカウントする */
  public countAll = (): number => {
    return this._values.length;
  };

  /** 指定された因数の数をカウントする */
  public countFactor = (factor: number): number => {
    return this._values.filter((value) => value === factor).length;
  };

  /** 指定されたフィルター関数に因数をフィルタした結果を返す */
  public filter = (callback: (value: number) => boolean): Factors => {
    return new Factors(this._values.filter(callback));
  };

  /** 指定された比較関数で因数をソートした結果を返す */
  public sort = (comparaFn: (a: number, b: number) => number): Factors => {
    let values = [...this._values];
    values = values.sort(comparaFn);
    return new Factors(values);
  };

  /** 因数の積(元の数字を求める */
  public product = (): number => {
    return ArrayUtils.product(this._values);
  };

  /** 因数を追加する */
  public add = (factor: number): Factors => {
    return new Factors([...this._values, factor]);
  };

  /** 2つの因数集合を結合する */
  public concat = (other: Factors): Factors => {
    return new Factors(this._values.concat(other._values));
  };

  /**
   * 因数のうち指定された因数集合をマージする
   * マージされた数は因数配列の最後に追加される
   *
   * 例えば、
   * [2, 2, 2, 3] mergeFactors([2, 2]) => [2, 3, 4]
   * [2, 2, 2, 3, 3] mergeFactors([2, 3]) => [2, 6, 6]
   * [2, 3, 5, 7] mergeFactors([2, 3]) => [5, 7, 6]
   */
  public mergeFactors = (target?: Factors): Factors => {
    if (!target || target._values.length <= 1) {
      return new Factors(this._values);
    }

    const replace: number = target.product();
    let values: number[] = this._values;
    while (ArrayUtils.contains(values, target._values)) {
      values = ArrayUtils.differenceOne(values, target._values).concat(replace);
    }

    return new Factors(values);
  };
}

export default Factors;
