import { range } from "lib/utils/utils";

import AbstractFactorCoordinator from "./AbstractFactorCoordinator";
import {
  CircularCoordinate,
  RectangularCoordinate,
  Dot,
  Radian,
} from "./coordinate";
import Factors from "./factorization/Factors";

class ClassicFactorCoordinator extends AbstractFactorCoordinator {
  protected divideCircleByFactor = (
    factor: number,
    circle: Dot,
    prevFactors: Factors
  ): Dot[] => {
    const theta: Radian = Radian.M_2PI.div(factor);
    const theta2: Radian = theta.div(2);
    const r: number =
      circle.radius * (Math.sin(theta2.value) / (1 + Math.sin(theta2.value)));

    // 内接円の中心座標(円座標系、原点は元円の中心)を計算してから
    // 直交座標系に変換し、元円の中心座標を加える
    let angle = circle.angle;
    if (factor === 2) {
      // 2が2回目以降の場合 -90°回転させる
      if (prevFactors.countFactor(2) > 1) {
        angle = angle.add(Radian.M_PI_2.mul(-1));
      }
    } else if (factor === 4) {
      if (prevFactors.countAll() > 0 && prevFactors.last() === 2) {
        // 因数が4で、その前が2の場合、-45°回転させる
        angle = angle.add(Radian.M_PI_4.mul(-1));
      } else {
        // 因数が4で、それ以外の場合、角度を-45°にリセットする
        angle = Radian.M_PI_4.mul(-1);
      }
    } else {
      // それ以外の場合、角度を-90°にリセットする
      angle = Radian.M_PI_2.mul(-1);
    }

    const inscribedCircleCenter: RectangularCoordinate = new CircularCoordinate(
      circle.radius - r,
      angle
    )
      .toRectangular()
      .add(circle.center);

    const inscribedCircleBase: Dot = new Dot(inscribedCircleCenter, r, angle);
    const circles: Dot[] = range(factor).map((i) =>
      inscribedCircleBase.rotate(circle.center, theta.mul(i))
    );

    return circles;
  };
}

export default ClassicFactorCoordinator;
