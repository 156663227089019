/* eslint-disable @typescript-eslint/no-explicit-any */
import Form from "@rjsf/core";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import Ajv from "ajv";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import "ace-builds/src-noconflict/ace";
import "brace/mode/json";
import "brace/theme/github";
import { Button } from "components/atoms/Button";
import SVGIcon from "components/atoms/SVGIcon";
import { transform } from "lib/utils/object";

type FormEditorProps<T> = {
  className?: string;
  setting: T;
  schema?: { [key: string]: any };
  onUpdateSetting?: (setting: T) => void;
  showDetails?: boolean;
  onClickAddParts?: () => void;
};

type JsonType = {
  [key: string]: any;
};

const ajv = new Ajv({ allErrors: true, verbose: true });
const uiSchema: UiSchema = {
  version: { "ui:widget": "hidden" },
  parts: {
    "ui:options": { addable: false },
    items: {
      type: { "ui:widget": "hidden" },
      timeline: { "ui:widget": "hidden" },
    },
  },
};

const simpleUiSchema: UiSchema = {
  version: { "ui:widget": "hidden" },
  parts: {
    "ui:options": { addable: false },
    items: {
      type: { "ui:widget": "hidden" },
      common: {
        rotate: { "ui:widget": "hidden" },
        borderWidth: { "ui:widget": "hidden" },
        borderStyle: { "ui:widget": "hidden" },
        borderColor: { "ui:widget": "hidden" },
        borderRadius: { "ui:widget": "hidden" },
        filter: { "ui:widget": "hidden" },
        blend: { "ui:widget": "hidden" },
      },
      clock: {
        fontFamily: { "ui:widget": "hidden" },
        fontSizeAdjust: { "ui:widget": "hidden" },
        fontStretch: { "ui:widget": "hidden" },
        fontVariant: { "ui:widget": "hidden" },
        whiteSpace: { "ui:widget": "hidden" },
        "coordinator:merge": { "ui:widget": "hidden" },
      },
      timeline: { "ui:widget": "hidden" },
    },
  },
};

const FormEditor: <T extends JsonType>(
  props: FormEditorProps<T>
) => React.ReactElement<FormEditorProps<T>> = <T extends JsonType>({
  className,
  setting,
  schema,
  onUpdateSetting,
  showDetails = false,
  onClickAddParts,
}: FormEditorProps<T>) => {
  // 取り扱わないのに、デフォルトで入り込んでしまう設定をスキーマ自体から削除
  const schemaForForm = transform(schema, {}, {}, [
    [
      ["coordinator:merge", null],
      ["coordinator:merge", undefined],
    ],
  ]);

  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const onChange = useCallback(
    (json: JsonType) => {
      const data = json.formData;
      if (schemaForForm && onUpdateSetting) {
        if (ajv.validate(schemaForForm, data)) {
          onUpdateSetting(data as T);
        }
      }
      setTimestamp(new Date().getTime());
    },
    [schemaForForm, onUpdateSetting]
  );

  return (
    <>
      <Wrapper className={className}>
        <Form
          schema={schemaForForm as RJSFSchema}
          uiSchema={showDetails ? uiSchema : simpleUiSchema}
          formData={setting}
          validator={validator}
          liveValidate={true}
          onChange={onChange}
        >
          <AddClockPartsButtonContainer>
            <AddClockPartsButton onClick={onClickAddParts}>
              <Icon type="choice" />
              <Label>時計パーツを追加する</Label>
            </AddClockPartsButton>
          </AddClockPartsButtonContainer>
        </Form>
        <React.Fragment key={timestamp} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  overflow: scroll;
  padding: 16px;
  > * {
    height: 100%;
  }

  select[id*="root_parts_"][id$="__oneof_select"] {
    display: none;
  }

  button[type="submit"] {
    display: none;
  }
`;

const AddClockPartsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;
`;

const AddClockPartsButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;

  /* cursor: pointer; */
  border: 1px dashed rgb(79, 86, 107);
`;

const Icon = styled(SVGIcon).attrs(() => ({ size: 24 }))`
  margin-right: 8px;
`;

const Label = styled.span`
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
`;

export default FormEditor;
