import React, { useEffect, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import styled from "styled-components";

type AccordionProps = {
  className?: string;
  accordionKey: string;
  open?: boolean;
  children: React.ReactNode;
};

type AccordionEvent = {
  type: "open" | "close" | "switch";
  accordionKey: string;
};

const ACCORDION_EVENT_NAME = "AccordionEvent";
const BaseAccordion: React.FC<AccordionProps> = ({
  className,
  accordionKey,
  open: defaultOpen = false,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(defaultOpen);
  const [contentHeight, setContentHeight] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    const handler = (event: CustomEvent<AccordionEvent>) => {
      if (event.detail.accordionKey !== accordionKey) {
        return;
      }
      switch (event.detail.type) {
        case "open":
          setOpen(true);
          break;
        case "close":
          setOpen(false);
          break;
        case "switch":
          setOpen(!open);
          break;
      }
    };
    window.addEventListener(ACCORDION_EVENT_NAME, handler as EventListener);
    return () => {
      window.removeEventListener(
        ACCORDION_EVENT_NAME,
        handler as EventListener
      );
    };
  }, [open, setOpen, accordionKey]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.clientHeight);
      }
    });

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => observer.disconnect();
  }, [contentRef]);

  return (
    <Wrapper className={className} style={{ height: contentHeight }}>
      <Content ref={contentRef} open={open}>
        {children}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  transition: height 400ms ease-out;
`;

const CloseContent = styled.div``;
const OpenContent = styled.div``;

const Content = styled.div<{ open: boolean }>`
  position: relative;
  width: 100%;
  border: 1px solid transparent;

  > ${CloseContent} {
    display: ${({ open }) => (open ? "none" : "block")};
  }

  > ${OpenContent} {
    display: ${({ open }) => (open ? "block" : "none")};
  }
`;

const Link: React.FC<{
  type: AccordionEvent["type"];
  accordionKey: string;
}> = ({ type, accordionKey, children }) => (
  <a
    onClick={() =>
      window.dispatchEvent(
        new CustomEvent<AccordionEvent>(ACCORDION_EVENT_NAME, {
          detail: { type, accordionKey },
        })
      )
    }
    style={{ cursor: "pointer", textDecoration: "none" }}
  >
    {children}
  </a>
);

type AccordionType = typeof BaseAccordion & {
  CloseContent: typeof CloseContent;
  OpenContent: typeof OpenContent;
  Link: typeof Link;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Accordion = BaseAccordion as any;
Accordion.CloseContent = CloseContent;
Accordion.OpenContent = OpenContent;
Accordion.Link = Link;

export default Accordion as AccordionType;
