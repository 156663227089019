import { JSONSchema7Definition } from "json-schema";

import { transform } from "lib/utils/object";

export const generatePartialSettingSchema = <T extends JSONSchema7Definition>(
  schema: T
): JSONSchema7Definition => {
  return transform(schema, {}, {}, [
    [
      ["default", null],
      ["default", undefined],
    ],
    [
      ["required", null],
      ["required", []],
    ],
  ]);
};

export const generateTimelineSettingSchema = <T extends JSONSchema7Definition>(
  clockPartsIndivisualSettingSchema: T
): JSONSchema7Definition => {
  const partialClockPartsIndivisualSettingSchema: T = generatePartialSettingSchema(
    clockPartsIndivisualSettingSchema
  ) as T;

  return {
    title: "タイムラインの設定",
    description: "タイムラインの設定",
    type: "object",
    additionalProperties: false,
    properties: {
      type: {
        enum: [
          "Year",
          "Month",
          "Day",
          "Hour",
          "Minute",
          "Second",
          "DayOfYear",
          "MinuteOfYear",
          "SecondOfYear",
          "MinuteOfDay",
          "SecondOfDay",
          "SecondOfHour",
        ],
        description: "設定対象の時刻タイプ",
      },
      values: {
        type: "array",
        items: {
          type: "object",
          properties: {
            t: {
              type: "number",
            },
            apply: {
              title: "この設定の適用方法",
              enum: ["merge", "spot"],
            },
            common: {
              $ref: "#/definitions/ClockPartsCommonSettingForTimeline",
            },
            clock: generatePartialSettingSchema(
              partialClockPartsIndivisualSettingSchema
            ),
          },
          required: ["t"],
        },
      },
      duration: {
        type: "number",
        description: "遷移アニメーションの時間",
        minimum: 0,
        maximum: 1000,
      },
      delay: {
        type: "number",
        description: "遷移アニメーションの遅延",
        minimum: 0,
        maximum: 1000,
      },
      timingFunction: {
        type: "string",
        description: "遷移アニメーションの関数",
      },
      divisor: {
        type: "number",
        description: "遷移アニメーションの繰り返し単位",
      },
    },
    required: ["values"],
  };
};
