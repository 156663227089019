import { ClockSetting } from "clock";

import { serializeSetting } from "./setting";

export const createClockURL = (setting: ClockSetting): string => {
  // return `${
  //   process.env.PUBLIC_URL || window.location.origin + "/clock-f"
  // }/?s=${serializeSetting(setting)}`;
  return `${
    process.env.PUBLIC_URL || window.location.origin
  }/?s=${serializeSetting(setting)}`;
};

export const publicURL = (path: string): string =>
  `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : ""}${path}`;
