import React from "react";

import { ClockPartsSetting } from "clock";
import { AnalogClockParts } from "clock/AnalogClock";
import { CountParts } from "clock/Count";
import { FactorDotClockParts } from "clock/FactorDotClock";
import { GaugeParts } from "clock/Gauge";
import { ImageParts } from "clock/Image";
import { LabelParts } from "clock/Label";
import { SimpleDotClockParts } from "clock/SimpleDotClock";
import { TextClockParts } from "clock/TextClock";

import UnknownParts from "./UnknownParts";

type ClockPartsProps = {
  setting: ClockPartsSetting;
};

const ClockParts: React.FC<ClockPartsProps> = ({ setting }) => {
  switch (setting.type) {
    case "TextClock":
      return <TextClockParts setting={setting} />;
    case "FactorDotClock":
      return <FactorDotClockParts setting={setting} />;
    case "SimpleDotClock":
      return <SimpleDotClockParts setting={setting} />;
    case "AnalogClock":
      return <AnalogClockParts setting={setting} />;
    case "Label":
      return <LabelParts setting={setting} />;
    case "Image":
      return <ImageParts setting={setting} />;
    case "Count":
      return <CountParts setting={setting} />;
    case "Gauge":
      return <GaugeParts setting={setting} />;
  }

  return <UnknownParts />;
};

export default React.memo(ClockParts);
