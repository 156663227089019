import React from "react";
import styled from "styled-components";

import { Centered } from "components/layout";

const UnknownParts: React.FC = () => (
  <Wrapper>
    <Centered>
      <Label>Unknown Clock Type</Label>
    </Centered>
  </Wrapper>
);

const Wrapper = styled.div`
  font-size: 12px;
  color: #fff;
  height: 100%;
  border: 1px dashed #fff;
`;

const Label = styled.span`
  display: inline-block;
`;

export default UnknownParts;
