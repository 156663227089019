import dayjs from "dayjs";

export type ConvertTimeType =
  | "Year"
  | "Month"
  | "Day"
  | "Hour"
  | "Minute"
  | "Second"
  | "DayOfYear"
  | "MinuteOfYear"
  | "SecondOfYear"
  | "MinuteOfDay"
  | "SecondOfDay"
  | "SecondOfHour";

export const convertTime = (
  time: dayjs.Dayjs,
  type: ConvertTimeType
): number => {
  switch (type) {
    case "Year":
      return time.year();
    case "Month":
      return time.month();
    case "Day":
      return time.day();
    case "Hour":
      return time.hour();
    case "Minute":
      return time.minute();
    case "Second":
      return time.second();
    case "DayOfYear":
      return time.dayOfYear();
    case "MinuteOfYear":
      return convertTime(time, "DayOfYear") * 24 * 60 + time.minute();
    case "SecondOfYear":
      return convertTime(time, "MinuteOfYear") * 60 + time.second();
    case "MinuteOfDay":
      return time.hour() * 60 + time.minute();
    case "SecondOfDay":
      return convertTime(time, "MinuteOfDay") * 60 + time.second();
    case "SecondOfHour":
      return time.minute() * 60 + time.second();
  }
};
