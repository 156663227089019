/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab, Tabs as ReactTabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";

const TabsTabs = styled(ReactTabs)`
  position: relative;
  height: 100%;
`;

const TabsTabList = styled(TabList)`
  display: flex;

  .react-tabs__tab--selected {
    opacity: 1;
  }
`;

const TabsTab = styled(Tab)`
  text-align: center;
  padding: 16px 24px;
  cursor: pointer;
  user-select: none;

  opacity: 0.7;
`;

const TabsTabPanelList = styled.div`
  .react-tabs__tab-panel--selected {
    display: block;
  }
`;

const TabsTabPanel = styled(TabPanel)`
  display: none;
  width: 100%;
  height: 100%;
`;

type TabsType = typeof TabsTabs & {
  TabList: typeof TabsTabList;
  Tab: typeof TabsTab;
  TabPanelList: typeof TabsTabPanelList;
  TabPanel: typeof TabsTabPanel;
};
const Tabs: any = TabsTabs;
Tabs.TabList = TabsTabList;
Tabs.Tab = TabsTab;
Tabs.TabPanelList = TabsTabPanelList;
Tabs.TabPanel = TabsTabPanel;

export default Tabs as TabsType;
